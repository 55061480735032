import { render, staticRenderFns } from "./layoutStyle3.vue?vue&type=template&id=f87736f2&scoped=true&"
import script from "./layoutStyle3.vue?vue&type=script&lang=ts&"
export * from "./layoutStyle3.vue?vue&type=script&lang=ts&"
import style0 from "./layoutStyle3.vue?vue&type=style&index=0&id=f87736f2&lang=scss&scoped=true&"
import style1 from "./layoutStyle3.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f87736f2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/zfh/dev/targus-micor/targus-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f87736f2')) {
      api.createRecord('f87736f2', component.options)
    } else {
      api.reload('f87736f2', component.options)
    }
    module.hot.accept("./layoutStyle3.vue?vue&type=template&id=f87736f2&scoped=true&", function () {
      api.rerender('f87736f2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/layout/layoutStyle3.vue"
export default component.exports