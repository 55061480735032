



















































  import { Component, Vue } from "vue-property-decorator";
  import layoutUserInfo from "./layoutUserInfoBase.vue"
  import { ApplicationModule } from "@/store/modules/application";
  import i18n from '@/pages/home/lang'
import eventHub from "@/utils/eventHub";
  
  @Component({
    name: "layout-style-35",
    components: {
      layoutUserInfo,
    },
  })
  export default class extends Vue {
    get logined() {
      return ApplicationModule.login;
    }
    time:any = null;
    mounted(){
      this.$nextTick(() => {
        this.init();
        this.initData();
        (window as any).onresize();
      })

      eventHub.$on('changeSetting', () => {
        this.init();
      })
    }
    init(){
      const dom:any = document.getElementById('animation-text');
      const content:any = this.$t('app.content');
      let innerHtml = '',key = 0,num = 0,timeout = 0;
      clearInterval(this.time);
      this.time = setInterval(() => {
        key == 3 && (key = 0);
        if(num < content[key].length){
          innerHtml += content[key][num],dom.innerHTML = innerHtml,num += 1;
        }else if(num == content[key].length && timeout != 9){
          timeout += 1;
        }else{
          key += 1,num = 0,innerHtml = '',timeout = 0;
        }
      },280)
      
    }
    destroyed(){
      this.time = null;
      window.onresize = null;
    }
    get applicationName() {
      if (i18n.locale == "zh-Hans") {
        return {size: '45px',spacing: '22px'}
      } else if (i18n.locale == "en") {
        return {size: '45px',spacing: '6px'}
      } else {
        return {size: '45px',spacing: '22px'}
      }
    }
    initData() {
      function setScale(){
        // 设计稿的宽度，设计稿的高度 根据实际项目调整
        let designWidth = 1920;
        let designHeight = 1354;
        // 内容区域的可视宽度, 高度
        let {clientHeight} = document.querySelectorAll('html')[0];
        // 缩放系数 (宽度)
        // let scaleWidth = clientWidth / designWidth;
        let scaleHeight = clientHeight / designHeight;
        // 需改动的dom节点
        ['#article'].forEach((dom) => {
          (document as any).querySelector(dom).style = `width: ${designWidth * scaleHeight}px; height: ${designHeight * scaleHeight}px;`;
        })
        // 缩放节点
        let scaleDom:any = document.querySelector('#scaleContent')
        scaleDom.style = `transform: scale(${scaleHeight},${scaleHeight})`;
        // 获取元素缩放后的实际宽度
        let scaleDomWidth:any =  scaleDom.getClientRects()[0].width; 
        (document as any).querySelector('.section').style = `width: ${scaleDomWidth}px; height: ${designHeight * scaleHeight}px;`
      }
      window.onresize = function () {
        setScale()
      };
    }
  }
  