export function CovertMainData(type) {
    switch(type) {
        case 'youzheng':
            return CovertYouZheng();
    }

    return {};
}

function CovertYouZheng() {
    let userInfo = sessionStorage.getItem('userInfo');
    let userToken = sessionStorage.getItem('userToken');
    let roleList = sessionStorage.getItem('roleList');
    let menus = sessionStorage.getItem('menus');

    if(!userInfo) {
        return {};
    }

    userToken = JSON.parse(userToken);
    sessionStorage.setItem("VUE_APP_ACCESS_TOKEN",JSON.parse(userToken.v));

    let data = {
        "principal": {},
        "menus": [],
        "applications": [],
        "authProperties": null,
        "userConfig": {
            "language_config": {
                "bizId": "85308",
                "bizType": "language_config",
                "data": "zh-CN"
            },
            "theme_config": {
                "bizId": "85308",
                "bizType": "theme_config",
                "data": "theme-youzheng"
            }
        },
        "login": true
    }

    userInfo = JSON.parse(userInfo);
    userInfo = JSON.parse(userInfo.v);

    roleList = JSON.parse(roleList);
    roleList = JSON.parse(roleList.v);

    data.principal = {
        "id":userInfo.id || '',
        "userId":userInfo.id || '',
        "usercode": userInfo.userCode || '',
        "name": userInfo.userName || '',
        "email": userInfo.email || '',
        "mobile": userInfo.phone || '',
        "status": 1,
        "username": userInfo.userCode || '',
        "delFlag": "0",
        "avatar": "",
        "languageConfig": "zh-CN",
        "themeConfig": "theme-youzheng",
        "roleList": roleList || [],
        "roleIds": (roleList || []).map(r => r.id)
    }

    menus = JSON.parse(menus);
    menus = JSON.parse(menus.v);

    data.menus = CovertYouZhengMenus(menus,true);
    
    return data;
}

function CovertYouZhengMenus(menus, top=false) {
    let temp = [];

    menus.forEach(m => {
        temp.push(
            {
                "menuId": m.id.toString(),
                "parentId": top?'0':m.parentId.toString(),
                "name": m.showName,
                "nameHans": m.showName,
                "nameEn": m.showName,
                "url": m.path || '',
                "perms": m.permissions,
                "type": m.permissions?'2':'1',
                "status": "0",
                "isFrame": "1",
                "visible": "0",
                "icon": "",
                "orderNum": 100,
                "isChecked": 0,
                "topMenu": false
            }
        )

        if(m.children && m.children.length > 0) {
            temp = temp.concat(CovertYouZhengMenus(m.children));
        }
    })

    return temp;
}

export function CovertPrincipalData(type, data) {
    switch(type) {
        case 'youzheng':
            return CovertYouZhengPrincipal(data);
    }

    return {};
}

function CovertYouZhengPrincipal(data) {    
    let temp =  {
        "userId": data.id,
        "usercode": data.principal.userCode,
        "name": data.principal.userName,
        "deptId": "",
        "email": "",
        "mobile": "",
        "status": 1,
        "username": data.principal.userName,
        "delFlag": "0",
        "dept": {},
        "avatar": "",
        "languageConfig": (data.userConfig.language_config || {}) .data,
        "themeConfig": (data.userConfig.theme_config || {}).data,
        "roleList": data.principal.roleList,
        "roleIds": (data.principal.roleList || []).map(r => r.userCode),
        "id": data.id
      }
    
    return temp;
}