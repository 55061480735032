var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "layout-style-35",
      staticStyle: { position: "relative", "background-color": "#25292a" },
    },
    [
      _c("div", { staticClass: "bg-mask", attrs: { id: "bgMask" } }),
      _c("article", { attrs: { id: "article" } }, [
        _c("div", { staticClass: "swiper-container" }, [
          _c("section", { staticClass: "section" }, [
            _c("div", { attrs: { id: "scaleContent" } }, [
              _c("div", { staticClass: "container" }, [
                _c(
                  "div",
                  {
                    staticClass: "index-bg",
                    staticStyle: { height: "100%", width: "100%" },
                    attrs: { id: "indexBg" },
                  },
                  [
                    _c("svg-icon", {
                      staticStyle: { "font-size": "1350px", height: "100%" },
                      attrs: { "icon-class": "logo-targus" },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "bg-mask", attrs: { id: "bgMask" } }),
                _c("div", { staticClass: "container-page" }, [
                  _c(
                    "div",
                    { staticClass: "left" },
                    [
                      _vm.logined
                        ? [
                            _vm.logined
                              ? _c("div", { staticStyle: { height: "40px" } })
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "logos",
                                staticStyle: { "margin-top": "180px" },
                              },
                              [
                                _vm._m(0),
                                _c(
                                  "div",
                                  {
                                    staticClass: "logos-intro",
                                    staticStyle: { "margin-left": "30px" },
                                  },
                                  [_vm._m(1)]
                                ),
                              ]
                            ),
                            _c("div", {
                              staticStyle: {
                                "margin-top": "80px",
                                "margin-bottom": "80px",
                                height: "45px",
                              },
                            }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "right",
                      staticStyle: { "margin-top": "10px" },
                    },
                    [_vm._t("default")],
                    2
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logos-system-logo" }, [
      _c("i", {
        staticClass: "el-icon-arrow-left",
        staticStyle: { "font-size": "45px" },
      }),
      _c(
        "span",
        {
          staticClass: "logos-system-logo-title",
          staticStyle: { "font-size": "50px" },
        },
        [_vm._v("Targus ")]
      ),
      _c("i", {
        staticClass: "el-icon-arrow-right",
        staticStyle: { "font-size": "45px" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "code" }, [
      _c("div", { staticClass: "Typist" }, [
        _c("div", { staticClass: "inner-text" }, [
          _c("span", {
            style: {
              "font-size": _vm.applicationName.size,
              "letter-spacing": _vm.applicationName.spacing,
            },
            attrs: { id: "animation-text" },
          }),
        ]),
        _c(
          "span",
          {
            staticClass: "Cursor Cursor--blinking",
            staticStyle: { "font-size": "45px" },
          },
          [_vm._v("_")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }