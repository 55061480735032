import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Message, MessageBox } from 'element-ui'
import VueRouter, { Route } from 'vue-router'
import { UserModule } from '@/store/modules/user'
import store from '@/store'
import { ApplicationModule } from './store/modules/application'
import { getApplicationInstance } from './pages/application'

const whiteList = ['/login', '/auth-redirect', '/risk/evaluate/exam', '/risk/evaluate/finance']

export function config_permission(router: VueRouter, themeName: any = ''){
  NProgress.configure({ showSpinner: false })


  const getPageTitle = () => {
    console.log('home currentRoute',router.currentRoute);
    const route = router.currentRoute
    if(route.meta && route.meta.title) {
      return `${route.meta.title}`
      // - ${process.env.VUE_APP_NAME||'app'}
    }
    return process.env.VUE_APP_NAME ||'app'
  }
  const getUlr = (menus: any) => {
    let url = '';
    for (let index = 0; index < menus.length; index++) {
      const menu = menus[index];
      if(menu.url && menu.visible === '0') {
        url = menu.url;
        break;
      }
      else if(menu.children && menu.children.length >0 ) {
        url = getUlr(menu.children);
      }
    }
    // if(menus.length > 0) {
    //   if(menus[0].url && menus[0].visible === '0') {
    //     url = menus[0].url;
    //   }
    //   else if(menus[0].children && menus[0].children.length >0 ) {
    //     url = getUlr(menus[0].children);
    //   }
    // }

    return url;
  }
  router.beforeEach(async(to: Route, from: Route, next: any) => {
    // Start progress bar
    NProgress.start()
    /* TODO 联调完删除 */
    // return next()
    // 获取主题
    if(themeName && !(to.meta || {}).appname) {
      store.commit('SET_FIX_THEME', themeName)
    }
    if(!UserModule.theme){
      const theme = localStorage.getItem('userTheme')
      store.commit('SET_THEME', theme || process.env.VUE_APP_DEFAULT_THEME)
    }
    if(!ApplicationModule.isInited){
      await store.dispatch('setSystemStats')
    }
    if(!ApplicationModule.isInited){
      MessageBox.alert("还没有加载到数据");

    }
    if(ApplicationModule.login){
      
      if(to.path == '/login'){
        getApplicationInstance().logout();
        next()
      }else{
        let reload = false
        if((UserModule.roles||[]).length == 0){
          await UserModule.GetUserInfo()
          if((UserModule.roles||[]).length > 0) {
            reload = true;
          }
          next({...to});
        }
        if(to.path == '/user/home'){
          const url = getUlr(UserModule.leftMenu)  || '/home';
          const resolved = router.resolve(url);
          if(resolved.resolved){
            if(resolved.resolved.path == to.path){
              return next()
            }
            return next({...resolved.resolved,query: to.query})
          }
        }else{
          next();

        }
        if(reload) {
          next(to); //处理浏览器刷新，先加载动态路由，再重新定位到动态页面，
        }
        else {
          next();
        }
      }
    } else {
      // Has no token
      if (whiteList.indexOf(to.path) !== -1 || (to.meta || {}).authWhite === true) {
        // In the free login whitelist, go directly
        next()
      } else {
        // Other pages that do not have permission to access are redirected to the login page.
        next(`/login?redirect=%2F`)
        return;
      }

    }
  })

  router.afterEach(() => {
    // Finish progress bar
    // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
    NProgress.done()
    // set page title
    if(router.currentRoute.meta && !(router.currentRoute.meta || {}).appname) {
      document.title = getPageTitle()
    }
  })
}
