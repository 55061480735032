import {$t} from '@/pages/home/lang'
import eventHub from '../eventHub';

/**
 * @classdesc 字典数据
 * @property {String} label 标签
 * @property {*} value 标签
 * @property {Object} raw 原始数据
 */
export default class DictData {
  constructor(label, value, raw) {
    const dictLabelKey = 'dictValue.'+raw.dictType+'.'+value;
    const dictI18nLabel = $t(dictLabelKey);
    if(dictI18nLabel != dictLabelKey) {
      this.label = dictI18nLabel
    }
    else {
      this.label = label
    }
    this.value = value
    this.raw = raw

    eventHub.$on('changeSetting', () => {
      const dictLabelKey = 'dictValue.'+raw.dictType+'.'+value;
      const dictI18nLabel = $t(dictLabelKey);
      if(dictI18nLabel != dictLabelKey) {
        this.label = dictI18nLabel
      }
      else {
        this.label = label
      }
    })
  }
}
