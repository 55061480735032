import axios, { AxiosRequestConfig } from 'axios';
import { Message, MessageBox } from 'element-ui';
import LoadingManager from './loading';
import {$t} from "@/pages/home/lang";
import { getApplicationInstance } from '@/pages/application';

const request_id_prefix = 'axios_request_index_';

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 1000*100,// 请求超时时间,100秒
    withCredentials: true,
});
function isLoginRequest(config:AxiosRequestConfig){
  return config.url === process.env.VUE_APP_SYSTEM_API+"oauth/token"
      && config.method 
      && config.method.toUpperCase() == 'POST'
}
// Request interceptors
service.interceptors.request.use(
    (config) => {
      // debugger
      // Add X-Access-Token header to every request, you can add other custom headers here
      if (config.headers && getApplicationInstance().accessToken && !isLoginRequest(config)) {
        config.headers['Authorization'] = "Bearer "+ getApplicationInstance().accessToken as string
      }
      if(config.headers && isLoginRequest(config)){
        config.headers['Authorization'] = "Basic bWlyc3A6bWlyc3A="
      }
      if(config.url && config.url.startsWith("https://console-mock.apipost.cn")){
        config.withCredentials = false;
      }
      // @ts-ignore
      config.requestId = getRequestId(config);
      // @ts-ignore
      if(!config.skipLoading) {
        LoadingManager.add(config);
        // @ts-ignore
        delete config.skipLoading
      }
      return config
    },
    (error) => {
        Promise.reject(error)
    }
)
service.interceptors.response.use((response) => {
  LoadingManager.remove(response.config);
  if (response.data.code === '999999' || response.data.code === '403') {
    Message({
      message: response.data.msg || 'Error',
      type: 'error'
    })
    return Promise.reject(response)
    // return response
  }
  return response;
},
(error) => {
  LoadingManager.remove(error.config || {});
  if(!error.response){
    console.error(`Network Error: Could not connect to the server. Request data:`, error.request);
    return Promise.reject($t('app.networkError'))
  }else if (error.response.status >= 400 && error.response.status < 500) {
    // handle 4xx client errors here
    if(error.response.status == 403){
      getApplicationInstance().destroy();
      return Promise.reject($t('app.sessionTimeout'));
    }
    if(error.response.status == 404){
      return Promise.reject("请求的资源不存在,url:"+error.response.config.url);
    }
    return Promise.reject('请求错误')
  } else if (error.response.status >= 500) {
    // handle 5xx server errors here
    console.error('An error occurred on the server.');
    return Promise.reject("请求错误："+error);
  }
  return Promise.reject("请求错误："+error);
})

// 给每个请求设置一个 requestId
function getRequestId(config: any) {
  return request_id_prefix + config.url + '_' + Math.random().toString();
}

export type RequestResponse<T = any> = Promise<T>
export interface RequestConfig extends AxiosRequestConfig {
  skipLoading?:boolean
  requestId?:string
}

export default function(config: RequestConfig):RequestResponse{
    // debugger
    return new Promise((resolve,reject)=>{
      service(config).then((res)=>{
        if(config.responseType != 'blob'){
          resolve(res.data)
        }else{
          resolve(res)
        }
      }).catch(e=>{
        reject(e)
      })
    })
}
