export default {
    home: {
        title: '風險知識庫',
        captcha: '驗證碼',
        captchaPlaceholder: '請輸入驗證碼',
        login: {
            welcome: '歡迎登錄',
            register: '尚未有帳號？ 立即注册',
            byPassword: '帳號密碼登入',
            bySSO: 'SSO登入',
            account: '帳號',
            accountPlaceholder: '請輸入用戶名',
            password: '密碼',
            passwordPlaceholder: '請輸入密碼',
            rememberMe: '記住我',
            forgetPassword: '忘記密碼？',
            login: '登入',
        },
        userAccount: {
            registerWelcome: '歡迎註冊',
            name: '姓名',
            namePlaceholder: '請輸入姓名',
            email: '郵箱',
            emailPlaceholder: '請輸入郵箱',
            mobile: '電話號碼',
            mobilePlaceholder: '請輸入電話號碼',
            companyName: '公司名稱',
            companyNamePlaceholder: '請輸入公司名稱',
            position: '職位',
            positionPlaceholder: '請輸入職位',
            contactMatters: '聯絡事項',
            contactMattersPlaceholder: '請輸入聯絡事項',
            submitAsAgree: '提交即視為同意',
            agreement: '《Targus隱私協議》',
            hasAccount: '已有帳號，立即登入',
            invalidMobile: '請輸入正確的電話號',
            invalidEmail: '請輸入正確的郵箱地址',
            forgetWelcome: '填寫資訊找回密碼',
        },
        home: {
            hello1: '淩晨好，',
            hello2: '早上好，',
            hello3: '上午好，',
            hello4: '中午好，',
            hello5: '下午好，',
            hello6: '晚上好，',
        },
        desc: {
            report: {
                description: '基於企業的組織架構和管控模式，形成全方位風險視圖，準確度量企業整體風險水平，為領導決策和日常管理提供前瞻性支持。',
                descriptionDetails1: '靈活的報表設計，基於需求定制化風險視圖',
                descriptionDetails2: '直觀的數據展示，利用可視化工具直觀展示風險狀況',
                descriptionDetails3: '動態交互式體驗，數據逐級鑽取快速找到關鍵信息',
            },
            risk: {
                description: '全生命周期風險管理工具，幫助企業及時掌握風險動態，迅速響應風險事件，通過節點流程的流轉，精細化管理和管控風險。',
                descriptionDetails1: '事前風險評估，以規範的制度與流程約束各項行為，將風險管理工作前置',
                descriptionDetails2: '事中風險監控，與業務系統深度集成，實現各類風險的全面監控',
                descriptionDetails3: '事後風險處置，靈活的工作流，保障企業面臨風險事件時的及時處置和響應時效',
            },
            compliance: {
                description: 'AI能力與流程管理有機結合，加速合規管理效率，建設“感知-解析-應對-管控”全方位智能化合規管理體系。',
                descriptionDetails1: '整合企業內外合規數據庫，支持合規知識更新與共享',
                descriptionDetails2: '利用AI技術智能識別和解讀合規信息，提供個性化合規建議',
                descriptionDetails3: '搭載流程引擎，實施合規問詢、事件處置、監管響應等合規管控手段',
            },            
            control: {
                description: '',
                descriptionDetails1: '',
                descriptionDetails2: '',
                descriptionDetails3: '',
            },           
            audit: {
                description: '大數據技術融入審計，實現數字化審前分析、規範化審計程序、自動化編制審計報告等措施，幫助高質量審計業務的實施。',
                descriptionDetails1: '數字化審計手段，從海量數據中挖掘潛在風險',
                descriptionDetails2: '審計作業支持，規範化管理審計流程和審計成果',
                descriptionDetails3: '審計資源共享，全面提升審計工作質量',
            },          
            supervise: {
                description: '信息技術與紀檢監察業務深度融合，為紀檢監察工作高質量發展提供科技動能，有效提升監督的精準性和有效性。',
                descriptionDetails1: '監察項目全流程管理，線上線下聯動，拓寬監察幅度',
                descriptionDetails2: '整合數據資源，深入分析大數據背景下的紀檢監察案例',
            },               
            workbench: {
                description: '以工作台形式支持團隊協作，提供數據集市、數據處理、規則引擎、智能引擎、數據探索、任務調度功能，助力風險洞察。',
                descriptionDetails1: '打破“數據壁壘”，極速處理海量數據',
                descriptionDetails2: '端到端無代碼，支持拖拽式交互',
                descriptionDetails3: '數據安全有保障，行列級數據權限配置',
            },               
            ai: {
                description: '內置多重AI技術與工具，匯集行業領先的預訓練模型，提供模型探索、引用、訓練、部署和應用的一站式服務。',
                descriptionDetails1: '自然語言處理，輔助文本生成與分類',
                descriptionDetails2: '機器視覺，識別對象中的信息或文字',
                descriptionDetails3: '大語言模型輔助語音識別和生成',
            },               
            'risk-screening': {
                description: '企業業務的防火牆，通過動態的風險監測，及時跟踪可疑活動，采取預控對策實現業務全時守護。',
                descriptionDetails1: '智能化風險攔截措施，攔截規則靈活配置',
                descriptionDetails2: '定制化集成消息接口，及時發出預警信息',
                descriptionDetails3: '流式計算引擎，支持1秒內快速響應',
            },               
            system: {
                description: '實現全面管理與監控系統運行狀態，數據許可權和功能許可權靈活配寘，提升組織效率和競爭力。',
                descriptionDetails1: '功能許可權管理與控制，保護系統機密資訊',
                descriptionDetails2: '管理維護數據資源，確保數據的可靠性、一致性和完整性',
                descriptionDetails3: '即時監控與診斷，維護系統高可用性和穩定性',
            },               
            conformance: {
                description: '',
                descriptionDetails1: '',
                descriptionDetails2: '',
                descriptionDetails3: '',
            },                   
            task: {
                description: '',
                descriptionDetails1: '',
                descriptionDetails2: '',
                descriptionDetails3: '',
            },    
        }
    }
}