import { initGlobalState  } from 'qiankun';  
import { UserModule } from "@/store/modules/user";
import LoadingManager from '@/utils/loading';
import { settings } from 'nprogress';

const initState = {
    type:'',
    data:{}
}

const actions = initGlobalState(initState);
actions.onGlobalStateChange((state, preState) => {
    switch(state.type) {
        case 'appStats':
            window.vueInstance.$store.dispatch('setAppState', state.data)
            UserModule.GetUserInfo()
            break;
        case 'update-menu':
            console.log('update-menu setMenuPath')
            window.vueInstance.$nextTick(() => {                
                window.vueInstance.$store.dispatch('setMenuPath', state.data)
            })
            break;
        case 'update-title':
            // document.title = `${state.data} - ${process.env.VUE_APP_NAME||'app'}`
            break;
        case 'app-mounted':
            LoadingManager.remove({requestId:'app-mount'})
            console.log('home app mounted',new Date().getTime())
            break;
        case 'add-loading':
            LoadingManager.remove({requestId:state.data})
            console.log('home add loading',new Date().getTime())
            break;
        case 'remove-loading':
            LoadingManager.remove({requestId:state.data})
            console.log('home remove loading',new Date().getTime())
            break;
        case 'refresh-workbench-menus':
            window.vueInstance.$store.dispatch('getWorkbenchMenus')
            break;
        case 'change-setting':
            window.vueInstance._i18n.locale = state.data.lang
            break;
    }
    console.log(preState, '主应用变更前');
    console.log(state, '主应用变更后');
})

export function logout() {
    actions.setGlobalState({
        type: 'logout'
    })
}

export function routerPush(path) {
    actions.setGlobalState({
        type: 'router-push',
        data: path
    })
}

export function changeSetting(setting) {
    actions.setGlobalState({
        type: 'change-setting',
        data: setting
    })
}