



















































import Vue from "vue";
import { makePy } from "@/utils/zhFirstletter";
import { agreeOrRefuse, selectById } from "@/api/workbench";
import { getFullUrl } from "@/utils/utils";
import { selectInvideAcl } from "@/api/user/message";

export default Vue.extend({
  name: 'AddEdit',
  props: {
    workbenchId: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      formData: {
        name: '',
        photoPath: '',
        bizId: ''
      },
      avatarImgPath: ''
    }
  },
  watch: {
    'formData.photoPath': {
      handler(val) {
        if (val) {
          getFullUrl(val).then(res => {
            this.avatarImgPath = res
          })
        }
      },
      immediate: true
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const res = await selectById({ id: this.workbenchId })
      //@ts-ignore
      this.formData = res.data || {}
      // 获取权限类型
      const { data: invideAcl } = await selectInvideAcl({
        bizId: this.formData.bizId
      })
      this.$set(this.formData, 'roleType', invideAcl[0].roleType)
    },
    _makePy(str: string) {
      return makePy(str)
    },
    // 同意 或 拒绝
    agreeOrReject(status: string) {
      (this as any).$refs.formData.validate(async (valid: boolean) => {
        if (valid) {
          let str = status === '0' ?
            (this as any).$t('wb.agreeInvitation') :
            (this as any).$t('wb.rejectInvitation')
          await this.$confirm(str, (this as any).$t('app.confirm'), {
            type: 'warning'
          })
          await agreeOrRefuse({
            bizId: this.formData.bizId,
            status: status
          })
          this.$message.success((this as any).$t('app.actionSuccess'))
          // 同意 刷新专项菜单 跳转专项
          if (status === '0') {
            await this.$store.dispatch('getWorkbenchMenus')
            this.$emit('feedback', status)
          }
          this.$emit('update:visible', false)
        } else {
          return false;
        }
      })
    },

    getRoleType(roleType: string) {
      if (roleType === '1') {
        return (this as any).$t('wb.collaborators')
      }
      if (roleType === '2') {
        return (this as any).$t('wb.viewer')
      }
      return ''
    }
  }
})
