<template>
  <div class="tab-view-layout">
    <div class="tabs">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="ch.name" v-for="ch in (children || [])" :key="ch.menuId" :name="ch.url"></el-tab-pane>
      </el-tabs>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
  import { UserModule } from "@/store/modules/user";

  export default{
    data() {
      return {
        activeName: "",
        children:[],
        redirectRoute: {
          '/supervision/creditBlacklist': '/supervision/creditBlacklist/index',
          '/supervision/fraud': '/supervision/fraud/index',
          '/engineering/contract': '/engineering/contract/index',
          '/engineering/project': '/engineering/project/index',
          '/engineering/finalization': '/engineering/finalization/index',
          '/resources/program': '/resources/program/index',
          '/resources/problem': '/resources/problem/index',
          '/resources/statute': '/resources/statute/index',
          '/resources/system': '/resources/system/index',
          '/resources/rule': '/resources/rule/index',
          '/resources/consulting': '/resources/consulting/index',
          '/resources/industry': '/resources/industry/index',
        }
      }
    },
    methods: {  
      handleClick(){
        this.$router.push(this.activeName)
      },
    },  
    mounted(){
      let current = UserModule.menus.find(m=>m.url == this.$route.path && m.component && m.component.indexOf('TabViewLayout#') == 0);
      let parentMenu = UserModule.menus.find(m=>m.menuId == current.parentId) || {};
      this.children = UserModule.menus.filter(m=>m.parentId == parentMenu.menuId);
      this.activeName = current.url;

      this.$router.beforeEach(async(to, from, next) => {
        console.log('tab',to)
          if(this.redirectRoute[to.path]) {
            next(this.redirectRoute[to.path])
          }
          else {
            next();
          }
      })
    }
  }
</script>
<style lang="scss" scoped>
  .tab-view-layout {
    padding-right: 17px;
    height: 100%;
    box-sizing: border-box;

    .tabs {
      padding: 10px;
    }

    ::v-deep .el-tabs__nav-wrap::after {
      background-color: inherit;
    }

    ::v-deep .el-tabs__active-bar {
      transition: none;
    }
  }
</style>