var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "formData",
      staticClass: "pri-font-color",
      staticStyle: { margin: "30px", "max-width": "450px" },
      attrs: { "label-position": "top", model: _vm.formData },
    },
    [
      _c("el-form-item", [
        _c(
          "div",
          {
            staticClass: "f-c-c",
            staticStyle: {
              width: "110px",
              height: "110px",
              position: "relative",
            },
          },
          [
            _c(
              "div",
              { staticClass: "avatar-setting f-c-c" },
              [
                _vm.avatarImgPath
                  ? _c("el-image", {
                      staticStyle: {
                        width: "100%",
                        height: "100%",
                        "border-radius": "50%",
                      },
                      attrs: { fit: "cover", src: _vm.avatarImgPath },
                    })
                  : _c(
                      "span",
                      [_c("svg-icon", { attrs: { "icon-class": "robot" } })],
                      1
                    ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("wb.workbenchName"), prop: "name" } },
        [_vm._v(" " + _vm._s(_vm.formData.name) + " ")]
      ),
      _c("el-form-item", { attrs: { label: _vm.$t("wb.remark") } }, [
        _vm._v(" " + _vm._s(_vm.formData.remark) + " "),
      ]),
      _c("el-form-item", { attrs: { label: _vm.$t("wb.roleType") } }, [
        _vm._v(" " + _vm._s(_vm.getRoleType(_vm.formData.roleType)) + " "),
      ]),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.agreeOrReject("0")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("wb.agree")) + " ")]
          ),
          !!_vm.workbenchId
            ? _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.agreeOrReject("1")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("wb.reject")) + " ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }