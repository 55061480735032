import {Action, getModule, Module, Mutation, MutationAction, VuexModule} from "vuex-module-decorators";
import store from "@/store";
import variables from "@/styles/element-variables.scss";
import { SystemStats, getAuthProperties, getSystemStats} from "@/api/system";
import {UserModule} from "@/store/modules/user";
import {addAppRoute} from '@/router';
import {CovertPrincipalData, CovertMainData} from '@/utils/mainAppDataConvert'
import { listData } from "@/api/sys/dict/data";
export  interface IApplication {
    collapse: boolean
    theme: string
    stats: SystemStats | undefined
}
@Module({dynamic: true, store, name: 'application'})
class Application extends VuexModule implements IApplication {
    public stats: SystemStats | undefined = undefined ;
    public appStats: SystemStats | undefined = undefined ;
    collapse: boolean = localStorage.getItem("workspace-collapse")=='1';
    theme: string = variables.theme || process.env.VUE_APP_DEFAULT_THEME
    colorPrimary: string = variables.colorPrimary || ''
    menuPath = ''
    defaultMenuStyle = '2'
    get userMenus():any{
        if(this.appStats) {
            return this.appStats.menus || {};
        }
        if(!this.stats){
            return {}
        }
        return this.stats.menus || {}
    }
    get menuStyle():any{
        if(!this.stats){
            return this.defaultMenuStyle
        }
        return this.stats.menuStyle || this.defaultMenuStyle
    }
    get userConfig():any{
        if(!this.stats){
            return {}
        }
        return this.stats.userConfig || {}
    }
    get user():any{
        if(!this.stats){
            return {}
        }
        return this.stats.principal || {}
    }
    get applications():any{
        if(!this.stats){
            return {}
        }
        return this.stats.applications || {}
    }
    get currentMenuPath():string {
        console.log('currentMenuPath',this.menuPath)
        return this.menuPath;
    }
    @Action
    public async changeCollapse() {
        if(this.collapse){
            this.SET_COLLAPSE(false)
        }else{
            this.SET_COLLAPSE(true)
        }
        localStorage.setItem("workspace-collapse",this.collapse?'1':"0")
    }
    @MutationAction({mutate: ['stats']})
    async logouted(){
        return {
            stats: undefined
        }
    }
    @MutationAction({mutate: ['stats']})
    async setSystemStats() {
        let systemData = {};

        if((window as any).__POWERED_BY_QIANKUN__) {
            console.log('从session获取')
            systemData = CovertMainData('youzheng')

            //根据配置构造app的信息
            let appsConfig = process.env.VUE_APP_APPS_CONFIG;            
            if(appsConfig) {     
                appsConfig = JSON.parse(appsConfig)    
            }
            //@ts-ignore
            systemData.applications = []
            appsConfig.forEach((app:any) => {
                //@ts-ignore
                systemData.applications.push({menuId:app.app,full:true})
            })
        }
        else {
            let resp = null;
            try{
                resp = await getSystemStats()
                resp.data.principal = CovertPrincipalData('youzheng',resp.data);
                const {data} = resp;
                systemData = data;
                //@ts-ignore
                if(systemData && systemData.applications) {
                    //@ts-ignore
                    systemData.applications.push({menuId:'file-viewer',full:true})
                }
            }catch(e){
                console.log(e)
                const {data} = await getAuthProperties()
                return {
                    stats: {
                        authProperties: data
                    }
                }
            }
        }

        const {rows} =  await listData({dictType: 'sys_property'})
        //@ts-ignore
        systemData.menuStyle = ((rows || []).find(r => r.dictLabel == '菜单风格') || {}).dictValue || this.defaultMenuStyle;
       
       console.log('data',systemData)
       debugger
       //@ts-ignore
       if(systemData && systemData.applications) {            
            let appsConfig = process.env.VUE_APP_APPS_CONFIG;            
            if(appsConfig) {     
                appsConfig = JSON.parse(appsConfig)    
            }

            //@ts-ignore
            systemData.applications.forEach((a:any) => {
                if(appsConfig) {
                    const config = appsConfig.find((c:any) => c.app == a.menuId);
                    if(config && config.entry) {
                        a.entry = config.entry;
                        if((window as any).__POWERED_BY_QIANKUN__) {
                            a.full = true;
                        }
                    }
                }

                addAppRoute(a.menuId)
            })
        }
        
       return {
        stats: systemData
       }
    }
    @Action
    async setAppState(data: any) {
       this.SET_APP_STATS(data);
    }
    @Action
    async setMenuPath(menuPath: string) {
       this.SET_MENU_PATH(menuPath);
    }
    get authConfigs(){
        return ((this.stats || {}).authProperties||{}).configs
    }
    get internalConfigs(){
        const configs = ((this.stats || {}).authProperties||{}).configs || [];
        return configs.filter(c => c.protocol === "INTERNAL")[0] || {};
    }
    //是否已经登录
    get login(){
        return (this.stats || {}).login || false
    }
    get isInited(){
        return !!this.stats
    }
    @Mutation
    SET_STATS(stats?:SystemStats){
        this.stats = stats;
    }
    @Mutation
    SET_APP_STATS(stats?:SystemStats){
        this.appStats = stats;
    }
    @Mutation
    SET_COLLAPSE(collapse: boolean) {
        this.collapse = collapse
    }
    @Mutation
    SET_MENU_PATH(menuPath: string) {
        console.log('SET_MENU_PATH',menuPath)
        this.menuPath = menuPath
    }
}

export const ApplicationModule = getModule(Application)
