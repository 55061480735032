






import { Component, Vue } from "vue-property-decorator";
import userhome35 from "./userhome35.vue";
@Component({
    components: { userhome35 }
})
export default class SystemHome extends Vue {
}
