





































import { Component, Vue } from "vue-property-decorator";
import { ApplicationModule } from "@/store/modules/application";
import MyMessage from "../MyMessage.vue";
import UserAvatar from "@/component/userAvatar/index.vue";
import UserSetting from "../userSetting.vue";
import SvgIcon from '@/component/svgIcon/index.vue'
// import Training from "../training.vue";
@Component({
  components: {
    MyMessage,
    UserAvatar,
    UserSetting,
    SvgIcon
    // Training,
  },
  dicts: ["sys_property"],
  methods: {
    selectMenu(v) {
      this.$router.push(v);
    },
  },
})
export default class Layout extends Vue {
  avatarImgPath = "";
  myMsgVisible = false;
  version = process.env.RELEASE_VERSION || null;
  appName = process.env.VUE_APP_NAME === "liuxiang" ? "liuxiang" : "";
  getMsgNumInt:any = null;

  get msgNum() {
    // return 0;
    return this.$store.state.user.msgNum;
  }

  get msgDot() {
    return this.msgNum > 0;
  }
  mounted() {
    // 轮询消息数量接口
    this.getMsgNumInt = setInterval(() => {
      if(ApplicationModule.login) {
        this.$store.dispatch("getMsgNum");
      }
      else {
        clearInterval(this.getMsgNumInt)
      }
    }, 60 * 1000);
  }
  // 显示消息
  showMessage() {
    this.myMsgVisible = true;
  }
}
