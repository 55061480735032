





































import { Component, Vue } from "vue-property-decorator";
import layoutStyle35 from "@/views/layout/layoutStyle35.vue";
import SvgIcon from "@/component/svgIcon/index.vue";
import { ApplicationModule } from "@/store/modules/application";
import i18n from "@/pages/home/lang";
import eventHub from "@/utils/eventHub";

@Component({
  components: { layoutStyle35, SvgIcon },
    name: "userhome-35",
  })
export default class extends Vue {
  getIcon(mod:any) {
    //@ts-ignore
    return (this.iconMatch[mod.menuId]?this.iconMatch[mod.menuId]:mod.userHomeIcon)
  }

  get currentUserName(){
    return ApplicationModule.user.name
  }

  iconMatch = {
    system: 'xtgl',
    workbench: 'zhgf',
    compliance: 'hggl',
    risk: 'fxyy'
  }
  appsConfig = JSON.parse(process.env.VUE_APP_APPS_CONFIG).map((item:any) => item.app);

  get userHomeModules() {
    return [
        {
          name: '风险驾驶舱',
          userHomeIcon: 'fxjsc',
          userHomeMsgCount: 0,
          menuId: 'report',
          status: '1',
          description: this.$t('home.desc.report.description'),
          descriptionDetails: [
            this.$t('home.desc.report.descriptionDetails1'),
            this.$t('home.desc.report.descriptionDetails2'),
            this.$t('home.desc.report.descriptionDetails3')
          ]
        },
        {
          name: '风险运营',
          userHomeIcon: 'fxyy',
          userHomeMsgCount: 0,
          menuId: 'risk',
          status: '1',
          description: this.$t('home.desc.risk.description'),
          descriptionDetails: [
            this.$t('home.desc.risk.descriptionDetails1'),
            this.$t('home.desc.risk.descriptionDetails2'),
            this.$t('home.desc.risk.descriptionDetails3')
          ]
        },
        {
          name: '合规管理',
          userHomeIcon: 'hggl',
          userHomeMsgCount: 0,
          menuId: 'compliance',
          status: '1',
          description: this.$t('home.desc.compliance.description'),
          descriptionDetails: [
            this.$t('home.desc.compliance.descriptionDetails1'),
            this.$t('home.desc.compliance.descriptionDetails2'),
            this.$t('home.desc.compliance.descriptionDetails3')
          ]
        },
        {
          name: '内部控制',
          userHomeIcon: 'nbkz',
          userHomeMsgCount: 0,
          menuId: 'control',
          status: '1',
          description: this.$t('home.desc.control.description'),
          descriptionDetails: [
            // this.$t('home.desc.control.descriptionDetails1'),
            // this.$t('home.desc.control.descriptionDetails2'),
            // this.$t('home.desc.control.descriptionDetails3')
          ]
        },
        {
          name: '审计作业',
          userHomeIcon: 'nbsj',
          userHomeMsgCount: 0,
          menuId: 'audit',
          status: '1',
          description: this.$t('home.desc.audit.description'),
          descriptionDetails: [
            this.$t('home.desc.audit.descriptionDetails1'),
            this.$t('home.desc.audit.descriptionDetails2'),
            this.$t('home.desc.audit.descriptionDetails3')
          ]
        },
        {
          name: '纪检监察',
          userHomeIcon: 'jjjc',
          userHomeMsgCount: 0,
          menuId: 'supervise',
          status: '1',
          description: this.$t('home.desc.supervise.description'),
          descriptionDetails: [
            this.$t('home.desc.supervise.descriptionDetails1'),
            this.$t('home.desc.supervise.descriptionDetails2')
          ]
        },
        {
          name: '智慧工坊',
          userHomeIcon: 'zhgf',
          userHomeMsgCount: 0,
          menuId: 'workbench',
          status: '1',
          description: this.$t('home.desc.workbench.description'),
          descriptionDetails: [
            this.$t('home.desc.workbench.descriptionDetails1'),
            this.$t('home.desc.workbench.descriptionDetails2'),
            this.$t('home.desc.workbench.descriptionDetails3')
          ]
        },
        {
          name: 'AI工具箱',
          userHomeIcon: 'aigjx',
          userHomeMsgCount: 0,
          menuId: 'ai',
          status: '1',
          description: this.$t('home.desc.ai.description'),
          descriptionDetails: [
            this.$t('home.desc.ai.descriptionDetails1'),
            this.$t('home.desc.ai.descriptionDetails2'),
            this.$t('home.desc.ai.descriptionDetails3')
          ]
        },
        {
          name: '实时拦截',
          userHomeIcon: 'fxlj',
          userHomeMsgCount: 0,
          menuId: 'risk-screening',
          status: '1',
          description: this.$t('home.desc.risk.description'),
          descriptionDetails: [
            this.$t('home.desc.risk-screening.descriptionDetails1'),
            this.$t('home.desc.risk-screening.descriptionDetails2'),
            this.$t('home.desc.risk-screening.descriptionDetails3')
          ]
        },
        {
          name: '系统管理',
          userHomeIcon: 'xtgl',
          userHomeMsgCount: 0,
          menuId: 'system',
          status: '1',
          description: this.$t('home.desc.system.description'),
          descriptionDetails: [
            this.$t('home.desc.system.descriptionDetails1'),
            this.$t('home.desc.system.descriptionDetails2'),
            this.$t('home.desc.system.descriptionDetails3')
          ]
        },
        {
          name: '风险合规',
          userHomeIcon: 'fxhg',
          userHomeMsgCount: 0,
          menuId: 'conformance',
          status: '1',
          description: this.$t('home.desc.conformance.description'),
          descriptionDetails: [
            // this.$t('home.desc.conformance.descriptionDetails1'),
            // this.$t('home.desc.conformance.descriptionDetails2'),
            // this.$t('home.desc.conformance.descriptionDetails3')
          ]
        },
        {
          name: '任务管理',
          userHomeIcon: 'rwgl',
          userHomeMsgCount: 0,
          menuId: 'task',
          status: '1',
          description: this.$t('home.desc.task.description'),
          descriptionDetails: [
            // this.$t('home.desc.task.descriptionDetails1'),
            // this.$t('home.desc.task.descriptionDetails2'),
            // this.$t('home.desc.task.descriptionDetails3')
          ]
        }
      ]
  }

  currentModule: any = {};

  get helloTime() {
    let hour = new Date().getHours();
    let hello = '';
    if(hour >= 0 && hour < 5) {
      hello = this.$t('home.home.hello1').toString()
    }
    else if(hour >= 5 && hour < 8) {
      hello = this.$t('home.home.hello2').toString()
    }
    else if(hour >= 8 && hour < 12) {
      hello = this.$t('home.home.hello3').toString()
    }
    else if(hour >= 12 && hour < 13) {
      hello = this.$t('home.home.hello4').toString()
    }
    else if(hour >= 13 && hour < 19) {
      hello = this.$t('home.home.hello5').toString()
    }
    else {
      hello = this.$t('home.home.hello6').toString()
    }

    return hello;
  }

  gotoModule(mod: any) {
    if(mod.status == '0') {
      let url = `${location.origin}/${mod.menuId}/`
      
      window.open(url)
    }
  }

  handleShowDesc(mod:any) {
    if(this.currentModule == mod) {
      if(mod.showDesc) {
        this.currentModule = {
          showDesc:false
        }
      }
      else {
        this.currentModule.showDesc = true;
      }
    }
    else {
      this.userHomeModules.forEach((u:any) => {
        u.showDesc = false;
      })
      this.currentModule = mod;
      this.currentModule.showDesc = true;
    }
  }

  updateModules() {
    let apps = ApplicationModule.applications;
    this.userHomeModules.forEach((u:any) => {
        u.showDesc = false;
        let app = apps.find((a:any) => {return a.menuId == u.menuId});
        if(app) {
          u.status = app.status;
          if (i18n.locale == "zh-Hans") {
            u.name = app.nameHans
          } else if (i18n.locale == "en") {
            u.name = app.nameEn
          } else {
            u.name = app.name
          }
        }
    })
  }

  mounted() {
    this.updateModules();

    eventHub.$on('changeSetting', () => {
      this.updateModules();
      if(this.currentModule) {
        let newMod = this.userHomeModules.find(m => m.menuId == this.currentModule.menuId);

        if(newMod) {
          let showDesc = this.currentModule.showDesc;
          this.currentModule = newMod;
          this.currentModule.showDesc = showDesc;
        }
      }
    })
  }
}
