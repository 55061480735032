var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "top-menu" },
    [
      _vm._l(_vm.leftMenu, function (menu, index) {
        return [
          _c(
            "div",
            {
              key: menu.menuId,
              class: [
                "menus",
                index == 0 &&
                menu.children &&
                menu.children.findIndex(function (item) {
                  return item.visible == "0"
                }) != -1
                  ? "first-has-children"
                  : "",
              ],
            },
            [
              menu.children &&
              menu.children.findIndex(function (item) {
                return item.visible == "0"
              }) != -1
                ? _c(
                    "el-popover",
                    {
                      attrs: {
                        trigger: "hover",
                        "close-delay": 200,
                        "open-delay": 100,
                        "popper-class": "top-menu-menu-popover",
                        "visible-arrow": false,
                      },
                      model: {
                        value: _vm.popoverVisibles[menu.menuId],
                        callback: function ($$v) {
                          _vm.$set(_vm.popoverVisibles, menu.menuId, $$v)
                        },
                        expression: "popoverVisibles[menu.menuId]",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "sub-menu",
                          class: _vm.hasMoreSubCol(menu) ? "more-col" : "",
                        },
                        [
                          _c("el-scrollbar", [
                            _c(
                              "div",
                              { staticClass: "sub-menu-scroll-content" },
                              [
                                _c("div", { staticClass: "sub-menu-content" }, [
                                  _c("div", { staticClass: "sub-menu-data" }, [
                                    _c(
                                      "div",
                                      { staticClass: "sub-menu-items" },
                                      _vm._l(
                                        _vm.getMenuChildrenInCol(menu, 0),
                                        function (sub) {
                                          return _c(
                                            "div",
                                            {
                                              key: sub.menuId,
                                              class: [
                                                "sub-menu-items-item",
                                                sub.url == _vm.currentPath ||
                                                sub.menuId ==
                                                  _vm.selectedSubMenuId
                                                  ? "active"
                                                  : "",
                                              ],
                                              on: {
                                                click: function ($event) {
                                                  return _vm.selectMenu(sub)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  directives: [
                                                    {
                                                      name: "ak-tooltip-auto-show",
                                                      rawName:
                                                        "v-ak-tooltip-auto-show",
                                                    },
                                                  ],
                                                  attrs: {
                                                    effect: "dark",
                                                    placement: "top",
                                                    content: sub.name,
                                                  },
                                                },
                                                [
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "sub-menu-items-item-name",
                                                      },
                                                      [_vm._v(_vm._s(sub.name))]
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _vm.hasSubMenuCustom(menu)
                                      ? _c(
                                          "div",
                                          { staticClass: "sub-menu-custom" },
                                          _vm._l(
                                            _vm.getMenuChildrenInCol(menu, 0),
                                            function (sub) {
                                              return _c(
                                                "div",
                                                {
                                                  key: sub.menuId,
                                                  staticClass: "custom",
                                                  style: {
                                                    visibility:
                                                      _vm.isMenuCustom(sub)
                                                        ? ""
                                                        : "hidden",
                                                  },
                                                },
                                                [
                                                  _c("div", [
                                                    _vm._v(_vm._s("C")),
                                                  ]),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ]),
                                  _vm.hasMoreSubCol(menu)
                                    ? _c("div", { staticClass: "split" })
                                    : _vm._e(),
                                  _vm.hasMoreSubCol(menu)
                                    ? _c(
                                        "div",
                                        { staticClass: "sub-menu-data" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "sub-menu-items" },
                                            _vm._l(
                                              _vm.getMenuChildrenInCol(menu, 1),
                                              function (sub) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: sub.menuId,
                                                    class: [
                                                      "sub-menu-items-item",
                                                      sub.url ==
                                                        _vm.currentPath ||
                                                      sub.menuId ==
                                                        _vm.selectedSubMenuId
                                                        ? "active"
                                                        : "",
                                                    ],
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.selectMenu(
                                                          sub
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "ak-tooltip-auto-show",
                                                            rawName:
                                                              "v-ak-tooltip-auto-show",
                                                          },
                                                        ],
                                                        attrs: {
                                                          effect: "dark",
                                                          placement: "top",
                                                          content: sub.name,
                                                        },
                                                      },
                                                      [
                                                        _c("div", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "sub-menu-items-item-name",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(sub.name)
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _vm.hasSubMenuCustom(menu)
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "sub-menu-custom",
                                                },
                                                _vm._l(
                                                  _vm.getMenuChildrenInCol(
                                                    menu,
                                                    1
                                                  ),
                                                  function (sub) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: sub.menuId,
                                                        staticClass: "custom",
                                                        style: {
                                                          visibility:
                                                            _vm.isMenuCustom(
                                                              sub
                                                            )
                                                              ? ""
                                                              : "hidden",
                                                        },
                                                      },
                                                      [
                                                        _c("div", [
                                                          _vm._v(_vm._s("C")),
                                                        ]),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { attrs: { slot: "reference" }, slot: "reference" },
                        [
                          _c("div", { staticClass: "menu-item" }, [
                            _c("span", { staticClass: "has-sub" }),
                            _c(
                              "div",
                              {
                                class: [
                                  "menus-main-menu",
                                  _vm.mainMenuActive[menu.menuId]
                                    ? "active"
                                    : "",
                                ],
                              },
                              [_vm._v(_vm._s(menu.name))]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _c(
                    "div",
                    {
                      class: [
                        "menus-main-menu",
                        _vm.mainMenuActive[menu.menuId] ? "active" : "",
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.selectMenu(menu)
                        },
                      },
                    },
                    [_vm._v(_vm._s(menu.name))]
                  ),
            ],
            1
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }