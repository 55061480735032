//下载文件
import { getUrlByPath } from "@/api/data/ai";
import { importTpl } from "@/api/fileApi"
import i18n from "@/pages/home/lang";
import Vue from 'vue';
export function handleDownData(res: any): Promise<string> {
  const contentDisposition = decodeURIComponent(
    res.headers["content-disposition"]
  );
  if (contentDisposition === "undefined") {
    return new Promise((resolve, reject) => {
      if (res.data.type === "application/json") {
        res.data.text().then((text: any) => {
          reject(JSON.parse(text));
        });
      } else {
        return reject({ msg: "下载失败" });
      }
    });
  }
  const str = contentDisposition.split(";")[1];
  const filename = str.split("=")[1];
  const suffix = filename.split(".")[1];
  let type = "";
  switch (suffix) {
    case "doc":
      type = "application/msword";
      break;
    case "docx":
      type = "application/msword";
      break;
    case "pdf":
      type = "application/pdf";
      break;
    case "xlsx":
      type = "application/vnd.ms-excel";
      break;
    case "xls":
      type = "application/vnd.ms-excel";
      break;
    case "jpe":
      type = "image/jpeg";
      break;
    case "jpeg":
      type = "image/jpeg";
      break;
    case "jpg":
      type = "image/jpeg";
      break;
    case "png":
      type = "image/png";
      break;
    case "gif":
      type = "image/gif";
      break;
    case "zip":
      type = "application/zip";
      break;
    default:
      type = "";
      return Promise.reject("not supported response type");
  }

  if (
    navigator.userAgent.indexOf("Edge") > -1 ||
    "msSaveOrOpenBlob" in navigator
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.navigator.msSaveOrOpenBlob(
      new Blob([res.data], { type: `application/${type}` }),
      filename
    );
    return Promise.resolve("success");
  }
  const url = window.URL.createObjectURL(
    new Blob([res.data], { type: `application/${type}` })
  );
  
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link); //下载完成移除元素
  window.URL.revokeObjectURL(url); //释放掉blob对象
  return Promise.resolve("success");
}

export function saveBlob(blob: Blob, filename: string): void {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  link.setAttribute("download", filename);
  link.click();
  // document.body.removeChild(link); //下载完成移除元素
  window.URL.revokeObjectURL(url);
}

// 获取单个文件完整地址
export async function getFullUrl(url: string) {
  const { data } = await getUrlByPath({
    urlList: [url],
  });
  console.log("data:", data);
  return (data && data.urlList[0]) || "";
}

// 获取文件列表完整地址
export async function getFullUrlList(urlList: Array<string>) {
  const { data } = await getUrlByPath({
    urlList,
  });
  return (data && data.urlList) || [];
}

// export function debounce(fn: any, delay: any){

//     let delays=delay||500;
//     let timer: any;
//     return () => {
//         let th = this;
//         let args=arguments;
//         if (timer) {
//             clearTimeout(timer);
//         }
//         timer=setTimeout(function () {
//             timer=null;
//             fn.apply(th, args);
//         }, delays);
//     };
// }'

export function closeWindow() {
  const userAgent = navigator.userAgent;
  if (
    userAgent.indexOf("Firefox") !== -1 ||
    userAgent.indexOf("Chrome") !== -1
  ) {
    window.location.replace("about:blank");
  } else {
    window.opener = null;
    window.open("", "_self");
  }
  window.close();
}

export function previewFile(fileInfo: any) {
  const url = encodeURIComponent(fileInfo.path);
  const fileName = fileInfo.originName;
  const ext = fileInfo.extension;      
  const previewQueryString = `url=${url}&title=${fileName}&ext=${ext}`;

  window.open('/file-viewer?'+previewQueryString, '_blank'); 
}

export function downloadImportTpl(url: string) {
  importTpl(url).then((data: any) => {
    handleDownData(data).catch(({ msg }) => {
      Vue.prototype.$message.error(msg);
    });
  });
}

export function handleFile(fileUrl: string, type: string) {
  const previewQueryString = `file=${fileUrl}&action=${type}`;

  window.open('/wopi/editor?'+previewQueryString, '_blank'); 
}

export function downloadFile(fileUrl: string) {
  const temp = {
      "urlList": [fileUrl]
  }
  getUrlByPath(temp).then(({data,code}:any) => {
      if (code == "000000") {
          const a = document.createElement("a");
          a.target = '_blank';
          a.href = data.urlList[0];
          a.click();
      }

  });
}

export function getI18nValue(object:any, field:string) {
  if (i18n.locale == "zh-Hans") {
    return (object || {})[field+'Hans'] || (object || {})[field]
  } else if (i18n.locale == "en") {
    return (object || {})[field+'En'] || (object || {})[field]
  } else {
    return (object || {})[field]
  }
}
