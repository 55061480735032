












import { getFullUrl } from "@/utils/utils";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {makePy} from '@/utils/zhFirstletter';
@Component({
  name: "user-avatar"
})
export default class UserAvatar extends Vue {
    @Prop({
        type: String,
        default: ""
    })
    avatar!:string; 
    @Prop({
        type: Number,
        default: 100
    })
    size!:number;

    @Prop({
        type: String,
        default: ""
    })
    name!:string;
    fullUrl = "";

    get _makePy(){
        return makePy
    }
    get width(){
        return this.size + 'px'
    }
    get height(){
        return this.size + 'px'
    }
    get fontSize(){
        return this.size*0.8 + 'px'
    }
    async initAvatarUlr() {
        if(this.avatar ){
            if(this.avatar.indexOf('http') === -1){
                this.fullUrl = await getFullUrl(this.avatar);
            }else{
                this.fullUrl = this.avatar;
            }
              
        }
    } 
    async mounted(){
        await this.initAvatarUlr()
    }
    
    @Watch('avatar', { immediate: true })
    onChangeValue() {        
        this.initAvatarUlr()
    }

}
