
import { UserModule } from "@/store/modules/user";
import { ApplicationModule } from "@/store/modules/application";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Menu as MenuType } from "@/api/sys/menu";
import i18n from "@/pages/home/lang";
import { cloneDeep, orderBy } from "lodash";
import { Menu, MenuItem, Submenu } from "element-ui";
import { WorkspaceModule } from "@/store/modules/workbench";
import { getApplicationInstance } from '@/pages/application';
import * as appActions from '@/app-action'

@Component({
  name: 'MainMenu'
})
export default class MainMenu extends Vue {
  // activeIndex = "";
  env = process.env.NODE_ENV;  

  get currentRouteHashPath() {
    return this.$route.hash.replace('#','');
  }

  get leftMenu() {
    let menu = this.getI18nName(cloneDeep(UserModule.leftMenu))
    let liuxiangMenus = UserModule.liuxiangMenus;
    const keys:any = {'flowProject': '/flow/auditProject/','workbench': '/data/workbench/'};
    for (let index = 0; index < menu.length; index++) {
      const element = menu[index];
      if(element.nameEn === 'Workbench') {
        element.children = element.children && (element.children.filter((e:any) => {return e.visible == '0'}) ||[]).concat([...this.workbenchMenus.map((workspace: any) => {
          return {
            name: workspace.name,
            url: `/workbench/${workspace.id}/index`,
            visible: '0'
          }
        })])

        break;
      }

      if(keys[element.nameEn]){
        element.children = [];
        liuxiangMenus.forEach((item:any) => {
          element.children.push({
            name: item.clientName,
            url: `${keys[element.nameEn]}${item.clientCode}/index`,
            visible: '0'
          })
        });
      }
    }
    return (menu[0] && menu[0].children) || []
  }

  getI18nName(menus: any[]) {
    return menus.map((menu) => {
      if (menu.children != null && menu.children.length != 0) {
        menu.children = this.getI18nName(menu.children)
      }
      if (i18n.locale == "zh-Hans") {
        menu.name = menu.nameHans || menu.name
      } else if (i18n.locale == "en") {
        menu.name = menu.nameEn || menu.name
      } else {
        // Do nothing
      }
      return menu
    })
  }
  render(h: any) {
    let clazz = this.collapsed ? 'left-menu el-menu toggle-menu' : 'left-menu el-menu';
    return (
      <Menu
            class={clazz}
            onSelect={this.selectMenu}
            onOpen={this.selectMenu}
            onClose={this.selectMenu}
            collapse={this.collapsed}
            uniqueOpened={true}
            ref={'leftmenu'}
      >
        {this.buildMenu2(this.leftMenu)}
      </Menu>
    )
  }
  getMenuLevel(menu:MenuType) {
    let level = 1;
    let parent = menu.parent
    while(parent) {
      level++;
      parent = parent.parent;
    }

    return level;
  }
  buildMenu2(menuList: any[]){
    return menuList.map((menu:MenuType)=>{
        const hasChildren = menu.children && menu.children.findIndex(item=>item.visible == '0' )!=-1

        if(hasChildren){
          let subClass = '';
          if(menu.parentId != '0' && this.getMenuLevel(menu) >= 3) {
            if(menu.icon) {
              subClass = 'sub-parent-icon'
            }
            else {
              subClass = 'sub-parent'
            }
          }
          return (
            <Submenu class= { subClass }
                index = { menu.url || "__menu_Id__"+menu.menuId || new Date().getTime() + ""}
            >
              <template slot="title">
                  {menu.icon ?<i class={menu.icon}></i>:''}
                  <span external={menu.isFrame}>{menu.name}</span>
              </template>
              {this.buildMenu2((menu.children || []).filter((e:any) => {return e.visible == '0'}))}
            </Submenu>
          )
        }else{
          return (
            <MenuItem
                class="menuColor"
                index={menu.url || menu.menuId}
              >
              {menu.icon ?<i class={menu.icon}></i>:''}
              <span external={menu.isFrame} slot="title">{menu.name}</span>
            </MenuItem>
          )
        }
    })
  }
  mounted() {
    // this.$store.dispatch('getWorkbenchMenus')
    if(getApplicationInstance().appId == 'workbench' || (this.$route.meta || {}).appname  == 'workbench') {
      UserModule.getWorkbenchMenus()
    }
  }

  @Watch('$store.state.application.menuPath',{deep:true})
  handleCurrentMenuPathChange() {
    console.log('handleCurrentMenuPathChange')
    if(ApplicationModule.currentMenuPath) {
      this.updateMenu(ApplicationModule.currentMenuPath)
      this.$nextTick(() => {                
          this.$store.dispatch('setMenuPath', '')
      })
    }
  }

  @Watch('currentRouteHashPath')
  handleCurrentRouteHashPathChange() {
    console.log('handleCurrentRouteHashPathChange')
    this.updateMenu(this.currentRouteHashPath)
  }
  

  updateMenu(path:any) {
    console.log('updateMenu',path)
    // let path = this.currentMenuPath || location.hash.replace('#','');
    let menu = UserModule.menus.find(m => m.url == path);    
    if(menu) {
      //@ts-ignore
      this.$refs['leftmenu'].activeIndex = path;
      let parentMenu = UserModule.menus.find(m => m.menuId == menu.parentId);

      if(parentMenu && parentMenu.parentId != '0') {        
        //@ts-ignore
        this.$refs['leftmenu'].open(parentMenu.url || "__menu_Id__"+parentMenu.menuId)
      }
      // //@ts-ignore
      // this.$refs['leftmenu'].$forceUpdate()
    }
    else if ((this.$route.meta || {}).appname  == 'workbench' && /^\/workbench\/(.+?)\/(.+)/.test(path)) {
      //工作台    
      const arr = path.split('/')
      //@ts-ignore
      this.$refs['leftmenu'].activeIndex =  `/workbench/${arr[2]}/index`
    }
  } 
  get collapsed() {
    return ApplicationModule.collapse || false
  }
  get workbenchMenus() {
    return UserModule.workbenchMenus
  }
  get activeIndex() {
    // 工作台子页面，高亮对应左侧专项菜单
    if (/^\/workbench\/(.+?)\/(.+)/.test(this.$route.path)) {
      const arr = this.$route.path.split('/')
      return `/workbench/${arr[2]}/index`
    }
    return this.$route.path
  }
  selectMenu(v: string) {
    //@ts-ignore
    document.getElementById('mainMenu').focus()
    //@ts-ignore
    let ev:Event = window.event;
    if(ev.target && ev.target instanceof HTMLElement){
      if(ev.target.classList.contains('el-submenu__icon-arrow')){
        return;
      }
    }

    for (let index = 0; index < UserModule.leftMenu.length; index++) {
      const element = UserModule.leftMenu[index];
      if(element.url === v) {
        //@ts-ignore
        // this.$refs['leftmenu'].open(v)
        //@ts-ignore
        this.$refs['leftmenu'].activeIndex = v
        break;
      }
    }
    if(this.$route.path == v || v.indexOf('__menu_Id__') >= 0) {
      return;
    }
    if(ev.target && ev.target instanceof HTMLElement && ev.target.getAttribute('external') === '0'){
      this.$router.push({
        name: 'external-link',
        params: {url:v}
      })
    }
    else {
      appActions.routerPush(v);
    }
  }

}
