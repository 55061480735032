
































































import { makePy } from "@/utils/zhFirstletter";
import { UserModule } from "@/store/modules/user";
import { Component, Vue, Watch } from "vue-property-decorator";
import { ApplicationModule } from "@/store/modules/application";
import MainMenu from "../menu2.vue";
import TopMenu from "../menuTop.vue";
import i18n from "@/pages/home/lang";
// import layoutUserInfo from "./layoutUserInfo.vue"
@Component({
  components: {
    MainMenu,TopMenu,
    layoutUserInfo: () => import('./layoutUserInfo.vue'),
  },
  dicts: ["sys_property"],
  methods: {
    selectMenu(v) {
      this.$router.push(v);
    },
  },
})
export default class Layout extends Vue {
  version = process.env.RELEASE_VERSION || null;
  appName = process.env.VUE_APP_NAME === "liuxiang" ? "liuxiang" : "";
  menuStyle = ApplicationModule.menuStyle;
  get logoPath() {
    if (process.env.VUE_APP_NAME === "liuxiang") {
      return "/assets/login_logo_liuxiang.png";
    }
    if (
      this.dict.type.sys_property &&
      this.dict.type.sys_property instanceof Array &&
      this.dict.type.sys_property.length > 0
    ) {
      let target = this.dict.type.sys_property.find((property) => {
        return property.label === "系统logo";
      });
      if (target != null) {
        let index = target.value.lastIndexOf('.')
        return target.value.substr(0,index)+'-'+UserModule.theme+target.value.substring(index) || "/assets/login_logo_ey.png";
      } else {
        return "/assets/login_logo_ey.png";
      }
    }
    return "";
  }

  get collapsed() {
    return ApplicationModule.collapse;
  }

  get sysName() {
    if (process.env.VUE_APP_NAME === "liuxiang") {
      return "Ai Creation Engine";
    }
    if (
      this.dict.type.sys_property &&
      this.dict.type.sys_property instanceof Array &&
      this.dict.type.sys_property.length > 0
    ) {
      let target = this.dict.type.sys_property.find((property) => {
        return property.label === "系统名称";
      });
      if (target != null) {
        return target.value || "Targus";
      }
    }
    return "";
  }

  get applicationName() {
    if(!this.sysName) {
      return '';
    }
    
    let appName = ' | '
    let app = ApplicationModule.applications.find((a:any) => a.menuId == (this.$route.meta || {}).appname) || {};

    if (i18n.locale == "zh-Hans") {
      appName += app.nameHans
    } else if (i18n.locale == "en") {
      appName += app.nameEn
    } else {
      appName += app.name
    }

    return appName
  }

  changeCollapse(): void {
    ApplicationModule.changeCollapse();
  }
  _makePy(str: string) {
    return makePy(str);
  }

  gotoHome() {
    location.href = '/'
  }

  updateScrollBar() {
    //解决滚动条初始化消失的问题
    //@ts-ignore
    this.$refs.scrollBar.update()
  }

  scrollTop() {
    //@ts-ignore
    this.$refs.scrollBar.wrap.scrollTop = 0;
  }
}
