if ((window as any).__POWERED_BY_QIANKUN__) {//@ts-ignore
  __webpack_public_path__ = window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__;
  console.log(__webpack_public_path__)
}

import App from '@/App.vue';
import Element from 'element-ui';
import '@/styles/element-variables.scss'
import '@/styles/index.scss'
import '@/styles/index-patch.scss'
import Vue from 'vue'
import router from './router'
import store from '@/store'
import i18n from './lang';
import '@/assets/iconSvg/index'
import { createAppilication, getApplicationInstance } from '../application';
import DictData from '@/component/DictData'
import {config_permission} from '@/permission';
import { start } from 'qiankun';
import akTooltipAutoShow from '@/directives/akTooltipAutoShow'

const appsConfig = JSON.parse(process.env.VUE_APP_APPS_CONFIG);  

//自动适配
import 'lib-flexible'
// import VueRouter from 'vue-router';
createAppilication('home')
getApplicationInstance().router = router;
config_permission(router, appsConfig.some((a:any) => location.pathname.indexOf('/'+a.app+'/') == 0)?'':'theme-dark')
Vue.use(Element, {
  i18n: (key: any, value: any) => i18n.t(key, value)
});
Vue.use(DictData);
Vue.use(akTooltipAutoShow);

Vue.config.productionTip = false
import VueRouter from 'vue-router';

let instance:any | null = null;
let routerQk:VueRouter|null = null;

function render(props:any = {}){
  routerQk = router;
  const { container } = props;
    instance = new Vue({
      router: routerQk,
      store,
      i18n,
      render: h => h(App)
  }).$mount(container ? container.querySelector('#targus-app') : '#targus-app');
  (window as any)['vueInstance'] = instance
}

const win = window as any;
// 独立运行时
if (!win.__POWERED_BY_QIANKUN__) {
  start();
  render();
}

export async function bootstrap() {
  console.log('[vue] vue app bootstraped');
}
export async function mount(props:any) {
  console.log('[vue] props from main framework', props);  
  render(props);
}
export async function unmount() {
  instance.$destroy();
  instance.$el.innerHTML = '';
  instance = null;
  routerQk = null;
}

(window as any)['themeColor'] = {
  getColorTextPrimary: function(){ return window.getComputedStyle(document.getElementsByClassName('theme-variables-color-text-primary')[0])['color']},
  getColorTextRegular: function(){ return window.getComputedStyle(document.getElementsByClassName('theme-variables-color-text-regular')[0])['color']},
  getColorTextRegularChild: function(){ return window.getComputedStyle(document.getElementsByClassName('theme-variables-color-text-regular-child')[0])['color']},
}

const broadcast = new BroadcastChannel("targus3.5");

broadcast.onmessage = ({data}) => {
  console.log('broadcast.onmessage',data)
  const settting = JSON.parse(data); 
  settting.fromBroadcast = true;
  store.dispatch('changeSetting', settting)
};
