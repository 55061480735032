export default {
  app: {
    content: {
      '0': ['EY ','Smart ','Risk ','Control ','Platform'],
      '1': ['Promote ','new ','targets ','for ','big ','data ','risk ','control'],
      '2': ['Make ','risks ','nowhere ','to ','hide']
    },
    title: 'Ai Creation Engine',
    pleaseSelect: 'Please Select',
    pleaseInput: 'Please Input',
    sessionTimeout: 'The session has expired, please log in again',
    networkError: 'Network error',
    hello: "Hello!",
    welcome: "Welcome to",
    help:  "Help",
    message: 'Messages',
    setting:  "Setting",
    logout:  "Logout",
    user:  "Profile",
    optionInput:  "Optional",
    mustInput:  "Required",
    avatarUpload: 'Upload an avatar with files no more than 2M; png, jpeg or jpg format',
    langSelect:  "Language Selection",
    zhCN:  "Simplified Chinese",
    zhHans:  "Traditional Chinese",
    en:  "English",
    defaultLang:  "Default Chinese Simplified, drop down to switch",
    theme:  "Theme",
    themeDark:  "Dark-green/Grey",
    themeDark2:  "Dark-yellow/Black",
    themeLight:  "Bright-yellow/white",
    themeHuayou: 'Blue/White',
    defaultTheme:  "Default dark-green/grey, pull down to switch",
    save: 'Save',
    cancel: 'Cancel',
    close:  "Close",
    submit:  "Submit",
    confirm:  "Confirm",
    upload: 'Upload',
    delSuccess:  "Successfully Deleted",
    importSuccess: 'Import successful',
    uploadFail:  "Upload Failed",
    confirmLogout:  "Are you sure you want to log out?",
    remark: 'Notes',
    actionSuccess:  "Operation Successful",
    all: 'All',
    enable: 'Enable',
    disable: 'Disable',
    action: 'Action',
    createTime: 'Creation time',
    updateTime:  "Modification Time",
    unknownProcess: 'Unknown operation',
    module: 'Module',
    add: 'New',
    name: 'Full name',
    userName: 'Username',
    password: 'Password',
    totla: 'Total',
    online: 'Online',
    outline: 'Offline',
    selected: 'Hit',
    unSelected: 'Not hit',
    underReview: 'Under Intelligence Review',
    outReportsReview: 'Under manual review',
    outReports: 'Reports Ready',
    outReportsReviewIng: 'Pending manual review',
    savedSuccessfully: "Saved successfully",
    submitSuccessfully: "Submitted successfully",
    reject: 'Reject',
    approve: 'Approve',
    create: 'New',
    search: 'Search',
    flow: 'Transfer',
    runSuccess: 'Ran submit successfully',
    finish: 'Finish',
    send: 'Send',
    training: 'Employee Training',
    trainingMaterials: 'Training Materials',
    trainingConfirmMsg: 'I have completed the training and know how to use the system under the relevant safety practices',
    collapse: 'Collapse',
    expand: 'Expand',
    selectAll: 'Select All',
    preItem: 'Previous',
    nextItem: 'Next',
    yes: "Yes",
    no: "No",
    submitting: 'Submitting',
    none: 'None',
    canPreview: 'This file cannot be previewed, please download and view',
    tempEmpty: 'None',
    showMenu: 'Display menu',
    hideMenu: 'Hide menu',
    custom: 'Customized',
    asc: 'Positive order',
    desc: 'Reverse order',
    refresh: 'Refresh',
  },

  menu: {
    home: "Home",
    wb: "Workbench",
    lastModified: 'Recently modified',
    integrate: 'Data Mart',
    dataAccess: "Data Access",
    process: 'Data processing',
    model: 'Rule engine',
    ruleModel: "Rule engine",
    ai: 'Intelligence engine',
    explore: 'Data exploration',
    schedule: 'Task scheduling',
    members: 'Members',
    workPlan: 'Group Announcement',
    noticePlaceholder: 'Please enter the content or expression and click to publish the group announcement',
    workbenchSetting: 'Project Settings',
    roleManage: 'Role management',
    aiModel: "AI Model",
    scheduleReport: 'Risk warning',

  },
  message: {
    markAllRead: 'Mark all as read',
    packUp: "Put Away",
    expand: 'unfold',
    noMore: "No More",
    loadMore: "Load More",
    teamInvitation: "Team Invitation",
    confirmInvitation: "Invitation Confirmation",
    confirmRead: "Are you sure you want to mark all as read?",
    confirmDelMsg: "Are you sure you want to delete this unread message?",
    memberInvitation: "Team Member Invitation",
    memberManage: "Team Member Management",
    specialManage: "Special Management",
    dataAuth: "Data Permission Authorization",
    editorialInterpretation: 'Editorial Interpretation',
    internalRegulationOptimization: 'Internal optimization',
    articleUpload: 'Article upload',
    articleRevision: 'Article Revision',
    crawlerMonitoring: 'Crawler monitoring',
    myMessage: "My Message",
    noRead: "Unread",
  },
  system: {
    loading: "Loading"
  },
  common: {
    uploadFile: 'Drag the file here, or <em>click to upload</em>',
    text_copied_to_clipboard: 'Text copied to clipboard',
    confirm: 'Confirm',
    approve: 'Aprove',
    back: 'Back',
    tips: 'Prompt',
    rows: "Rows",
    columns: "Columns",
    data: "Data",
    previewTopRows: "Preview top 10 rows.",
    previewTopRows1: "Preview top 1000 rows.",
    previewTopRows5: "Preview top 5 rows.",
    judgingFieldName: 'Judgment Field Name',
    placeHolder: "Please name the judgment field",
    labelTextRequire1: 'Required - Supporting Chinese characters, numbers, English case and "_", no more than 50 characters',
    rules: 'Filter Rule',
    assign: 'Assign Value',
    edit: 'Edit',
    download: 'Download ',
    export: 'Export',
    import: 'Import',
    downloadTemplate: 'Download Template',
    query: 'Query',
    resultsDisplay: 'Result Display',
    version: 'Version',
    resultQuery: 'Result',
    unnamedDataProc: "Unnamed Data Processing",
    unnamedRuleEngine: 'Unnamed RuleEngine',
    redo: "Redo",
    recovery: 'Recover',
    enlarge: 'Zoom in ',
    narrow: 'Zoom out',
    needPrimaryKey: 'Please select at least one primary key',
    execute: 'Run',
    auth: 'Authorize',
    deleteRow: 'Delete',
    runningLog: 'Run Log',
    view: 'View',
    editDisabled: 'Edit (Disabled)',
    deleteRowDisabled: 'Delete (disable)',
    viewSpecial: 'Preview',
    explore: 'Data exploration',
    authSpecial: 'Request permissions',
    stop: 'Stop',
    deleteThisRow: 'Remove this row?',
    executeTitle: 'Execute',
    executeTask: 'Are you sure you want to execute?',
    executing: 'Executing',
    executeSuccess: 'Executing successfully',
    executeFail: 'Executing failed',
    stopTask: 'Are you sure to stop running immediately?',
    stopping: 'Stopping',
    stopSuccess: 'Stopped successfully',
    stopFail: 'Stop failed',
    exportData: 'Are you sure you want to export data??',
    exploreZoreData: '0 rows of data, unable to explore',
    deleteTask: 'Are you sure you want to delete it?',
    deleting: 'Deleting',
    deleteConfirm: 'Are you sure you want to delete it?',
    select: 'Please select',
    pleaseEnter: 'Please enter',
    topping: 'Topping',
    toppingConfirm: 'Are you sure you want to top it?',
    after23Hours: 'After 23 hours',
    copyConfirm: 'Are you sure you want to copy?',
    copySuccess: 'Successfully copied',
    copyFail: 'Copy failed',
    uploadFileMessage: 'Drag the file into the specified area, or click to complete the upload',
    saving: 'Saving',
    dataException: 'Data exception',
    effectivenessEvaluation: 'Effectiveness evaluation',
    resultPreview: 'Result preview',
    viewEvaluationResults: 'View evaluation results',
    saveFail: 'Save failed',
  },
  // workbench 工作台模块
  wb: {
    mySpecial: "My Special",
    newSpecial: "New special",
    newRemark: "For everyone to know more about the workbench, write some notes!",
    reportPlatform: "Reporting Platform",
    currWorkbenchName: "Special Name",
    currWorkbenchRemark: "Let's work together to complete this new project! come on!",
    workbenchName: 'Project Name',
    nameValidator: 'No modification once filled; 10 characters max; "_" supported but no initiation with numbers.',
    canNotChange: "Once filled in, it cannot be changed",
    remark: "It can be special remarks or announcements, which are displayed on the front page of the special",
    addEditremark: 'What would you like to say to your group members',
    remarkEdit: "It can be a special note or an announcement, which can be edited by the special owner and collaborators",
    remove: 'Remove projects',
    inputNamePlease: "Please fill in the special name",
    nameFormat: 'Support Chinese, upper and lower case English and "_"',
    confirmRemove: "Once the special items are removed, the authorization and scheduling of relevant tables will be prohibited. Are you sure to remove?",
    removeSuccess: "Remove special item successfully",
    confirmLeave: "This modification has not been saved and cannot be restored after leaving. Are you sure?",
    roleType: "Permission Type",
    agree: "Agree",
    reject: "Refuse",
    collaborators: 'Cooperator',
    viewer: 'Viewer',
    agreeInvitation: "Confirm your agreement to the invitation",
    rejectInvitation: "Confirm rejection of invitation",
    owner: 'Owner',
    leave: "Leave the team",
    removeMember: 'Remove from group',
    transferOwnership: 'Transfer ownership',
    invitationAgain: 'Re-send Invitation',
    members: 'Members',
    invitationNew: "Invite new members",
    searchText: 'Search by username, employee ID, role, department',
    sendInvitation: 'Send Invitation',
    selectMember: "Please select a special member",
    isExist: "Already exists and has been automatically de duplicated",
    hasBeenSend: "Special member invitation sent",
    changeRole: "Change member permissions",
    leaveMsg: "Are you sure to leave the team? After leaving the team, the files you create in the team will be automatically transferred to the team owner",
    rejected: "Rejected",
    toBeConfirmed: 'Pending for Approval',
    me: 'Me',
    status: 'Project Status',
    createTimeSort: 'Creation time',
    updateTimeSort: 'Last modified time',
    searchWbText: 'Fuzzy search by project names, owners',
    searchWbListText: 'Fuzzy search by project names, owners',
    replaceOwner: 'Change owners',
    jumpToItem: 'Jump to project page',
    confirmReplace: 'Confirm',
    dataAuthorizeCount: 'Number of data authorizations',
    disableMsg: "Once special items are disabled, authorization and scheduling of relevant tables will be prohibited. Are you sure to disable it?",
    enableMsg: ' Once the project is enabled, all relevant table authorization and scheduling will be enabled. Are you sure to continue?',
    selectPerson: "Please select a person",
    normalFieldRule: 'Up to 24 characters, does not support the beginning of a number, ok to use "_"',
    dbTableNameRule: 'Up to 60 characters; does not support beginning with a number; only English characters, numbers, "$" and "_" can be used',
    addFirstWork: 'Click New to create the first task',
    noPermission: 'No permission to access',
    newVersion: 'New Version',
    scheduleReport: 'Risk warning',
    supplierTitle: 'Relationship Graph',
    supplierTitleSub: 'Supplier Graph',
    supplierSearch1: 'Entity search',
    supplierSearch2: 'Path search',
    wbListAllCount: 'Number of all workshops: ',
    wbListMyCount: 'Number of my workshops: ',
    noticePublicsh: 'Publish',
    noticeDeleteConfirm: 'Are you sure you want to delete this team announcement?',
    permissionTitle: 'Role/User',
    permissionRemark: 'Permission description',
    permissionSystem: 'System permissions',
    permissionColumn: 'Column level permissions',
    permissionTypeRead: 'View',
    permissionTypeDownload: 'Download',
    permissionTypeEdit: 'Edit',
    permissionTypeExecute: 'Execute',
    permissionTypeRef: 'Reference',
    permissionTypeDelete: 'Delete',
    permissionTypeAuth: 'Authorization',
    permissionGroupViewer: 'Viewers',
    permissionGroupOwner: 'Owner',
    permissionGroupCoordinator: 'Collaborator',
    permissionAuthUserExist: 'The authorization object {name} already exists, please do not add it again',
    settingSendSuccess: 'Successfully sent',
    paramError: 'Parameter error',
    aiSelectNodeLabel: 'Select fitting model',
    aiSelectNodeNotic: 'Required - predict based on the selected model and version',
    aiAudioTitle: 'Audio playback: ',
    aiAudioName: 'Audio Name: ',
    aiAudioType: 'Audio input method: ',
    aiNoDataDisplay: 'There is no data to display',
    aiEstimate: 'Causal effect',
    aiPlacebo: 'Inspection',
    aiGraph: 'Causal effect',
    aiDistinguish: 'Inspection',
    aiIsTreatment: 'Select intervention variables',
    aiIsOutput: 'Select the dependent variable',
    aiIsCV: 'Select obfuscation variable',
    aiIsIV: 'Select tool variable',
    aiCausalMessage1: 'Required - recommended by the system, model types can be switched down according to actual situations',
    aiCausalMessage2: 'Required - only supports single choice',
    aiSelectModal: 'Required - recommended by the system, model types can be switched down based on actual situation',
    aiRegression: 'Regression',
    aiClassification: 'Two classifications',
    aiMulClassification: 'Multi classification',
    aiSelectModalTypeValid: 'Please select a model type',
    aiSelectModalValid: 'Please select a model',
    aiSelectObjectType: 'Select to recognize object type',
    aiSelectModalLabel: 'Select Model',
    aiSelectModalDefault: 'Compliance Model',
    aiSelectObjectTypeValid: 'Please select the recognized object type',
    aiEffectEvaluationTitle: 'Effect Ranking List',
    aiEffectEvaluationBest: 'The best model',
    aiLearningCurveUrl: 'Effect improvement curve',
    aiErrorplotUrl: 'Predicted residual',
    aiConfusionMatrixUrl: 'Confusion Matrix',
    aiExecuting: 'In progress',
    aiExecute: 'Execute',
    aiFit: 'Fit',
    aiModelDefindMessage1: 'Required - recommended by the system, model types can be switched down according to actual situations',
    aiModelDefindMessage2: 'Required - Please select an evaluation indicator based on the selected model type as the basis for evaluating the effectiveness of the model',
    aiModelDefindMessage3: 'Required - please click or enter a positive integer',
    aiModelDefindMessage4: 'Required - supports multiple selections',
    aiModelDefindMetric: 'Evaluation metrics',
    aiModelDefindTimeBudget: 'Time allocation',
    aiModelDefindEstimators: 'Selected model',
    aiModelDefindTimeBudgetInput: 'The time unit is seconds, please enter an integer value',
    aiModelDefindTimeBudgetUnit: 'Seconds',
    aiModelDefindTaskValid: 'Please select a model type',
    aiModelDefindMetricValid: 'Please select an evaluation metric',
    aiModelDefindTimeBudgetValid: 'Please enter a time allocation',
    aiModelDefindEstimatorsValid: 'Please select the selected model',
    aiModelStoreSelectTitle: 'Select Model',
    aiModelStoreSelectMessage: 'Required - predict based on the selected model and version',
    aiModelStore: 'Model library',
    aiMultiTables: 'Input Tables',
    aiDelectConfirm: 'Do you want to delete it?',
    aiNoParentNode: 'This node does not have an upstream node',
    aiInputRuleTable: 'Rule table input',
    aiExporting: 'Exporting',
    aiExportFileName: 'Data_download.xlsx',
    aiRuleConfig: 'Rule configuration',
    aiBaseRuleMoney: 'Transaction amount',
    aiBaseRuleDate: 'Transaction date',
    aiBaseRuleYear: 'Trading year',
    aiBaseRuleCode: 'Transaction code',
    aiBaseRuleCustomerCode: 'Customer Code',
    aiBaseRuleOperatorCode: 'Operation code',
    aiBaseRuleTime: 'Transaction year day hour minute second',
    aiBaseRuleHandlerCode: 'Financial Advisor Code',
    aiFeatureForecaseTitle: 'Feature sorting',
    aiFeatureForecaseMessage1: 'Please drag and drop the\' Matching Features\' column fields to sort and merge them with the \'Matching Features List\'. ',
    aiFeatureForecaseList: 'Fit feature list',
    aiFeatureForecaseMatch: 'Match features',
    aiFeatureForecaseMessage2: 'Drag and drop the\' Unmatched Features\' column field to the left to match features',
    aiFeatureForecaseNotMatch: 'Unmatched feature',
    aiFeatureForecaseSelectFeatures: 'Select Features',
    aiFeatureForecaseSelectLabel: 'Select label',
    aiFeatureSingle: 'Single choice',
    aiFeatureMessage1: 'At least one feature must be selected, supporting multiple selections; Labels and features cannot be selected in the same field at the same time ',
    aiFeatureMessage2: 'Tag only single choice; Labels and features cannot be selected in the same field at the same time ',
    aiFeatureForecaseIsFeatures: 'Select Features',
    aiFeatureForecaseIsLabel: 'Select label',
    aiSelectTable: 'Select Table',
    aiSelectModelValid: 'Please select a model first',
    exploreTabDisplayName1: 'Frequency statistics',
    exploreTabDisplayName2: 'Ring chart',
    exploreTabDisplayName3: 'Line Chart',
    exploreTabDisplayName4: 'Histogram',
    exploreTabDisplayName5: 'Correlation analysis',
    exploreTabDisplayName6: 'Aggregation Statistics',
    exploreTabDisplayName7: 'Bar chart',
    exploreByHour: 'By hour',
    exploreByDay: 'By day',
    exploreByMonth: 'By month',
    exploreByYear: 'By year',
    explore50Date: 'Only supports displaying the top 50 occurrences',
    exploreDataTypeLetter: 'Text',
    exploreDataTypeTime: 'Time',
    exploreDataTypeDate: 'Date',
    exploreDataTypeText: 'Text content',
    exploreDataTypeId: 'ID content',
    exploreDataTypeNumber: 'Numeric',
    exploreDataTypeBoolean: 'Boolean value',
    exploreTimes: 'Times',
    exploreField: 'Field name',
    exploreAnalyticalType: 'Analysis type',
    exploreStatisticsTypeSum: 'Total',
    exploreStatisticsTypeCount: 'Number of rows',
    exploreStatisticsTypeAvg: 'Mean',
    exploreStatisticsTypeDistinct: 'By de duplication count',
    exploreOthers: '=All others=',
    exploreLoadDataFailure: 'Data loading failed, please reselect the field',
    exploreLoadDataFail2: 'Data loading failed',
    modelRuleTableName: 'Rule model name',
    modelRuleCategory: 'Application field',
    modelIsDimTableValid: 'Only one primary key form is supported as a dimension table',
    modelIsBasicruleValid: 'There is at least one basic rule or aggregation rule in the model',
    modelIsAggruleValid: 'There is at least one base rule or aggregation rule in the model',
    processDataTableName: 'Data processing table name',
    processDataCategory: 'Data Domain',
    processInputTableValid: 'No input table selected',
    processNoExecuteRight: 'No permission to execute',
    processExecuteNodeValid: 'Please add an execution node',
    scheduleCalendarTime: 'Run time',
    scheduleCalendarState: 'Running state',
    scheduleCalendarTask: 'Task',
    scheduleCalendarDate: '{year}/{month}/{day}',
    scheduleKeyword: 'Enter the task name to search for',
    scheduleWeek0: 'Sunday',
    scheduleWeek1: 'Monday',
    scheduleWeek2: 'Tuesday',
    scheduleWeek3: 'Wednesday',
    scheduleWeek4: 'Thursday',
    scheduleWeek5: 'Friday',
    scheduleWeek6: 'Saturday',
    scheduleTypeTitle: 'Task Category',
    scheduleViewConfig: 'View task scheduling settings',
    scheduleLast5Times: 'Display only the last 5 times',
    scheduleNextExecuteTime: 'Next execution time',
    modelDefaulField: 'Field1',
    modelDefaulFieldValue: 'Field',
    modelCompoundRule: 'Rule',
    modelCompoundRuleName: 'Rule name',
    outputReviewConfig: 'Backtracking configuration',
    outputReviewPart: 'Partial backtracking',
    outputSelectTimeField: 'Select a time field',
    outputSelectTimeFieldValid: 'Select time field',
    outputReviewTime: 'Backtracking time',
    outputReviewTimeDay: 'Day',
    outputReviewTimeMonth: 'Month',
    outputReviewTimeYear: 'Year',
    outputReviewTimeRange: 'Period',
    resultRuleType: 'Rule type',
    resultRuleName: 'Rule name',
    resultWeight: 'Weight',
    resultTotal: 'Total number of rule objects',
    resultHint: 'Number of hits',
    resultHintPct: 'Hit ratio',
    resultExecutTime: 'Refresh time',
    resultHintChart: 'Hit Chart',
    resultWeightSum: 'Weight Summary',
    editField: 'Edit field',
    componentMessageBox: 'The notification prompt content should not exceed two lines, and will be automatically omitted if it exceeds two lines.',
    componentCursorExpError: 'The separator cannot be entered here',
    componentCursorExpUnknown: 'Unknown operation',
    componentIconSelect: 'Please enter an icon name',
    componentFileUploading: 'Uploading...',
    componentFileAddFile: 'Add attachment',
    componentFileDeleteFile: 'Delete',
    componentFileDownloadFile: 'Download',
    componentFileNotSupportPreview: 'This file does not support preview',
    componentFileDownloading: 'Downloading...',
    componentFileDownloadFailure: 'File download failed',
    componentFileDeleteConfirm: 'Are you sure you want to delete the file?',
    componentFileDeleting: 'Deleting...',
    componentFileDeleteFailure: 'Delete failed',
    componentFileTypeValid: 'Cannot upload files of this type',
    componentFileUploadFailure: 'File upload failed',
    componentDataTableOperator: 'Operation',
    componentDataTableFilter: 'Filter',
    componentDataTableFilterControl: 'Sort Master Control',
    componentDataTableReset: 'Restore default',
    componentDataTableCol: 'Configure columns',
    componentDataTableFields: 'Selected Fields',
    componentCursorFunSelectInput: 'Search or directly select',
    componentCursorFunSelectTitle: 'Func',
    componentCursorFunSelectSug: 'Function 1/Function',
    componentCursorConstSelectInput: 'Input constant',
    componentCursorConstSelectNum: 'Number',
    componentCursorConstSelectConst: 'Constant',
    componentCursorConstSelectConstShort: 'Const',
    componentCursorConstSelectNumValid: 'Please enter a number',
    componentCursorConstSelectConstValid: 'Constant cannot be empty',
    componentCursorConstSelectPi: 'Pi',
    componentCursorConstSelectNull: 'Null',
    componentCursorOperatorSelect: 'Symbol',
    componentCursorOperatorSelectValid: 'Please enter or select + - * /',
    componentCursorTableFieldSelect: 'Field',
    componentCursorTableFieldSelectInput: 'Search or directly select',
    componentEcharRowNum: 'Number of rows',
    componentRuleCalc: 'Calculate',
    componentRuleOr: 'Or',
    componentRuleAnd: 'And',
    componentRuleSelectorInput: 'Please select/blur search',
    componentRuleSelectorFieldInput: 'Input box',
    componentRuleSelectorFieldBracket: 'Brackets',
    componentRuleSelectorFieldOperator: 'Operator',
    componentRuleSelectorFieldFunc: 'Function',
    componentRuleSelectorFieldFuncAggregate: 'Aggregate function',
    componentRuleSelectorFieldDate: 'Date',
    componentRuleSelectorFieldOther: 'Other',
    graphTableValid: 'Please select an input table',
    graphTableReqValid: 'Table name cannot be empty',
    graphTableTypeValid: 'Invalid type',
    graphTableModelSelect: 'Select a model',
    graphTableModelDefind: 'Model Definition',
    graphCausalInfeDistinguish: 'View causal identification',
    graphSelectFile: 'Select file',
    graphSelectObject: 'Select recognition object',
    graphPicView: 'Picture preview',
    graphSelectAudioFile: 'Audio file',
    graphPlayAudioFile: 'Audio playback',
    graphSelectTextFile: 'Text input',
    graphSelectCategory: 'Select type',
    expressionUnknownType: 'Unknown data type',
    expressionFormatError: 'Format error',
    componentRuleFormatError: 'Incorrect data format',
    graphCommonNoCanclerPerm: 'No permission to cancel execution',
    graphCommonAddNodeValid: 'Please add an execution node',
    graphCommonStopConfirm: 'Are you sure you want to immediately terminate the execution of the task?',
    graphCommonStopping: 'Aborting task...',
    graphCommonStopSuccess: 'The task has stopped',
    graphCommonStopError: 'An unknown error occurred while the task was stopping',
    libSelectTable: 'Please select a table name',
    libSelectField: 'Please select a field',
    libSelectFile: 'Please select a file',
    libSelectFildNone: 'No file selected',
    libSelectInputTable: 'Please select an input table',
    libSelectLabel: 'Please select a label',
    graphBaseLoading: 'Loading data',
    graphBaseDataError: 'Data error',
    graphBaseNodeValid: 'Please check if the node configuration is correct',
    graphBaseNodePreview: 'Parsing node data',
    graphUtilsSelectPrimaryTable: 'Please select the main table',
    graphViewBasePreview: 'Data preview',
    graphViewBasePreviewDim: 'Data preview (dimension table)',
    graphViewBaseCopy: 'Copy',
    graphViewBaseMainTable: 'Main Table',
    graphViewBaseDimTable: 'Dimension Table',
  },
  youzhengWbColumns: {
    name: 'Workbench',
    roleType: 'My role',
    ownerUserId: 'Owner',
    collaboratorsNumber: 'Number of collaborators',
    viewerNumber: 'Number of viewers',
    createTime: 'Creation time',
    updateTime: 'Last modified time',
    confirmDeleteTable: 'Are you sure you want to delete this workbench? It will not be restored after deletion?',
  },
  wbColumns: {
    status: 'Project Status',
    name: 'Project Name',
    userName: 'Owner',
    createTime: 'Creation time',
    updateTime: 'Last modified time',
    dataAuthorizeCount: 'Number of data authorizations',
  },
  integrate: {
    all: 'All',
    analyticalType: {
      id: 'ID',
      numeric: 'Numeric',
      boolean: 'Boolean',
      text: 'Text',
      date: 'Date',
      timestamp: 'Time',
      category: 'Type',
    },
    viewData: 'View Data',
    executeAnalysis: 'Execution Statistics',
    confirm_download_data_table: 'Are you sure you want to download data?',
    data_field_type_integer: 'Integer',
    data_field_type_float: 'Float',
    data_field_type_boolean: 'Boolean',
    data_field_type_string: 'String',
    data_field_type_date: 'Date',
    data_field_type_timestamp: 'Timestamp',
    data_field_type_time: 'Time',
    data_field_type_number: 'Number',
    numberOfBasicRules: '{count} Basic rules',
    numberOfAggregateRules: '{count} Aggregation rules',
    numberOfCompoundRules: '{count} Compound rules',
    table_field: 'Field',
    constant: 'Constant',
    operator:' Operator',
    bracket: 'Bracket',
    comma: 'Comma',
    function: 'Function',

    confirmExecute: 'Are you sure you want to execute?',

    outputNode: 'Output node',
    customScript: 'Custom scripts',
    customModel: 'Custom models',
    dataAccess: 'Data Mart',
    dataProcess: 'Data processing',
    aiModel: 'Intelligence engine',
    rules: 'Rule engine',
    wait: 'Waiting',
    running: 'Running',
    success: 'Success',
    fail: 'Failure',
    warning: 'Warn',
    share: "Share",
    inApplication: 'In application',
    dataType: 'Data type',
    runningStatus: 'Status',
    dataArea: 'Data domain',

    confirmDeleteTable: 'Are you sure you want to delete this data table? It will not be restored after deletion?',
    owner: "Owner",
    permissionType: "Permission Type",
    nextRunTime: 'Next run time',
    nextRunTime1: 'Next run time',
    numberOfDataRows: 'Number of rows',
    quantityToBeAuthorized: 'Number of cases to be authorized',
    pleaseEnterNTO: 'Fuzz search by table name, creator name',
    cardStateOcrResult: 'All recognition results: {count} characters',
    cardStateAsrResult: 'All recognition results: {count} characters',
    cardStateDetectResult: 'Identify object type: {count}',
    cardStateAutomlFitResultType: 'Fit feature: {count}',
    cardStateAutomlFitResultCount: 'Fit quantity: {count}',
    cardStateAutomlPredictResultType: 'Prediction feature: {count}',
    cardStateAutomlPredictResultCount: 'Predicted quantity: {count}',
    cardStateNlpClassifierResultType: 'Type: {count}',
    cardStateNlpClassifierResultCount: 'Quantity: {count}',
    cardStateScriptResultType: 'Type: {count}',
    cardStateScriptResultCount: 'Quantity: {count}',
    cardStateCustomimModelResultType: 'Type: {count}',
    cardStateCustomimModelResultCount: 'Quantity: {count}',
    cardStateDataProcessResult: 'Number of data rows: {count}',
    cardStateRunning: 'Running',
    cardStateWarning: 'Alarm',
    scheduleNoSet: 'Not set',
    scheduleDetailInfo: 'Display only the last 5 times',
    scheduleNextTime: 'Next execution time',
    imageLoading: 'Loading',
    supplierChartInternalPerson: 'Internal employee',
    supplierChartExternalRelative: 'Related relative',
    supplierChartExternalCompany: 'External Company',
    supplierChartExternalPerson: 'External personnel',
    supplierChartFullscreen: 'Full screen',
    supplierChartCancelFullscreen: 'Exit full screen',
    aiModelIndexKeyword: 'Please enter the table name and creator for fuzzy search',
    aiModelIndexDeleteConfirm: 'Are you sure to delete the model?',
    aiModelEditDataTableNameValid: 'The data processing table name cannot be empty',
    aiExecuteLogEndTime: 'Completion time',
    aiExecuteLogStartTime: 'Execution time',
    aiExecuteLogLog: 'Execution result',
    aiExecuteLogStatus: 'Status',
    aiDataPreviewTitle: 'Data Preview',
    aiDataPreviewMessage: 'Display up to the first 1000 pieces of data',
    aiGraphSelectFileMessage: 'Supports batch addition of images or local uploading of individual images through forms',
    supplierEntityName: 'Entity Name',
    supplierEntityLabel: 'Entity label',
    supplierEntityProp: 'Entity attribute',
    supplierEntitySource: 'Source Entity',
    supplierEntityTarget: 'Target entity',
    supplierEntityRelativeType: 'Relationship type',
    supplierEntitySearchKeyword: 'Please enter the node name to search',
    supplierEntitySearchLLevel1: 'Level 1',
    supplierEntitySearchLLevel2: 'Level 2',
    supplierEntitySearchLLevel3: 'Level 3',
    supplierEntitySearchLLevel4: 'Level 4',
    supplierEntitySearchNodeId: 'Please enter the source node ID to search',
    supplierEntitySearchSourceNodeName: 'Please enter the source node name for search',
    supplierEntitySearchTargetNodeId: 'Please enter the source node ID to search',
    supplierEntitySearchTargetNodeName: 'Please enter the source node name for search',
    supplierEntitySearchPathValid: 'There is a mandatory field for source node id and source node name, and a mandatory field for target node id and target node name',

    search: 'Search',
    create: 'New',
    operate: 'Action',
    dataSourceTable: 'Data source table',
    tableName: "Table Name",
    currentAuthorization: "Authorization",
    authorization: "Authorization",
    dataAccessName: 'Data input name',
    dataAccessName1: "Please fill in data access name",
    pendingAuthorization: 'Pending for authorization',
    authorized: 'Authorized',
    notApplied: 'Not applied',
    selfData: 'Self-owned data',
    rejected: 'Rejected',
    revoked: 'Revoked',
    allProjects: 'All Projects',
    marketManager: "Market Manager",
    details: 'Details',
    unauthorized: 'Not Authorized',
    authorizedProject: 'Authorized Projects',
    add: 'Add',
    dataAccessBasicInformation: 'Basic information of data input',
    authorizedApproval: 'Authorization approval',
    currentStatus: 'Current status',
    authorityRequest: 'Permission Application',
    reasonForFields: 'Application Field',
    reasonForApplication: 'Application reason',
    reasonForApproval: 'Reason for approval',
    pleaseInputData: 'Please fill in the reason for authorization or rejection, click "Authorize" or "Reject" to send the reason for approval to the application initiator',
    pleaseInputData1: 'Optional - when it is empty, click "Cancel" to directly push the approval result to the application initiator. After cancellation, it cannot be restored.',
    pleaseInputData2: 'Optional - when it is empty, click "Authorize" or "Reject" to directly push the approval result to the originator of the application',
    pleaseInputData3: 'Optional - when it is empty, click "Authorize" to directly push the approval result to the application sponsor',
    columnlevelPermissionConfiguration: 'Column-Level Permission Configuration',
    fieldName: 'Field Name',
    fieldType: 'Field Type',
    primaryKey: 'Primary Key',
    encryptingOption: "Encrypting Option",
    maskingOption: 'Is data desensitized',
    revoke: 'Undo',
    authorize: 'Authorize',
    reject: "Reject",
    save: 'Save',
    saveAndBack: 'Save and Return',
    cancel: 'Cancel',
    close: "Close",
    runningLog: 'Run Log',
    startTime: 'Start Time',
    completeTime: 'End Time',
    operationResult: 'Result',
    operationStatus: 'Status',
    pleaseInputData4: 'Please fill in the application reason, click "Apply" to send the application reason to the data mart administrator',
    pleaseInputData5: 'Optional - When empty, click "Apply" to directly push the approval result to the application initiator',
    application: 'Application',
    pleaseNameThisImportedTable: "Please name this imported table",
    pleaseInputData6: 'Required - Cannot start with numbers. "_" and "/"are allowed. Allow 12 Chinese characters or 24 English letters',
    commentOfDataAccess: 'Data Input Note',
    canAddCommentToTable: 'Add Notes for Data table',
    dropDownToSelect: 'Please drop down to select',
    refreshMethod: 'Refresh Mode',
    clickOrPullToUploadExcelFiles: 'Select or drop files',
    remove: 'Delete',
    fileName: 'File Name',
    fileSize: 'File Size',
    fileType: 'File Type',
    OfData: 'Data Size',
    selectDataSource: 'Select Data Source',
    tableList: 'Table',
    dataMart: 'Data Mart',
    pleaseEnterSQL: "Please Enter SQL",
    pleaseInputData7: 'Required - Select Database input first, then select tables in the database',
    forApproval: 'Authorize',
    creater: 'Created by',
    numberOfDataColumns: 'Number of columns',
    numberOfDataRows1: 'Number of rows',
    numberOfRows1: 'Number of rows',
    whetherToShare: 'Shared',
    current: 'Current',
    status: 'Status',
    authStatus: 'Authorization status',
    pleaseInputData8: "Optional - Can add comment to table. Can be viewed in project data mart.",
    pleaseInputData9: "Annotate data fields for easy viewing and management",
    pleaseInputData10: 'Required - Select Database input first, then select tables in the database',
    dataPreview: 'Data Preview',
    newDataAccess: "NEW Data Access",
    pleaseInputData11: "Please enter the process flow, creator for fuzzy search",
    confirmDetail1: "Current edits will be lost after return. Are you sure to continue?",
    tableInput: 'Table Input',
    pleaseInputData12: 'Fuzzy Search by Workbench Name',
    disabled: "Disabled",
    activated: "Activated",
    flowRecord: 'Records',
    approved: "Approved",
    rejected1: "Rejected",
    updateAuthorization: "Update Authorization",
    cancelled: "Cancelled",
    pleaseInputData13: 'Please fill in the name',
    pleaseInputData14: "Please select a data type",
    sysFieldName: "System Field Name",
    fieldChineseName: 'Field Name',
    fieldChineseName1: "Chinese Name",
    modelType: 'Model type',
    refreshState: 'Refresh the status',
    range:'Application domain',
    loading: 'Desperately Loading',
    loaded: 'Loading completed',
    modelName: 'Model name',
    modelRemark: 'Model remark',
    modelSourceType: 'Model source',
    inputModelName: 'Please fill in the model name',
    selectFunc: 'Please select a feature',
    noMoreThirty: 'Up to 30 Chinese characters',
    range1:'Application domain',
    selectRange1: 'Please select application domains',
    refrashing: 'Refreshing',
    selectFunc1: 'Select Model',
    imageReco: 'Image Recognition',
    textReco: 'Text Recognition',
    pleaseInputData15:'Please complete the form',
    savedSuccessfully: 'Saved successfully',
    pleaseInputData16: 'The data type and data table name cannot be empty',
    dataDetail: 'Data Details',
    refreshDataDetail: 'Refresh Table Structure',
    autoMlFitting: "Auto ML Fitting",
    autoMlPrediction: 'AUTO ML Prediction',
    nlpClassifier: 'Text classification',
    nextStep: 'Next',
    ruleResultquery: "Only Supports Querying The Results Of Adding Images In Batches To The Table",
    selectFile: "Required - Can Be Added In Bulk Through The Table Or Local Upload Of A Single Image",
    selectFileText: 'Required - Please select an input form',
    classCategory: 'Required - Select the recognition object type',
    selectObjectType: 'Required - Select the recognition object type',
    newRuleEngine: 'Create a new rule engine',
    editRuleEngine: 'Edit the rule engine',
    newRuleProcess: "Create new data processing",
    editRuleProcess: "Edit data processing",
    yes: "Yes",
    no: "No",
    asr: 'Speech recognition',
    audioPath: 'Audio path',
    causalInference: 'Causal Recogniction',
    uploadTime: 'Upload Time',
    pleaseInputData17: 'Full: Replace previous data with full new data; Incremental: Keep previous data and append new data',
    // pleaseInputData8: 'Please fill in the application reason, click "Apply" to send the application reason to the data mart administrator',
    aiClassify1: 'Decision Intelligence',
    aiClassify2: 'Artificial Intelligence',
    types: {
      autoMlFitting: {
        title: 'AUTO ML fitting',
        tag1: 'Risk Analysis',
        tag2: 'Traffic prediction',
        content: 'Training machine learning models based on historical data, figure out universal rules apply to all potential samples, while find out the optimal expression which reflect the relationship between variables, to prepare for subsequent predictions.'
      },
      autoMlPrediction: {
        title: 'AUTO ML Prediction',
        tag1: 'Risk Prediction',
        tag2: 'Traffic prediction',
        content: 'Use the fitted machine learning model to regress or classify the relevant business characteristics to guide the direction of business development.'
      },
      causalInference: {
        title: 'Causal Recogniction',
        tag1: 'AB test',
        tag2: 'Attribution Analysis',
        content: 'Counterfactual estimation based on observational data, analysis of causal relationships between intervention and outcome, including modeling, identification, estimation and rebuttal.'
      },
      customScript: {
        title: 'Custom scripts',
        tag1: ' custom development of complex scenes',
        content: 'Write SQL and Python executables for complex scenarios to personalize data operation.'
      },
      customModel: {
        title: 'Custom models',
        tag1: 'Free model application and management',
        content: 'Use your own model for prediction, which can be integrated with other risk scenarios and managed in a unified manner.'
      },
      timeSeries: {
        title: 'Time Series Prediction',
        tag1: 'Market trend analysis',
        tag2: 'Market volatility analysis',
        tag3: 'Traffic prediction',
        content: 'Time series is a type of algorithm used to study the change of data over time, and is a commonly used regression prediction method. Statistical analysis is carried out according to the development process reflected in historical data, and then by analogy or extension, to predict the possible future development trend of things.'
      },
      textReco: {
        title: 'Text Recognition',
        tag1: 'Text Extraction',
        tag2: 'Fapiao Recognition',
        tag3: 'Contract recognition',
        content:  'Coding SQL and Python formula of execution for complex scenarios to personalize data.'
      },
      imageReco: {
        title: 'Image Recognition',
        tag1: 'Object Recognition',
        tag2: 'Face recognition',
        content: 'Identify objects and portraits in images to provide a data basis for subsequent risk analysis.'
      },
      asr: {
        title: 'Speech recognition',
        tag1: 'Speech recognition',
        tag2: 'Voiceprint classification',
        content: 'Speech recognition model accurately identifies business conversations and voiceprints, and is suitable for scenarios such as voice data collection and voiceprint classification.'
      },
      nlpClassifier: {
        title: 'Text classification',
        tag1: 'Text classification',
        content: 'Categorize text messages according to pre-set tags.'
      },
    },
    stopConfirm: 'Are you sure to stop running immediately?',
    stopping: 'Stopping',
    stopSuccess: 'Stopped successfully',
    stopFail: 'Stop failed',
    executeConfirm: 'Are you sure you want to execute?',
    executing: 'Executing',
    executeSuccess: 'Executing successfully',
    executeFail: 'Executing failed',
    detectItemFileIndex: 'File preview',
    detectItemFileName: 'File name',
    detectItemFileInputType: 'File input method',
    detectItemFileSingleType: 'Single file - Local upload',
    detectItemOnlyImageType: 'Only supports preview of image type files',
    detectItemAllResult: 'Recognition result',
    detectItemTotal: 'Total',
    detectItemNoData: 'No data to display',
    detectItemLoadFail: 'Load Fail',
    imageSingleUpload: 'Single image - local upload',
    imageTableUpload: 'Batch images - select image input table',
    downloadConfirm: 'Do you want to download it?',
    downloading: 'Downloading',

    analysisType: 'Analysis type',
    nullPercentage: 'Null percentage',
    Maximum: 'Maximum',
    Minimum: 'Minimum',
    Average: 'Average',
    Median: 'Median',
    standardDeviation: 'Standar deviation',
    deduplicationCounting: 'Deduplication counting',
    frequencyStatistics: 'Frequency statistics',
    executionStatistics: 'Execution statistics',

    datasourceDeleteConfirm: 'Are you sure you want to delete \'{connectName}\'?',
    addAuthFieldSuccess: 'Successfully configured [{titleName}] authorization for the special and sent notification',

    exploreDetailAnalysis: 'Statistical analysis',
    exploreDetailQuery: 'Data query',
    exploreDetailQueryFitlerTitle: 'Query criteria',
    exploreIndexKeyword: 'Please enter a name keyword for fuzzy search',
    exploreIndexNoSetting: 'Not set',
    exploreIndexOwner: 'Owner',
    exploreIndexViewer: 'Viewers',
    exploreIndexCoordinator: 'Collaborator',
    exploreIndexDataNameValid: 'Please fill in the data source and table name',
    exploreIndexDataTypeValid: 'Please select a data type',
    exploreIndexZeroRow: '0 rows of data, cannot explore',

    SetAccessApprovalSpecial: '[{dataTableName}] has been sent for authorization for [Special]',
  },
  dataTable: {
    noComment: 'No comments',
    editLog: 'Edit Log',
    comments: 'Comments',
    sendComment: 'Send',
    noEditLog: 'No edit logs',
    noRunningLog: 'No run logs',
    enterCommentText: 'Please enter a comment',
  },
  process: {
    window:{
      name: 'Window Function',
      fieldNameRemark: 'Required - cannot be duplicate with the main table; Only forms with 1 primary key are supported as dimension tables',
      assign: 'Assignment rules',
      sortFields: 'Sorting rules',
      groupFields: 'Group Fields',
    },
    sample:{
      name: 'Sampling',
      sampleType: 'Sampling type',
      sampleType1: 'System sampling',
      sampleType2: 'Bernoulli sampling',
      sampleType3: 'Reservoir sampling',
      sampleQuantity: 'Sampling quantity',
      sampleQuantityByPercent: 'In proportion',
      sampleQuantityByPercentRemark: 'Note: Please enter a positive integer from 0 to 100 in the input box',
      sampleQuantityByFix: 'By quantity',
      sampleQuantityByFixRemark: 'Note: Please enter a positive integer in the input box',
      sampleMethod: 'Sampling method',
      sampleMethodByRandom: 'Random',
      sampleMethodByRandomRemark: 'Note: Random sampling indicates that data will be randomly extracted from the data table',
      sampleMethodByFix: 'Fixed',
      sampleMethodByFixRemark: 'Note: Fixed sampling parameters are automatically generated by the system. Click Update to refresh fixed sampling parameters',
    },
    selectInputTable: 'Select Input Table',
    rules: 'Filter Criteria',
    reopen: 'Reopen',
    confirmDetail: "After the page is re-opened, the current edits will be lost. Are you sure to reopen?",
    confirmDetail1: "After cancelation, the current edits will be lost. You can save it first. Are you sure to cancel and return to the data processing list page?",
    confirmDetail2: "User ABC has saved the current version, please switch to another version or create a new version to save",
    placeHolder: 'Remarks Information',
    selectVersion: "Select Version",
    unnamedDataProcessing: "Unnamed Data Processing",
    dataFieldNone: "Data Field: None",
    labelTextRequire: "Required - Select input form based on currently existing permissions",
    selectFields: 'Choose Fields',
    aggregationConditions: 'Aggregation Criteria',
    aggregateValue: 'Aggregation Values',
    update: "Update",
    newfield: 'New Field',
    mergeType: 'Merge Type',
    mergeInfo: 'Merging Information',
    mergedTable: 'Merged Tables',
    sortbyField: 'Field Sorting',
    pleaseEnter: "Please Enter",
    pleaseEnterOrSearch: 'Please Select or Search',
    pleaseSelectOrSearch: 'Please Select or Search',
    outputField: 'Output Field',
    outputTableName: "Output Table Name",
    placeHolder1: "Please name the output table of the data processing flow",
    labelTextRequire1: 'Required - No numbers are supported at the beginning, "_" and "/" are available, up to 12 Chinese characters or 24 English letters',
    increment: 'Incremental',
    increment1: 'Append',
    totalQuantity: 'Full',
    connectionType: 'Join Type',
    conditionalConnectionType: 'Conditional Join Type',
    leftTable: "Left Table",
    rightTable: "Right Table",
    newField: 'New Field',
    newJudgingField: 'New Judgment Field',
    tableAggregation: 'Aggregation',
    tableMerge: 'Table Merging',
    tableJoin: 'Table Join',
    tableOutput: 'Table Output',
    reqSelectInputTable: "Required - Select Input Data Table",
    opt10Table: "Optional - Add up to 10 dimension tables",
    optMultiField: 'Optional - Support creating multiple fields',
    optRule: "Optional - At least one basic rule or aggregated rule; 30 rules maximum",
    optSupportsUpTo: "Optional - Supports up to 30 rules",
    reqFeatureMustCheck: "Required - Feature Must Be Checked And Label Confirmed",
    reqSelectModel: "Required - Select The Specified Model Based On The Type",
    inputMainTable: 'Enter the primary table',
    dataAugmentation: 'Data Augmentation',
    createFields: 'Create field',
    basicRules: 'Basic Rule',
    aggregationRules: 'Aggregation Rules',
    compoundRules: 'Composite Rules',
    output: 'Output',
    resultsOverview: "Results Overview",
    dimensionTable: "Dimension Table",
    innerJoin:'INNER JOIN',
    outJoin:'OUTER JOIN',
    leftJoin:'LEFT JOIN"',
    rightJoin:'RIGHT JOIN',
    unionTable:'UNION',
    unionAllTable:'UNION ALL',
    andTable:'AND',
    orTable:'OR',

    confictMessage: 'User {username} has saved the current version, please switch to another version or create a new version to save',
    confictSelectOtherVersion: 'Please select another version',
    confictSaveConfirm: 'Are you sure you want to save the current content to the target version?',

    addNewTitle: 'New preprocessing',
    switchVersionConfirm: 'Do you want to switch versions?',
    pageParamError: 'Incorrect page parameters',
    noImplementation: 'Not implemented',
    dataNotSaved: 'Your data has not been saved',
  },
  model: {
    modelName: 'Model library',
    pReqText: 'Only the data table with the primary key can be selected as the primary table, and the number in parentheses represents the number of primary keys',
    basicInfo: 'Basic Information',
    basicRuleName: 'Basic Rule Name',
    reCombRuleName: 'Composite Rule Name',
    aggregationRuleName: 'Aggregation Rule Name',
    pleaseNameTheRule: 'Up to 24 characters, does not support the beginning of a number, ok to use "_"',
    ruleWeight: 'Rule Weights',
    placeHolderText: "Please enter a non-negative number",
    pReqText1: 'Required - The weight factor for this rule; If the weight is 0, this rule is invalid',
    pleaseRemarkThitRule: 'Add a description of this rule',
    pleaseRefreshRule: 'Update: Replace previous results; Append: Append new data without overwriting previous results',
    ruleConfiguration: 'Rule Configuration',
    basicRuleConfiguration: 'Basic Rule Configuration',
    aggregateDimensions: 'Aggregation Dimensions',
    aggregationRuleFilter: 'Pre-Aggregation Filtering',
    aggregationRuleConfiguration: 'Aggregation Rule Configuration',
    displayOptions: 'Presentation Options',
    displayRulesDetails: "Display Rules Details",
    showWeightRule: 'Display 0 Weight Rules',
    displayMainTableInfo: 'Display primary table information',
    displayDimensionTableInfo: 'Display Dimension Table Information',
    screenRuleName: 'Filter Rule Name',
    screenRunDate: "Screen Run Date",
    ruleType: 'Rule Type',
    ruleName: 'Rule Name',
    weight: 'Weights',
    totalNumberofRuleObjects: 'Total Number of Rule Objects',
    runningTime: 'Elapsed Time',
    mainTableInfo: 'Primary table information',
    selectDimensionTable: 'Select Dimension Table',
    pReqText2: 'Primary tables not selectable; Only data tables with one primary key can be selected as a dimension table',
    selectMainTableAssociatedFields: 'Select Primary Table Association Field',
    pReqText3: "Required - Please drop down to select the field associated with the main table",
    dimensionTableInfo: 'Dimension Table Information',
    byHits: "By the number of hits",
    unnamedRuleModel: "Unnamed Rule Model",
    pReqText4: 'Remarks Information',
    rf: 'Random Forest',
    lgbm: 'LightGBM',
    xgboost: 'Xgboost',
    lrl1: 'L1 logistic regression',
    outputCommentText: 'Effective when the rule refresh mode is updated, 1) Partial Backtracking: Update the results within a certain period of time, 2) Full Backtracking: Update all results'
  },
  contract: {
    // serialNumber: 'Number',
    // serialNumber: '关联条文',
    // serialNumber: '修改意见',
    // serialNumber: 'Action',
  },

  scheduleReport: {
    runningTime: 'Elapsed Time',
    tableNamePlaceholder: 'Fuzzy Search by task name',
  },

  scheduleSetting: {
    submitChange: 'Scheduling change requests',
    menuSchedule: 'Scheduling configuration',
    menuReport: 'Alert configuration',
    menuDataSync: 'Data push',
    enableSchedule: 'Whether to schedule',
    selectScheduleType: 'Select a scheduling mode',
    selectScheduleTypePlaceHolder: 'Select a scheduling method from the drop-down menu',
    selectScheduleTypeTip: 'Required - Select whether to rely on precedent tasks or to configure a scheduling frequency period',
    selectProTask: 'Select precedent tasks',
    enableAlert: 'Whether to alert',
    enableAlertTip: 'After being enabled, alerts are triggered each time a task is executed and are pushed to the task management module',
    enableSyncData: 'Whether to push',
    enableSyncDataTip: 'When data push is enabled, data is pushed to the target database each time the task is executed',
    syncDataTargetTableName: 'Push table names',
    selectTargetDataSource: 'Select the destination database',
    stateTypeOnlineApprove: 'Apply for online launch',
    stateTypeConfigured: 'Configured',
    stateTypeOnline: 'Live',
    stateTypeNotConfigured: 'Not configured',
    stateTypeOfflineApprove: 'Apply for switching offline',
    scheduledType0: 'Rely on precedent scheduling',
    scheduledType1: 'Independent scheduling',
    scheduleName: 'Schedule',
    submitFormChangeList: 'Change list',
    submitFormChangeListTotal0: 'Total',
    submitFormChangeListTotal1: 'Rows',
    submitFormExpand: 'Unfold',
    submitFormCollapse: 'Fold',
    submitDetailBizType: 'Module',
    submitDetailDataTableName: 'Task name',
    submitDetailVersion: 'Task version',
    submitDetailChangeTypeName: 'Change type',
    submitFormSelectApprovalPerson: 'Select the approver',
    submitFormRemark: 'Application reason',
    scheduleSetting: 'Task scheduling settings',
    application: 'Application',
    enableScheduleChangeWarning1: 'If a subsequent task is dependent on the current task, please rermove the dependency and then cancel the scheduling',
    enableScheduleChangeWarning2: 'When the current task scheduling is disabled, alert pushes will be stopped. Are you sure to continue?',
  },

  execution: {
    status: {
      queued: 'Waiting',
      finished: 'Success',
      failed: 'Failure',
      started: 'Started',
      stopped: 'Stop',
      canceled: 'Cancel',
      unkown: 'Unkown',
    }
  },
  dictValue: {    
    rule_fn_group: {
      'abs,ceiling,exp,floor,greatest,least,ln,log,mod,pi,rand,round,sqrt': 'mathematics',
      'instr,concat,replace,lcase,left,length,ltrim,rtrim,trim,substring,quote,right,ucase, regexp_replace': 'string',
      'curdate,curtime,date_add,date_format,date_sub,datediff,from_unixtime,to_date': 'date',
      'avg,count,group_concat,max,min,sum': 'polymerization',
      'distinct,to_char,cast,in': 'other',
      'row_number,rank,dense_rank,rank_dense,percent_rank,cume_dist,ntile,lag,lead,first_value,last_value,nth_value,first,last': 'window',
    },
    rule_fn_group_remark: {
      'avg': `AVG (expression)<br/>Returns the average value of an expression, where expression is a field<br/>For example, returns the average value of the Price field in the Products table<br/><code>SELECT AVG (Price) AS AveragePrice From Products</ Code>`,
      'count': `COUNT (expression)<br/>Returns the count of non NULL values of expression in the rows retrieved by the SELECT statement, where expression is a field<br/>For example, returns the number of non NULL values of the Item field in the Products table<br/><code>SELECT COUNT (Item) AS NumItem From Products</ Code>`,
      'group_concat': `GROUP_ CONCAT (expression)<br/>concatenates the expression values in the same group generated based on certain aggregation conditions, returning a string result where expression is a field<br/>For example, returns all items in the Products table in the current category<br/><code>SELECT Category, GROUP_ CONCAT (Item) AS ItemNames from Products GROUP BY Category</ Code>`,
      'max': `MAX (expression)<br/>Returns the maximum value in the field expression<br/>For example, returns the maximum value of the field Price in the data table Products<br/><code>SELECT MAX (Price) AS LargestPrice From Products</ Code>`,
      'min': `MIN (expression)<br/>Returns the minimum value in the field expression<br/>For example, returns the minimum value of the field Price in the data table Products<br/><code>SELECT MIN (Price) AS LargestPrice From Products</ Code>`,
      'sum': `SUM (expression)<br/>Returns the total of the specified fields<br/>For example, calculating the total quantity of the fields in the OrderDetails table<br/><code>SELECT SUM (Quantity) AS TotalItemsOrdered From OrderDetails</ Code>`,
      'instr': `INSTR (STR, SUBSTR)<br/>Searches for a specified character (SUBSTR) in a string (STR) and returns the position where the specified character was found (INDEX). If SUBSTR does not exist in STR, returns 0; The string that STR is searched for; The string that SUBSTR wants to search for<br/>For example: return the position of the field Item in the field Group in the data table Products<br/><code>SELECT Item, Group, INSTR (Item, Group) AS Index From Products</ Code>`,
      'concat': `CONCAT (s1, s2... sn)<br/>String s1, s2... sn and other multiple strings are merged into one string<br/>For example, merge the fields Item and Category in the Products table and connect them with '-'<br/><code>SELECT CONCAT (Category, '-', Item) from Products</ Code>`,
      'replace': `Replace (s, s1, s2)<br/>Replace string s2 with string s1 in string s.<br/>For example, replace Drive in the field Category in the Products table with SoftDrive<br/><code>SELECT Replace (Category, 'Drive', 'SoftDrive')</ Code>`,
      'lcase': `LCASE (s)<br/>Change all letters of the string s to lowercase<br/>For example, convert the field Category in the Products table to lowercase<br/><code>SELECT LCASE (Category) From Products</ Code>`,
      'left': `Left (s, n)<br/>Returns the first n characters of the string s<br/>For example, returns the first two characters in the string LongFor<br/><code>SELECT Left ('LongFor ', 2); -> Lo</code>`,
      'length': `LENGTH (s)<br/>Returns the length of field s<br/>For example: returns the length of field Item in the data table Products<br/><code>SELECT LENGTH (Item) AS LenPrice From Products</ Code>`,
      'ltrim': `LTRIM (s)<br/>Remove spaces at the beginning of string s<br/>For example: Remove spaces at the beginning of string LongFor<br/><code>SELECT LTRIM ('LongFor ') AS LeftTrimmedString; -> LongFor</code>`,
      'quote': `QUOTE (s)<br/>Use a backslash to escape the single quotation mark in s<br/>For example, in the data table Products, use a backslash to escape the single quotation mark in the field Item<br/><code>SELECT QUOTE (Item) From Products</ Code>`,
      'right': `Right (s, n)<br/>Returns the last n characters of the string s<br/>For example, returns the last two characters in the string LongFor<br/><code>SELECT Right ('LongFor ', 2); -> Or</code>`,
      'ucase': `UCASE (s)<br/>Change all letters of the string s to uppercase letters<br/>For example, convert the field Category in the Products table to uppercase<br/><code>SELECT UCASE (Category) from Products</ Code>`,
      'abs': `ABS (x)<br/>Returns the absolute value of x<br/>For example: Returns the absolute value of -1<br/><code>SELECT ABS (-1); -> 1</code>`,
      'ceiling': `CEILING (x)<br/>Returns the smallest integer greater than or equal to x.<br/>For example, returns the rounded up value of 1.5<br/><code>SELECT CEILING (1.5); -> 2</code>`,
      'exp': `EXP (x)<br/>Returns the x-th power of e<br/>For example: Calculate the cubic power of e<br/><code>SELECT EXP (3); -> 20.085536923188</code>`,
      'floor': `FLOOR (x)<br/>Returns the maximum integer less than or equal to x<br/>For example: Returns an integer less than or equal to 1.5<br/><code>SELECT FLOOR (1.5); -> 1</code>`,
      'greatest': `GREATEST (expr1, expr2, expr3,...)<br/>Returns the maximum value in the list<br/>For example: Returns the maximum value in the following number list<br/><code>SELECT GREATEST (3, 12, 34, 8, 25); -> 34</code>`,
      'least': `LEAST (expr1, expr2, expr3,...)<br/>Returns the minimum value in the list<br/>For example: Returns the minimum value in the following number list<br/><code>SELECT LEAST (3, 12, 34, 8, 25); -> 3</code>`,
      'ln': `LN (x)<br/>Returns the natural logarithm of a number, based on e<br/>For example: Returns the natural logarithm of 2<br/><code>SELECT LN (2); -> 0.6931471805599453</code>`,
      'log': `LOG (base, x)<br/>Returns the logarithm with a base of 2 as the specified number with a base<br/>For example: Returns the logarithm with a base of 2 as the base of 4<br/><code>SELECT LOG (2, 4); -> 2</code>`,
      'mod': `MOD (x, y)<br/>Returns the remainder of x divided by y<br/>For example: Returns the remainder of 5 divided by 2<br/><code>SELECT MOD (5,2); -> 1</code>`,
      'pi': `PI()<br/>Returns pi (3.141593)<br/>For example, returns the area of a circle with a radius of the field radius in the data table Circles<br/><code>SELECT PI() * radius * radius AS area from Circles</ Code>`,
      'rand': `RAND()<br/>Returns a random number from 0 to 1<br/><code>SELECT RAND() ->0.93099315644334</ Code>`,
      'round': `ROUND (x)<br/>Returns the integer closest to x<br/><code>SELECT ROUND (1.23456); -> 1</code>`,
      'sqrt': `SQRT (x)<br/>Returns the square root of x<br/>For example: Returns the square root of 25<br/><code>SELECT SQRT (25); -> 5</code>`,
      'curdate': `CURDATE()<br/>Returns the current date<br/><code>SELECT CURDATE(); -> January 04, 2021</code>`,
      'curtime': `CURTIME()<br/>Returns the current time<br/><code>SELECT CURTIME(); -> 2021-01-04T08:51:45.415278Z</code>`,
      'date_add': `DATE_ ADD (d, INTERVAL expr type)<br/>Returns the calculated start date d plus the date after a time period<br/><code>SELECT date_ Add ('2011-11-11 11:11:11:11 ', 5,' minutes'); -> 2011-11-11 11:16:11</code>`,
      'distinct': `DISTINCT (expression)<br/>Returns the result of field expression after deduplication<br/>For example: returns the result of field Item deduplication in the data table Products<br/><code>SELECT DISTINCT (Item) From Products</ Code>`,
      'to_char': `TO_ CHAR (d, format)<br/>Convert the date field d to a string type in a certain format<br/>For example: Return the time field SoldTime in the data table Products to a string type of 'yyyy/mm/dd'<br/><code>SELECT TO_ CHAR (SoldTime, 'yyyy/mm/dd') from Products</ Code>`,
      'cast': `CAST (expression, data_type)<br/>The return field expression is converted to data_ The result of type<br/>For example: return the result of converting the field SoldTime in the data table Products to char<br/><code>SELECT CAST (SoldTime, date) from Products</ Code>`,
      'date_format': `DATE_ Format (d, f)<br/>Display date as required by expression f<br/><code>SELECT DATE_ FORMAT (2011-11-11 11:11:11:11, '% Y -% m -% d% r'); -> 2011-11-11 11:11:11:11 AM</code>`,
      'date_sub': `DATE_ SUB (date, INTERVAL expr type)<br/>Returns the result of subtracting the specified time interval from the date<br/>For example: returns the OrderDate field in the Orders table minus 2 days<br/><code>SELECT OrderId, date_ Sub (OrderDate, 2, 'days') AS OrderPayDate from Orders</ Code>`,
      'datediff': `DATEDIFF (type, d1, d2)<br/>Calculate the number of days between dates d1->d2<br/>For example, return the time interval between the fields SoldTime and ProductedTime in the data table Products<br/><code>SELECT DATEDIFF ('days', ProductedTime, SoldTime) from Products</ Code>`,
      'from_unixtime': `From_ UNIXTIME (d, format)<br/>d is the parameter that needs to be processed (this parameter is a Unix timestamp), which can be a field name or directly a Unix timestamp string. Format is the return value format<br/>For example, return the result of converting the SoldTime field in the Data Table Products to% Y% m% d<br/><code>SELECT From_ UNIXTIME (SoldTime, '% Y% m% d') From Products</ Code>`,
      'to_date': `TO_ DATE (s, format)<br/>Convert the string type field s to a date type in a certain format<br/>For example: return the string type field SoldTime in the data table Products to a time type of 'yyyy mm dd'<br/><code>SELECT TO_ DATE (SoldTime, '% Y -% M -% D') From Products</ Code>`,
    }
  }
};
