





































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {Map} from '@/types/base'
import { getFullUrl, previewFile } from "@/utils/utils";
import { makePy } from "@/utils/zhFirstletter";
import { UserModule } from "@/store/modules/user";
import UserAvatar from '@/component/userAvatar/index.vue';
import { Theme, WorkspaceModule } from "@/store/modules/workbench";
import { ApplicationModule } from "@/store/modules/application";
import { Application, getApplicationInstance } from '@/pages/application';
import { getDicts } from "@/api/sys/dict/data";
@Component({
  components: {UserAvatar},
  dicts: ['sys_property','system_language'],
})
export default class UserSetting extends Vue {
    @Prop({default: false})
    visible!:boolean
    @Prop({default: 0})
    size!:number

    showSetting = false
    avatarImgPath = ''
    tempLang = ''
    tempTheme = ''
    tempAvatar = ''
    userSetting:Map<any> = {
        avatar: '',
        lang: '',
        theme: ''
    }
    checked = false
    fileInfo = {
        path : '',
        originName : '',
        extension : '',
    }
    get userInfo(){
       const roles = UserModule.roles.map(role=>role.roleName).join("/");
       const name = UserModule.name;
       const username =UserModule.username;
       return {
            roles,
            name,
            username
       }
    }
    get uploadHeader(){
        return {
            "Authorization": "Bearer "+getApplicationInstance().accessToken
        }
    }
    get uploadUrl() {
        return process.env.VUE_APP_SYSTEM_API+ 'user/config/upload';
        // return '/user/config/upload';
    }
    get currTheme() {
        return this.$store.state.user.theme
    }
    get currAvatar() {
     return this.$store.state.user.avatar
    }
    get currLang() {
        return this.$store.state.user.lang
    }
    get name(): string {
        return UserModule.name
    }
    get themeList(): Array<Theme>{
        return (WorkspaceModule.themeList || []).filter(theme=>theme.status=='0')
    }

    mounted() {
        this.getUserSetting()

        getDicts("system_handbook_config").then(({ data }) => {
            if(data) {
                data.forEach((d:any) => {
                    switch(d.dictLabel) {
                        case 'path':
                            this.fileInfo.path = d.dictValue;
                            break;
                        case 'originName':
                            this.fileInfo.originName = d.dictValue;
                            break;
                        case 'extension':
                            this.fileInfo.extension = d.dictValue;
                            break;
                    }
                })
            }
        });

        let flag = localStorage.getItem('user-training')
        console.log('flag', flag)
        if (flag && flag == '1') {
            this.checked = true
        } else {
            this.checked = false
        }
    }

    checkedChange(val: boolean) {
        this.checked = val
        if (val) {
            localStorage.setItem('user-training', '1')
        } else {
            localStorage.setItem('user-training', '0')
        }
    }

      
    previewFile() {
      previewFile(this.fileInfo);
    }

    _makePy(str: string) {
        return makePy(str)
    }
    logout(){
        this.$confirm((this as any).$t('app.confirmLogout'), (this as any).$t('app.confirm')).then(async () => {
            await getApplicationInstance().logout();
            // this.$router.replace({path: "/login",query:{from: 'logout'}})
            let url = `${location.origin}/login?from=logout`
            location.replace(url);
        })
    }
    getUserSetting() {
        console.log('this.dict.type.sys_property:', this.dict.type.sys_property)
        this.userSetting = {
            avatar: this.currAvatar,
            lang: this.currLang,
            theme: this.currTheme
        }
    }
    // 未保存设置，关闭弹窗
    async noSaveSetting() {
        const params = {
            lang : this.tempLang,
            theme : this.tempTheme,
            avatar : this.tempAvatar
        }
        await this.$store.dispatch('changeSetting', params)
        this.showSetting = false
    }

    handleAvatarSuccess(res: any, file: any) {
        if (res.code === '000000') {
        this.userSetting.avatar = res.data.sysFile.path
        } else {
        this.$message.warning((this as any).$t('app.uploadFail'));
        }
        console.log('file:', file)
    }
      // 保存设置
    async saveSetting() {
        // console.log('this.userSetting:', this.userSetting)
        await this.$store.dispatch('changeSetting', this.userSetting)
        
        const broadcast = new BroadcastChannel("targus3.5");
        broadcast.postMessage(JSON.stringify(this.userSetting));
        console.log('postMessage',JSON.stringify(this.userSetting))
        this.showSetting = false
    }

    // 选择语言
    async changeLang() {
        await this.$store.dispatch('changeSetting', this.userSetting)
    }
    // 选择主题
    async changeTheme() {
        await this.$store.dispatch('changeSetting', this.userSetting)
    }
    toSetting() {
        // 暂存语言、主题、头像
        this.tempLang = this.currLang
        this.tempTheme = this.currTheme
        this.tempAvatar = this.currAvatar
        this.getUserSetting()
        this.avatarImgPath = ''
        if (this.tempAvatar) {
            getFullUrl(this.tempAvatar).then(res => {
                console.log('res:url', res)
                this.avatarImgPath = res
            })
        }
        this.showSetting = true
    }

    @Watch('userSetting.avatar', { immediate: true })
    onChangeValue(val: any) {
        if (val) {
        getFullUrl(val).then(res => {
            console.log('res:url', res)
            this.avatarImgPath = res
        })
        }
    }
}
