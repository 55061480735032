import Vue from 'vue'
import Vuex from 'vuex'
import { IUserState } from './modules/user'
import { ITagsState } from './modules/tagsView'
import {IApplication} from './modules/application'
Vue.use(Vuex)
export interface IRootState {
    user: IUserState
    tags: ITagsState
    application: IApplication
}
export default new Vuex.Store<IRootState>({})
