import Vue from 'vue'
import VueI18n from 'vue-i18n'
import loadash from 'lodash';

// @ts-ignore
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
// @ts-ignore
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui lang
// @ts-ignore
import elementZhHansLocale from 'element-ui/lib/locale/lang/zh-TW'// element-ui lang

import enGlobal from '@/lang/en'
import zhGlobal from '@/lang/zh-CN'
import zhHansGlobal from '@/lang/zh-Hans'

import enLocale from './en'
import zhLocale from './zh-CN'
import zhHansLocale from './zh-Hans'

const enExt = loadash.extend({}, enGlobal, enLocale);
const zhExt = loadash.extend({}, zhGlobal, zhLocale);
const zhHansExt = loadash.extend({}, zhHansGlobal, zhHansLocale);

Vue.use(VueI18n)

const messages = {
    en: {
        ...enExt,
        ...elementEnLocale
    },
    'zh-CN': {
        ...zhExt,
        ...elementZhLocale
    },
    'zh-Hans': {
        ...zhHansExt,
        ...elementZhHansLocale
    }
}
export function getLanguage() {
    const chooseLanguage = localStorage.getItem('userLang')
    if (chooseLanguage) return chooseLanguage

    // 未选择语言
    const language = (navigator.language || (navigator as any).browserLanguage).toLowerCase()
    const locales = Object.keys(messages)
    for (const locale of locales) {
        if (language.indexOf(locale) > -1) {
            return locale
        }
    }
    return 'zh-CN'
}

const i18n = new VueI18n({
    locale: getLanguage(),
    messages
})
export function  $t(key: VueI18n.Path, choice?: VueI18n.Choice, values?: VueI18n.Values): string{ 
    // @ts-ignore
    return i18n.tc.call(i18n, key, choice, values); 
  } 
export default i18n
