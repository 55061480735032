export default {
  app: {
    content: {
      '0': ['安','永','智','慧','风','控','平','台'],
      '1': ['推','动','大','数','据','风','控','新','标','的'],
      '2': ['让','风','险','无','处','遁','形']
    },
    title: 'Ai创造引擎',
    pleaseSelect: '请选择',
    pleaseInput: '请输入',
    sessionTimeout: '会话已过期，请重新登录',
    networkError: '网络错误',
    hello: '您好!',
    welcome: '欢迎访问',
    help: '帮助',
    message: '消息',
    setting: '设置',
    logout: '登出',
    user: '个人',
    optionInput: '选填',
    mustInput: '必填',
    avatarUpload: '上传头像，文件不超过2M，png，jpeg或jpg格式',
    langSelect: '语言选择',
    zhCN: '中文简体',
    zhHans: '中文繁体',
    en: '英文',
    defaultLang: '默认中文简体,下拉进行切换',
    theme: '主题颜色',
    themeDark: '暗色-绿/灰',
    themeDark2: '暗色-黄/黑',
    themeLight: '亮色-黄/白',
    themeHuayou: '华友-蓝/白',
    defaultTheme: '默认暗色-绿/灰，下拉进行切换',
    save: '保存',
    cancel: '取消',
    close: '关闭',
    submit: '提交',
    confirm: '确认',
    upload: '上传',
    delConfirm: '确认删除？',
    delSuccess: '删除成功',
    uploadFail: '上传失败',
    confirmLogout: '确认要退出登录吗？',
    remark: '备注',
    actionSuccess: '操作成功',
    importSuccess: '导入成功',
    all: '全部',
    enable: '启用',
    disable: '禁用',
    action: '操作',
    createTime: '创建时间',
    updateTime: '修改时间',
    unknownProcess: '未知操作',
    module: '所属模块',
    add: '新增',
    name: '姓名',
    userName: '用户名',
    password: '密码',
    totla: '总共',
    online: '线上',
    outline: '线下',
    selected: '录制中',
    unSelected: '待上传',
    underReview: '智能飞检中',
    outReportsReview: '人工复核中',
    outReports: '已出报告',
    outReportsReviewIng: '待人工复核',
    savedSuccessfully: "保存成功",
    submitSuccessfully: "提交成功",
    reject: '驳回',
    approve: '批准',
    create: '新建',
    search: '搜索',
    flow: '流转',
    runSuccess: '执行成功',
    finish: '完成',
    send: '发送',
    training: '员工培训',
    trainingMaterials: '培训材料',
    trainingConfirmMsg: '我已完成培训，知晓本系统的使用方法及相关的安全操作规范',
    collapse: '折叠',
    expand: '展开',
    selectAll: '全选',
    preItem: '上一个',
    nextItem: '下一个',
    yes: '是',
    no: '否',
    submitting: '正在提交',
    none: '无',
    canPreview: '该文件无法预览，请下载后查看',
    showMenu: '显示菜单',
    hideMenu: '隐藏菜单',
    custom: '定制',
  },
  menu: {
    home: '首页',
    wb: '智慧工坊',
    lastModified: '最近修改',
    integrate: '数据集市',
    dataAccess: '数据接入',
    process: '数据处理',
    model: '规则引擎',
    ruleModel: '规则模型',
    ai: '智能引擎',
    explore: '数据探索',
    schedule: '任务调度',
    members: '团队成员',
    workPlan: '团队公告',
    noticePlaceholder: '请输入内容或表情，点击发布组内公告',
    workbenchSetting: '专项设置',
    roleManage: '角色管理',
    aiModel: 'AI模型',
    scheduleReport: '风险预警',
  },
  message: {
    markAllRead: '全部标记为已读',
    packUp: '收起',
    expand: '展开',
    noMore: '没有更多了',
    loadMore: '加载更多',
    teamInvitation: '团队邀请',
    confirmInvitation: '邀请确认',
    confirmRead: '确定全部标记为已读吗？',
    confirmDelMsg: '确定要删除该未读消息吗？',
    memberInvitation: '团队成员邀请',
    memberManage: '团队成员管理',
    specialManage: '专项管理',
    dataAuth: '数据权限授权',
    editorialInterpretation: '编辑解读',
    internalRegulationOptimization: '内规优化',
    articleUpload: '文章上传',
    articleRevision: '文章修正',
    crawlerMonitoring: '爬虫监控',
    myMessage: '我的消息',
    noRead: '未读'
  },
  system: {
    loading: "加载中",
  },
  common: {
    uploadFile: '将文件拖到此处，或<em>点击上传</em>',
    text_copied_to_clipboard: '文字已经复制到剪切板',
    confirm: '确定',
    approve: '审批',
    back: '返回',
    tips: '提示',
    rows: '行',
    columns: '列',
    data: '数据',
    previewTopRows: '仅预览前10行',
    previewTopRows1: '仅预览前1000行',
    previewTopRows5: '仅预览前5行',
    judgingFieldName: '判断字段名称',
    placeHolder: '请为新增判断字段命名',
    labelTextRequire1: '必填-支持汉字、数字、英文大小写及“_”，最多不超过50字符',
    rules: '筛选规则',
    assign: '赋值',
    edit: '编辑',
    download: '下载',
    export: '导出',
    import: '批量导入',
    downloadTemplate: '下载批量导入模板',
    query: '查询',
    resultsDisplay: '结果展示',
    version: '版本',
    resultQuery: '结果查询',
    

    unnamedDataProc: '未命名数据处理',
    unnamedRuleEngine: '未命名规则引擎',
    redo: '重做',
    recovery: '恢复',
    enlarge: '放大',
    narrow: '缩小',
    needPrimaryKey: '请选择至少一个主键',
    execute: '运行',
    auth: '授权',
    deleteRow: '删除',
    runningLog: '运行日志',
    view: '查看',
    editDisabled: '编辑（禁用）',
    deleteRowDisabled: '删除（禁用）',
    viewSpecial: '预览',
    explore: '数据探索',
    authSpecial: '申请权限',
    stop: '停止',
    deleteThisRow: '删除此行？',
    executeTask: '确认要执行任务吗？',
    executing: '正在执行',
    executeSuccess: '执行成功',
    executeFail: '执行成功',
    stopTask: '是否确认立即停止运行？',
    stopping: '正在停止',
    stopSuccess: '停止成功',
    stopFail: '停止失败',
    exportData: '确认要下载数据吗?',
    exploreZoreData: '0行数据，不能探索',
    deleteTask: '确认要删除吗？',
    deleting: '正在删除',
    deleteConfirm: '确认要删除吗？',
    select: '请选择',
    pleaseEnter: '请输入',
  },
  wb: {
    mySpecial: '我的专项',
    newSpecial: '新建智慧工作台',
    newRemark: '为了大家更了解工作台，写一些备注吧！',
    reportPlatform: '报表平台',
    currWorkbenchName: '专项的名字',
    currWorkbenchRemark: '大家一起协作完成这个新建智慧工作台吧！加油！',
    workbenchName: '专项名称',
    nameValidator: '一旦填写不可变更；最多10个字符，不支持数字开头，可使用"_"',
    canNotChange: '一旦填写不可变更',
    remark: '可为专项备注或发布公告，显示在专项首页',
    addEditremark: '有什么想对团队成员说的',
    remarkEdit: '可为专项备注或发布公告，专项所有者与协作者皆可编辑',
    remove: '移除专项',
    inputNamePlease: '请填写专项名称',
    nameFormat: '支持中文、大小写英文及“_”',
    confirmRemove: '一旦移除专项，相关表授权及调度都将禁止。是否确定移除？',
    removeSuccess: '移除专项成功',
    confirmLeave: '还未保存本次修改，离开后将无法恢复，是否确定？',
    roleType: '权限类型',
    agree: '同意',
    reject: '拒绝',
    collaborators: '协作者',
    viewer: '查看者',
    agreeInvitation: '确认同意邀请',
    rejectInvitation: '确认拒绝邀请',
    owner: '所有者',
    leave: '离开团队',
    removeMember: '移出团队',
    transferOwnership: '移交所有权',
    invitationAgain: '重发邀请',
    members: '团队成员',
    invitationNew: '邀请新成员',
    searchText: '请输入用户名/员工号/角色/部门进行搜索',
    sendInvitation: '发送邀请',
    selectMember: '请选择专项成员',
    isExist: '已存在，已自动去重',
    hasBeenSend: '专项成员邀请已发送',
    changeRole: '变更成员权限',
    leaveMsg: '是否确定离开团队？离开团队后，你在团队中所创建的文件将自动转交给团队所有者',
    rejected: '已拒绝',
    toBeConfirmed: '待确认',
    me: '我',
    status: '专项状态',
    createTimeSort: '创建时间',
    updateTimeSort: '最近修改时间',
    searchWbText: '请输入专项名称、所有者进行模糊搜索',
    replaceOwner: '替换所有者',
    jumpToItem: '跳转到专项',
    confirmReplace: '确认替换',
    dataAuthorizeCount: '数据权限数量',
    disableMsg: '一旦禁用专项，相关表授权及调度都将禁止。是否确定禁用？',
    enableMsg: '一旦启用专项，相关表授权及调度都将启用。是否确定启用？',
    selectPerson: '请选择人员',
    normalFieldRule: '最多24个字符，不支持数字开头，可使用"_"',
    dbTableNameRule: '最多24个字符，不支持数字开头，只可使用英文字符、数字、"$"和"_"',
    addFirstWork: '点击新建，创建第一个任务',
    noPermission: '无权限访问',
    newVersion: '新版本',
  },
  dictValue: {
  }
};
