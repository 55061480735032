var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { position: "relative", "background-color": "#25292a" } },
    [
      _c("div", { staticClass: "bg-mask", attrs: { id: "bgMask" } }),
      _c("article", { attrs: { id: "article" } }, [
        _c("div", { staticClass: "swiper-container" }, [
          _c("section", { staticClass: "section" }, [
            _c("div", { attrs: { id: "scaleContent" } }, [
              _c("div", { staticClass: "login-view" }, [
                _c(
                  "div",
                  { staticClass: "container" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "index-bg",
                        staticStyle: { height: "100%", width: "100%" },
                        attrs: { id: "indexBg" },
                      },
                      [
                        _c("svg-icon", {
                          staticStyle: { "font-size": "850px", height: "100%" },
                          attrs: { "icon-class": "logo-targus" },
                        }),
                      ],
                      1
                    ),
                    _c("div", {
                      staticClass: "bg-mask",
                      attrs: { id: "bgMask" },
                    }),
                    _c("div"),
                    _vm.typeName == "login"
                      ? _c(
                          "div",
                          { staticClass: "login-35" },
                          [
                            _c("div", { staticClass: "welcome" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("home.login.welcome")) + " "
                              ),
                              _vm._m(0),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "register",
                                on: {
                                  click: function () {
                                    _vm.typeName = "register"
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("home.login.register")))]
                            ),
                            _c("div", { staticClass: "login-types" }, [
                              _c(
                                "div",
                                { staticClass: "login-types-btn active" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("home.login.byPassword"))
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "login-types-btn disable",
                                  staticStyle: { "margin-left": "12px" },
                                },
                                [_vm._v(_vm._s(_vm.$t("home.login.bySSO")))]
                              ),
                            ]),
                            _vm._m(1),
                            _c("internal-35-new"),
                          ],
                          1
                        )
                      : _vm.typeName == "register"
                      ? _c("Register")
                      : _c("ForgetPassword"),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "title", staticStyle: { "font-size": "40px" } },
      [
        _c("i", { staticClass: "el-icon-arrow-left" }),
        _c(
          "span",
          {
            staticStyle: {
              position: "relative",
              top: "-2px",
              "font-size": "40px",
            },
          },
          [_vm._v("Targus ")]
        ),
        _c("i", { staticClass: "el-icon-arrow-right" }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login-decorate" }, [
      _c("div", { staticClass: "login-decorate-line" }),
      _c("div", [_vm._v("or")]),
      _c("div", { staticClass: "login-decorate-line" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }