var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "layout-user-info" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "user-message" },
      [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              height: "100%",
              "align-items": "center",
              gap: "20px",
            },
          },
          [
            _c(
              "el-badge",
              {
                staticClass: "reg-font-color message-btn",
                class: _vm.myMsgVisible ? "visible" : "",
                staticStyle: { cursor: "pointer" },
                attrs: { "is-dot": _vm.msgDot },
                nativeOn: {
                  click: function ($event) {
                    return _vm.showMessage($event)
                  },
                },
              },
              [
                _c("svg-icon", {
                  staticStyle: {
                    "font-size": "24px",
                    top: "2px",
                    position: "relative",
                  },
                  attrs: { "icon-class": "message" },
                }),
              ],
              1
            ),
            _c("UserSetting", { attrs: { size: 32 } }),
          ],
          1
        ),
        _c(
          "el-drawer",
          {
            staticStyle: { height: "calc(100% - 64px)", "margin-top": "64px" },
            attrs: {
              "append-to-body": true,
              "modal-append-to-body": false,
              visible: _vm.myMsgVisible,
              direction: "rtl",
              "show-close": false,
              size: 400,
            },
            on: {
              "update:visible": function ($event) {
                _vm.myMsgVisible = $event
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "msg-dialog-title",
                attrs: { slot: "title" },
                slot: "title",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "f-b-c reg-font-color",
                    staticStyle: { padding: "5px 10px 0 10px" },
                  },
                  [
                    _c("div", [
                      _vm._v(" " + _vm._s(_vm.$t("message.myMessage")) + " "),
                      _c("span", { staticStyle: { "margin-left": "20px" } }, [
                        _vm._v(
                          " (" +
                            _vm._s(_vm.$t("message.noRead")) +
                            "：" +
                            _vm._s(_vm.msgNum) +
                            ") "
                        ),
                      ]),
                    ]),
                    _c("i", {
                      staticClass: "el-icon-s-unfold",
                      staticStyle: { cursor: "pointer", "font-size": "20px" },
                      on: {
                        click: function ($event) {
                          _vm.myMsgVisible = false
                        },
                      },
                    }),
                  ]
                ),
                _c("el-divider"),
              ],
              1
            ),
            _vm.myMsgVisible ? _c("MyMessage") : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo-targus" }, [
      _c("div", { staticClass: "logo-ey" }),
      _vm._v(" Targus "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }