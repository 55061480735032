import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators"
import store from ".."
import {
    updateUserConfig
} from "@/api/user/setting";
import i18n from "@/pages/home/lang";
import { getList } from "@/api/workbench";
import { unReadNewsCount } from "@/api/user/message";
import { Menu } from "@/api/sys/menu";
import {addUserRoute, layouts} from '@/router';
import { Dept } from "@/api/system";
import { Theme, WorkspaceModule } from "./workbench";
import loadash from 'lodash';
import {MessageBox} from "element-ui";
import router from '@/router'
import { ApplicationModule } from "@/store/modules/application";
import { changeSetting } from "@/app-action";
import eventHub from "@/utils/eventHub"
export interface IUserState {
    name:string
    username: string
    userId: string
    activeRoleId:string
    roles: any[]
    menus: any[]
    theme: string
    lang: string
    avatar: string
    currWorkbench: any //当前专项信息
    workbenchMenus: any
    msgNum: number // 当前未读消息条数
}
@Module({dynamic: true, store, name: 'user'})
class User extends VuexModule implements IUserState{
    public userId=''
    public name = ''
    public username= ''
    public dept:Dept = {}
    public activeRoleId = ''
    public roles:any[] = []
    public menus:any[] = []
    public liuxiangMenus:any = []
    public theme = ''
    public lang = ''
    public avatar = ''
    public currWorkbench = {}
    public workbenchMenus = []
    public msgNum = 0
    public fixTheme = ''
    
    get perms():Array<string>{
        const menus = this.menus || []
        return menus.filter(menu => menu.perms).flatMap(menu => menu.perms.split(',')).map(perm=>perm.trim());
    }
    get leftMenu(){
       let menus:any[];
       if(Array.isArray(this.menus)){
            menus = JSON.parse(JSON.stringify(this.menus))
       }else{
            menus = []
       }
       const topMeu = menus.filter((menu:Menu) => menu.parentId === '0');
       const menuMap  = menus.reduce((map,cur)=>{
         map[cur.menuId] = cur
         return map;
       },{});
       for(const menu of menus){
           if(menu.type === '1' || menu.type === '0'){
                if(menuMap[menu.parentId]){
                    menuMap[menu.parentId].children = menuMap[menu.parentId].children || []
                    menu.parent = menuMap[menu.parentId];
                    menuMap[menu.parentId].children.push(menu)
                }
           }
       }
      return topMeu;
    }
    @Action
    public async GetUserInfo(){
        console.log('获取用户信息:',new Date().getTime())
        if (!ApplicationModule.isInited) {
            throw Error('GetUserInfo: application is not inited!')
        }
        try {
            const data = ApplicationModule.user;

            this.SET_USER_ID(data.id+'')
            this.SET_NAME(data.name)
            this.SET_USERNAME(data.username)
            this.SET_ROLES(data.roles || data.roleList ||[])
            this.SET_MENUS(ApplicationModule.userMenus||[])
           
            this.SET_DEPT(data.dept||{})
            await this.registerUserRoute()
            // 获取用户设置
            await this.getUserSetting()
            // 获取未读消息条数
            await this.getMsgNum()
            if(data.remind){
                MessageBox.alert(data.remind,"凭证到期提醒",{ type: 'warning' });
            }

        }catch(e){
            console.error("error on process login response ",e)
            this.Reset()
        }

    }
    @Mutation
    SET_DEPT(dept:Dept){
        this.dept = dept;
    }
    @Mutation
    SET_USER_ID(userId:string){
        this.userId = userId
    }
    @Mutation
    SET_MENUS(menus: any[]) {
        this.menus = menus
    }
    @Mutation
    SET_LIUXIANGMENUS(menus: any){
        this.liuxiangMenus = menus;
    }
    @Mutation
    SET_USERNAME(username: string) {
        this.username = username;
    }

    @Action
    public  async Reset() {
       this.SET_MENUS([])
       this.SET_ROLES([])
       this.SET_WORKBENCH_MENUS([])
       this.SET_CURR_WORKBENCH({})
       this.SET_USER_ID('')
       this.SET_USER_ID('')
       this.SET_USERNAME('')
       this.SET_NAME('')

    }
    @Mutation
    SET_NAME(name: string) {
        this.name = name
    }
    @Mutation
    SET_ROLES(roles: any[]) {
        this.roles = roles;
    }
    @Mutation
    SET_FIX_THEME(theme: string):void {
        this.fixTheme = theme
    }
    @Mutation
    SET_THEME(theme: string):void {
        this.theme = theme
        localStorage.setItem('userTheme', theme)
        document.documentElement.className = this.fixTheme || theme;
    }
    @Mutation
    SET_LANG(lang: string):void {
        this.lang = lang
        localStorage.setItem('userLang', lang)
        i18n.locale = lang
    }
    @Mutation
    SET_AVATAR(avatar: string):void {
        this.avatar = avatar
        localStorage.setItem('userAvatar', avatar)
    }
    @Action
    async registerUserRoute(){
        const menus:Array<Menu> = this.menus || []
        for(const menu of menus){
            if(menu.type == '1' && menu.component){

                const components = menu.component.split('#');

                if(components.length == 1) {
                    try{
                        addUserRoute({
                            path: menu.url || '',
                            component:  () => import(/* webpackChunkName: "targust-module" */'@/'+components[0]),
                            //@ts-ignore
                            meta: loadash.extend({menu: menu,title: menu.name || ''},menu.meta?JSON.parse(menu.meta):{})
                        })
                    }catch(error){
                        console.log("error on config")
                    }
                }
                else {
                    const layout_list = layouts;
                    console.log(layout_list)
                    // debugger
                    const routeConfig = {
                        //@ts-ignore
                        path: '',
                        //@ts-ignore
                        component: layouts[components[0]],
                        children:[]
                    };
                    let currentConfig = routeConfig;
                    for (let index = 1; index < components.length; index++) {
                        if(index+1 < components.length) {
                            //@ts-ignore
                            currentConfig.children.push({
                                //@ts-ignore
                                path: '',
                                //@ts-ignore
                                component: layouts[components[index]],
                                //@ts-ignore
                                children:[],
                                //@ts-ignore
                                meta: loadash.extend({menu: menu,title: menu.name || ''},menu.meta?JSON.parse(menu.meta):{})
                            })

                            currentConfig = currentConfig.children[0]
                        }
                        else {
                           
                            try{ 
                                 //@ts-ignore
                                currentConfig.children.push({
                                    //@ts-ignore
                                    path: menu.url || '',
                                    //@ts-ignore
                                    component:  () => import(/* webpackChunkName: "targust-module" */'@/'+components[0]),
                                    //@ts-ignore
                                    meta: loadash.extend({menu: menu,title: menu.name || ''},menu.meta?JSON.parse(menu.meta):{})
                                })
                            }catch(e){
                                console.error("error resolve links",menu,e)
                                //throw e;
                            }
                        }
                    }

                    addUserRoute(routeConfig)
                }
            }
        }

    }
    @Action
    async getUserSetting() {
        const data = ApplicationModule.userConfig;
        let userTheme = data.theme_config && data.theme_config.data
        await WorkspaceModule.loadThemeList()
        const themes = WorkspaceModule.themeList  || []
        const themeMap:{[key:string]:Theme} = themes.reduce((rs:any,theme)=>{
            rs[theme.code] = theme
            return rs;
        },{})
        if(!userTheme){
            if(themes.length>0){
                userTheme = themes[0].code
            }else{
                userTheme = process.env.VUE_APP_DEFAULT_THEME
            }
        }else{
            if(!themeMap[userTheme] && themes.length>0){
                userTheme = themes[0].code
            }
        }
        // 主题
        this.SET_THEME(userTheme)
        // 语言
        this.SET_LANG(data.language_config && data.language_config.data || 'zh-CN')
        // 头像
        this.SET_AVATAR(data.img_path && data.img_path.data || '')
    }
    @Action
    async changeSetting(setting: any) {
        // 用户设置请求接口
        console.log('setting:', setting)
        if(!setting.fromBroadcast) {
            await updateUserConfig(
            [{
                bizType: 'theme_config',
                data: setting.theme
            },{
                bizType: 'language_config',
                data: setting.lang
            },{
                bizType: 'img_path',
                data: setting.avatar
            }]
            )
        }
        this.SET_THEME(setting.theme)
        this.SET_AVATAR(setting.avatar)
        this.SET_LANG(setting.lang)

        setting.noUpdate = true; //子应用不更新
        changeSetting(setting);

        eventHub.$emit('changeSetting',setting);
    }
    // 设置当前专项信息
    @Mutation
    SET_CURR_WORKBENCH(wb: any) {
        console.log('设置当前专项信息:')
        this.currWorkbench = wb
    }
    // 专项菜单
    @Mutation
    SET_WORKBENCH_MENUS(menus: any) {
        this.workbenchMenus = menus
    }

    @Action
    async getWorkbenchMenus() {
        const res = await getList()
        this.SET_WORKBENCH_MENUS(res.data || [])
    }

    // 未读消息条数
    @Mutation
    SET_MSG_NUM(num: number) {
        this.msgNum = num
    }

    @Action
    async getMsgNum() {
        // 防止logout后仍定时获取
        if (ApplicationModule.login) {
            const { data } = await unReadNewsCount()
            this.SET_MSG_NUM(data || 0)
        }
    }
    @Action({root: true})
    hasRole(roleCode:string):boolean{
       const finded =  (this.roles||[]).find(r=>r.roleCode==roleCode);
       if(finded){
        return true;
       }
       return false;
    }

}
export const UserModule = getModule(User)
