var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout-style-35", [
    _c(
      "div",
      {
        staticClass: "userhome-35",
        staticStyle: {
          height: "100%",
          "padding-bottom": "20px",
          "min-height": "475px",
          width: "100%",
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "modules",
            staticStyle: { "margin-top": "30px", width: "1565px" },
          },
          [
            _vm._l(_vm.userHomeModules, function (mod, index) {
              return [
                _vm.appsConfig.indexOf(mod.menuId) > -1
                  ? _c(
                      "div",
                      {
                        key: index,
                        staticClass: "module",
                        class: mod.status === "1" ? "disable" : "",
                        staticStyle: { "margin-right": "30px" },
                        on: {
                          mouseenter: function ($event) {
                            return _vm.handleShowDesc(mod)
                          },
                        },
                      },
                      [
                        _c(
                          "el-badge",
                          {
                            staticClass: "module-msg-count",
                            attrs: {
                              hidden: mod.userHomeMsgCount <= 0,
                              value: mod.userHomeMsgCount,
                              max: 99,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "module-icon",
                                class:
                                  _vm.currentModule.name == mod.name
                                    ? "show"
                                    : "",
                                staticStyle: {
                                  "margin-bottom": "60px",
                                  width: "100px",
                                  height: "95px",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.gotoModule(mod)
                                  },
                                },
                              },
                              [
                                _c("SvgIcon", {
                                  staticStyle: {
                                    display: "flex",
                                    "font-size": "36px",
                                  },
                                  attrs: {
                                    iconClass: "userhome-" + _vm.getIcon(mod),
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "module-title",
                                staticStyle: {
                                  "margin-top": "15px",
                                  "margin-bottom": "10px",
                                  "font-size": "24px",
                                  width: "200px",
                                  height: "30px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      height: "75px",
                                      "line-height": "1",
                                      top: "-50px",
                                    },
                                  },
                                  [_vm._v(_vm._s(mod.name))]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
        _vm.currentModule.showDesc
          ? _c(
              "div",
              {
                staticClass: "description",
                staticStyle: { "margin-top": "60px" },
              },
              [
                _vm.currentModule.description
                  ? _c(
                      "div",
                      {
                        staticClass: "description-title",
                        staticStyle: {
                          height: "60px",
                          "line-height": "60px",
                          "font-size": "30px",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.currentModule.name))]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "description-content",
                    staticStyle: { "font-size": "25px" },
                  },
                  [
                    _vm.currentModule.description
                      ? _c("div", {
                          staticClass: "description-content-info",
                          staticStyle: {
                            "margin-top": "20px",
                            "margin-bottom": "50px",
                          },
                          domProps: {
                            innerHTML: _vm._s(_vm.currentModule.description),
                          },
                        })
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "description-content-info" },
                      _vm._l(
                        _vm.currentModule.descriptionDetails,
                        function (content, index) {
                          return _c(
                            "p",
                            { key: index, staticStyle: { margin: "10px 0" } },
                            [
                              _c("i", {
                                staticClass: "el-icon-s-promotion",
                                staticStyle: { "margin-right": "10px" },
                              }),
                              _vm._v(" " + _vm._s(content) + " "),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }