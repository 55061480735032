import request from '@/utils/request'

// 消息列表
export function ReadNewsList(params: IPage) {
  return request({
    baseURL: process.env.VUE_APP_SYSTEM_API,
    url: '/home-news/ReadNewsList',
    method: 'GET',
    params
  })
}

// 未读消息列表
export function unReadNewsList(params: IPage) {
  return request({
    baseURL: process.env.VUE_APP_SYSTEM_API,
    url: '/home-news/unReadNewsList',
    method: 'GET',
    params
  })
}

// 未读消息数量
export function unReadNewsCount() {
  return request({
    baseURL: process.env.VUE_APP_SYSTEM_API,
    url: '/home-news/unReadNewsCount',
    method: 'GET',
    skipLoading: true
  })
}

// 设置已读状态
export function setReadStatus(data?: Array<INewsId>) {
  if(!data){
    data = []
  }
  return request({
    baseURL: process.env.VUE_APP_SYSTEM_API,
    url: '/home-news/setReadStatus',
    method: 'POST',
    data
  })
}

// 当前邀请的权限类型
export function selectInvideAcl(data: IInvideAcl) {
  return request({
    baseURL: process.env.VUE_APP_DATA_API,
    url: `/workspace/selectInvideAcl`,
    method: 'POST',
    data
  })
}

// 删除消息
export function deleteNews(data: INewsId) {
  return request({
    baseURL: process.env.VUE_APP_SYSTEM_API,
    url: 'home-news/delete',
    method: 'POST',
    data
  })
}


export interface INewsId {
  id: string
}

export interface IPage {
  pageNum?: number
  pageSize?: number
}

export interface IInvideAcl {
  bizId: string
}
