
































































import { validPhone, validEmail } from '@/utils/validate'
import { Component, Ref, Vue } from "vue-property-decorator";
import { Captcha, getCaptach} from "@/api/system";
import { ElForm } from 'element-ui/types/form';
import { userRegister} from "@/api/user/account";
import { ApplicationModule } from '@/store/modules/application'
  
  @Component({
    components: {  },
      name: "register",
    })
  export default class extends Vue {
    @Ref("form")
    form!:ElForm
    ApplicationModule = ApplicationModule
    saveForm:any = {
        name: "", //姓名
        email: "", //邮箱
        mobile: "", //联系电话
        companyName: "", //公司名称
        position: "", //职位
        contactMatters: "", //联络事项
        captchaCode: "",
        captchaKey: ""
    }
    captcha: Captcha = {
        image: "",
        key: ""
    }
    rules = {
        name: [
            { required: true, message: this.$t('home.userAccount.namePlaceholder') }
        ],
        email: [
          { required: true, message: this.$t('home.userAccount.emailPlaceholder') },          
          { validator: this.validRegEmail }
        ],
        // mobile: [
        //   { required: true, message: this.$t('home.userAccount.mobilePlaceholder') },          
        //   { validator: this.validPhone }
        // ],
        companyName: [
          { required: true, message: this.$t('home.userAccount.companyNamePlaceholder') },          
        ],
        // position: [
        //   { required: true, message: this.$t('home.userAccount.positionPlaceholder') },          
        // ],
        // captchaCode: [
        //   { required: true, message: this.$t('home.captchaPlaceholder') },          
        // ],
    }
    mounted() {
        this.loadCaptcha()
    }
    async loadCaptcha() {
        const rs = await getCaptach({ width: 120, height: 40, type: "gif" });
        this.captcha = rs.data;
        this.saveForm.captchaKey = rs.data.key;
    }
    agreement() {
        let routeUrl: any = this.$router.resolve({
            path: '/account/agreement',
        })
        window.open(routeUrl.href, '_blank')
    }
    login() {
        // this.$router.push({
        //     path: '/login'
        // })
        (this.$parent as any).typeName = 'login';
    }
    async validPhone(rule: any, value: any, callback: any) {
        if(validPhone(value)) {
            callback();
        } else {
            callback(new Error(this.$t('home.userAccount.invalidMobile').toString()));
        }
    }
    async validRegEmail(rule: any, value: any, callback: any) {
      if(validEmail(value)) {
        callback();
      } else {
        callback(new Error(this.$t('home.userAccount.invalidEmail').toString()));
      }
    }
    handleRegister() {
        this.form.validate((valid:boolean) => {
            if(valid) {
                userRegister(this.saveForm)
                    .then(() => {
                        this.$message.success(this.$t('app.submitSuccessfully').toString());
                    })
            }
        })
    }
  }
  