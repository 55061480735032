export default {
  app: {
    content: {
      '0': ['安','永','智','慧','風','控','平','臺'],
      '1': ['推','動','大','數','據','風','控','新','標','的'],
      '2': ['讓','風','險','無','處','遁','形']
    },
    title: 'Ai創造引擎',
    pleaseSelect: '請選擇',
    pleaseInput: '請輸入',
    sessionTimeout: '會話已過期，請重新登入',
    networkError: '網絡錯誤',
    hello: '您好!',
    welcome: '歡迎訪問',
    help: '幫助',
    message: '消息',
    setting: '設定',
    logout: '登出',
    user: '個人',
    optionInput: '選填',
    mustInput: '必填',
    avatarUpload: '上傳頭像，檔不超過2M，png，jpeg或jpg格式',
    langSelect: '語言選擇',
    zhCN: '中文簡體',
    zhHans: '中文繁體',
    en: '英文',
    defaultLang: '默認中文簡體,下拉進行切換',
    theme: '主題顏色',
    themeDark: '暗色-綠/灰',
    themeDark2: '暗色-黃/黑',
    themeLight: '亮色-黃/白',
    themeHuayou: '華友-藍/白',
    defaultTheme: '默認暗色-綠/灰，下拉進行切換',
    save: '保存',
    cancel: '取消',
    close: '關閉',
    submit: '提交',
    confirm: '確認',
    upload: '上傳',
    delSuccess: '刪除成功',
    uploadFail: '上傳失敗',
    importSuccess: '導入成功',
    confirmLogout: '確認要退出登錄嗎？',
    remark: '備註',
    actionSuccess: '操作成功',
    all: '全部',
    enable: '啟用',
    disable: '禁用',
    action: '操作',
    createTime: '創建時間',
    updateTime: '修改時間',
    unknownProcess: '未知操作',
    module: '所屬模組',
    add: '新增',
    name: '姓名',
    userName: '用戶名',
    password: '密碼',
    totla: '總共',
    online: '線上',
    outline: '線下',
    selected: '錄製中',
    unSelected: '待上傳',
    underReview: '智慧飛檢中',
    outReportsReview: '人工覆核中',
    outReports: '已出報告',
    outReportsReviewIng: '待人工覆核',
    savedSuccessfully: "保存成功",
    submitSuccessfully: "提交成功",
    reject: '駁回',
    approve: '準予',

    search: '搜索',
    flow: '流轉',
    runSuccess: '執行成功',
    finish: '完成',
    send: '發送',
    collapse: '折疊',
    expand: '展開',
    selectAll: '全選',
    preItem: '上一個',
    nextItem: '下一個',
    yes: '是',
    no: '否',
    training: '員工培訓',
    trainingMaterials: '培訓材料',
    trainingConfirmMsg: '我已完成培訓，了解本系統的使用方法及相關的安全操作規範',
    submitting: '正在提交',
    create: '新建',
    none: '無',
    canPreview: '該檔案無法預覽，請下載後查看',
    showMenu: '顯示選單',
    hideMenu: '隱藏選單',
    custom: '定制',
  },
  menu: {
    home: '首頁',
    wb: '智慧工坊',
    lastModified: '最近修改',
    integrate: '數據集市',
    dataAccess: '數據存取',
    process: '數據處理',
    model: '規則引擎',
    ruleModel: '規則模型',
    ai: '智慧引擎',
    explore: '數據探索',
    schedule: '任務調度',
    members: '團隊成員',
    workPlan: '團隊公告',
    noticePlaceholder: '請輸入內容或表情，點擊發佈組內公告',
    workbenchSetting: '專案設置',
    roleManage: '角色管理',
    aiModel: 'AI模型',
    scheduleReport: '風險預警',
  },
  message: {
    markAllRead: '全部標記為已讀',
    packUp: '收起',
    expand: '展開',
    noMore: '沒有更多了',
    loadMore: '載入更多',
    teamInvitation: '團隊邀請',
    confirmInvitation: '邀請確認',
    confirmRead: '確定全部標記為已讀嗎？',
    confirmDelMsg: '確定要刪除該未讀消息嗎？',
    memberInvitation: '團隊成員邀請',
    memberManage: '團隊成員管理',
    specialManage: '專案管理',
    dataAuth: '數據權限授權',
    editorialInterpretation: '編輯解讀',
    internalRegulationOptimization: '內規優化',
    articleUpload: '文章上傳',
    articleRevision: '文章修正',
    crawlerMonitoring: '爬蟲監控',
    myMessage: '我的消息',
    noRead: '未读'
  },
  system: {
    loading: "載入中",
  },
  common: {
    uploadFile: '將文件拖到此處，或<em>點擊上傳</em>',
    text_copied_to_clipboard: '文字已經複製到剪切板',
    approve: '審批',
    confirm: '確定',
    back: '返回',
    tips: '提示',
    rows: '行',
    columns: '列',
    data: '數據',
    previewTopRows: '僅預覽前10行',
    previewTopRows1: '僅預覽前1000行',
    previewTopRows5: '僅預覽前5行',
    judgingFieldName: '判斷欄位名稱',
    placeHolder: '請為新增判斷欄位命名',
    labelTextRequire1: '必填-支援中文字、數字、英文大小寫及“_”，最多不超過50字元',
    rules: '篩選規則',
    assign: '賦值',
    edit: '編輯',
    download: '下載',
    export: '匯出',
    import: '批量導入',
    downloadTemplate: '下載批量導入模板',
    query: '查詢',
    resultsDisplay: '結果展示',
    version: '版本',
    resultQuery: '結果查詢',
    

    unnamedDataProc: '未命名數據處理',
    unnamedRuleEngine: '未命名規則引擎',
    redo: '重做',
    recovery: '恢復',
    enlarge: '放大',
    narrow: '縮小',
    needPrimaryKey: '請選擇至少一個主鍵',
    execute: '執行',
    auth: '授權',
    deleteRow: '刪除',
    runningLog: '執行日誌',
    view: '查看',
    editDisabled: '編輯（禁用）',
    deleteRowDisabled: '刪除（禁用）',
    viewSpecial: '預覽',
    explore: '資料探索',
    authSpecial: '申請權限',
    stop: '停止',
    deleteThisRow: '删除此行？',
    executeTask: '確認要執行任務嗎？',
    executing: '正在執行',
    executeSuccess: '執行成功',
    executeFail: '執行成功',
    stopTask: '是否確認立即停止運行？',
    stopping: '正在停止',
    stopSuccess: '停止成功',
    stopFail: '停止失敗',
    exportData: '確認要下載數據嗎?',
    exploreZoreData: '0行數據，不能探索',
    deleteTask: '確認要刪除嗎？',
    deleting: '正在刪除',
    deleteConfirm: '确认要删除吗？',
    select: '請選擇',
    pleaseEnter: '請輸入',
  },
  // workbench 工作台模块
  wb: {
    mySpecial: '我的專案',
    newSpecial: '新建智慧工作台',
    newRemark: '為了大家更瞭解工作臺，寫一些備註吧！',
    reportPlatform: '報表平臺',
    currWorkbenchName: '專案名稱',
    currWorkbenchRemark: '大家一起合作完成這個新建智慧工作台吧！加油！',
    workbenchName: '專案名稱',
    nameValidator: '一旦填寫不可變更；最多10個字元，不支援數位開頭，可使用"_"',
    canNotChange: '一旦填寫不可變更',
    remark: '可為專案備註或發佈公告，顯示在專案首頁',
    addEditremark: '有什麼想對團隊成員說的',
    remarkEdit: '可為專案備註或發佈公告，專案所有者與合作者皆可編輯',
    remove: '移除專案',
    inputNamePlease: '請填寫專案名稱',
    nameFormat: '支援中文、大小寫英文及“_”',
    confirmRemove: '一旦移除專案，相關表授權及調度都將禁止。是否確定移除？',
    removeSuccess: '移除專案成功',
    confirmLeave: '還未保存本次修改，離開後將無法恢復，是否確定？',
    roleType: '權限類型',
    agree: '同意',
    reject: '拒絕',
    collaborators: '合作者',
    viewer: '查看者',
    agreeInvitation: '確認同意邀請',
    rejectInvitation: '確認拒絕邀請',
    owner: '所有者',
    leave: '離開團隊',
    removeMember: '移出團隊',
    transferOwnership: '移交所有權',
    invitationAgain: '重發邀請',
    members: '團隊成員',
    invitationNew: '邀請新成員',
    searchText: '請輸入用戶名/員工編號/角色/部門進行搜索',
    sendInvitation: '發送邀請',
    selectMember: '請選擇專案成員',
    isExist: '已存在，已自動刪除重複資料',
    hasBeenSend: '專案成員邀請已發送',
    changeRole: '變更成員權限',
    leaveMsg: '是否確定離開團隊？離開團隊後，你在團隊中所創建的檔案將自動轉交給團隊所有者',
    rejected: '已拒絕',
    toBeConfirmed: '待確認',
    me: '我',
    status: '專案狀態',
    createTimeSort: '創建時間',
    updateTimeSort: '最近修改時間',
    searchWbText: '請輸入專案名稱、所有者進行模糊搜索',
    replaceOwner: '替換所有者',
    jumpToItem: '跳轉到專案',
    confirmReplace: '確認替換',
    dataAuthorizeCount: '資料權限數量',
    disableMsg: '一旦禁用專案，相關表授權及調度都將禁止。是否確定禁用？',
    enableMsg: '一旦啟用專案，相關表授權及調度都將啟用。是否確定啟用？',
    selectPerson: '請選擇人員',
    normalFieldRule: '最多24個字元，不支援數位開頭，可使用"_"',
    dbTableNameRule: '最多24個字元，不支持數位開頭，只可使用英文字元、數位、“$”和“_”',
    addFirstWork: '點擊新建，創建第一個任務',
    noPermission: '無權限訪問',
    newVersion: '新版本'
  },
  dictValue: {
  }
};
