export default {
    home: {
        title: '风险知识库',
        captcha: '验证码',
        captchaPlaceholder: '请输入验证码',
        login: {
            welcome: '欢迎登录',
            register: '尚未有账号？立即注册',
            byPassword: '账号密码登录',
            bySSO: 'SSO登录',
            account: '账号',
            accountPlaceholder: '请输入账号',
            password: '密码',
            passwordPlaceholder: '请输入密码',
            rememberMe: '记住我',
            forgetPassword: '忘记密码？',
            login: '登录',
        },
        userAccount: {
            registerWelcome: '欢迎注册',
            name: '姓名',
            namePlaceholder: '请输入姓名',
            email: '邮箱',
            emailPlaceholder: '请输入邮箱',
            mobile: '电话号码',
            mobilePlaceholder: '请输入电话号码',
            companyName: '公司名称',
            companyNamePlaceholder: '请输入公司名称',
            position: '职位',
            positionPlaceholder: '请输入职位',
            contactMatters: '联络事项',
            contactMattersPlaceholder: '请输入联络事项',
            submitAsAgree: '提交即视为同意',
            agreement: '《Targus隐私协议》',
            hasAccount: '已有账号，立即登录',
            invalidMobile: '请输入正确的电话号',
            invalidEmail: '请输入正确的邮箱地址',
            forgetWelcome: '填写信息找回密码',
        },
        home: {
            hello1: '凌晨好，',
            hello2: '早上好，',
            hello3: '上午好，',
            hello4: '中午好，',
            hello5: '下午好，',
            hello6: '晚上好，',
        },
        desc: {
            report: {
                description: '基于企业的组织架构和管控模式，形成全方位风险视图，准确度量企业整体风险水平，为领导决策和日常管理提供前瞻性支持。',
                descriptionDetails1: '灵活的报表设计，基于需求定制化风险视图',
                descriptionDetails2: '直观的数据展示，利用可视化工具直观展示风险状况',
                descriptionDetails3: '动态交互式体验，数据逐级钻取快速找到关键信息',
            },
            risk: {
                description: '全生命周期风险管理工具，帮助企业及时掌握风险动态，迅速响应风险事件，通过节点流程的流转，精细化管理和管控风险。',
                descriptionDetails1: '事前风险评估，以规范的制度与流程约束各项行为，将风险管理工作前置',
                descriptionDetails2: '事中风险监控，与业务系统深度集成，实现各类风险的全面监控',
                descriptionDetails3: '事后风险处置，灵活的工作流，保障企业面临风险事件时',
            },
            compliance: {
                description: 'AI能力与流程管理有机结合，加速合规管理效率，建设“感知-解析-应对-管控”全方位智能化合规管理体系。',
                descriptionDetails1: '整合企业内外合规数据库，支持合规知识更新与共享',
                descriptionDetails2: '利用AI技术智能识别和解读合规信息，提供个性化合规建议',
                descriptionDetails3: '搭载流程引擎，实施合规问询、事件处置、监管响应等合规管控手段',
            },            
            control: {
                description: '',
                descriptionDetails1: '',
                descriptionDetails2: '',
                descriptionDetails3: '',
            },           
            audit: {
                description: '大数据技术融入审计，实现数字化审前分析、规范化审计程序、自动化编制审计报告等措施，帮助高质量审计业务的实施。',
                descriptionDetails1: '数字化审计手段，从海量数据中挖掘潜在风险',
                descriptionDetails2: '审计作业支持，规范化管理审计流程和审计成果',
                descriptionDetails3: '审计资源共享，全面提升审计工作质量',
            },          
            supervise: {
                description: '信息技术与纪检监察业务深度融合，为纪检监察工作高质量发展提供科技动能，有效提升监督的精准性和有效性。',
                descriptionDetails1: '监察项目全流程管理，线上线下联动，拓宽监察幅度',
                descriptionDetails2: '整合数据资源，深入分析大数据背景下的纪检监察案例',
            },               
            workbench: {
                description: '以工作台形式支持团队协作，提供数据集市、数据处理、规则引擎、智能引擎、数据探索、任务调度功能，助力风险洞察。',
                descriptionDetails1: '打破“数据壁垒”，极速处理海量数据',
                descriptionDetails2: '端到端无代码，支持拖拽式交互',
                descriptionDetails3: '数据安全有保障，行列级数据权限配置',
            },               
            ai: {
                description: '内置多重AI技术与工具，汇集行业领先的预训练模型，提供模型探索、引用、训练、部署和应用的一站式服务。',
                descriptionDetails1: '自然语言处理，辅助文本生成与分类',
                descriptionDetails2: '机器视觉，识别对象中的信息或文字',
                descriptionDetails3: '大语言模型辅助语音识别和生成',
            },               
            'risk-screening': {
                description: '企业业务的防火墙，通过动态的风险监测，及时跟踪可疑活动，采取预控对策实现业务全时守护。',
                descriptionDetails1: '智能化风险拦截措施，拦截规则灵活配置',
                descriptionDetails2: '定制化集成消息接口，及时发出预警信息',
                descriptionDetails3: '流式计算引擎，支持1秒内快速响应',
            },               
            system: {
                description: '实现全面管理与监控系统运行状态，数据权限和功能权限灵活配置，提升组织效率和竞争力。',
                descriptionDetails1: '功能权限管理与控制，保护系统机密信息',
                descriptionDetails2: '管理维护数据资源，确保数据的可靠性、一致性和完整性',
                descriptionDetails3: '实时监控与诊断，维护系统高可用性和稳定性',
            },               
            conformance: {
                description: '',
                descriptionDetails1: '',
                descriptionDetails2: '',
                descriptionDetails3: '',
            },                   
            task: {
                description: '',
                descriptionDetails1: '',
                descriptionDetails2: '',
                descriptionDetails3: '',
            },    
        }
    }
}