// import {DataTable, GraphNode} from "@/modules/data/process/data-process-types";
// import { ExploreSearchForm } from "./dataTableApi";
import request from "@/utils/request";
import { Page, Result } from "../system";

export interface TempObject {
    [key: string]: any
}

export function getUploadUrl(fileName: string): Promise<Result<any>>{
    return request({
        baseURL: process.env.VUE_APP_SYSTEM_API,
        url: '/sys-file/get-upload-url?fileName='+ fileName +'&bizType=ai-import-image',
        method: 'GET'
    })
}
// 多类型
export function getUploadMulUrl(fileName: string, urlType: string): Promise<Result<any>>{
    return request({
        baseURL: process.env.VUE_APP_SYSTEM_API,
        url: '/sys-file/get-upload-url?fileName='+ fileName +'&bizType=' + urlType,
        method: 'GET'
    })
}

export function getPicPath(table: string,pictureField:string): Promise<Result<any>>{
    return request({
        url: '/data/engine/table/findUrl',
        method: 'POST',
        data: {
            "sourceTable": table,
            "urlField": pictureField
        }
    })
}

export function getUrlByPath(data: any): Promise<Result<any>>{
    console.log('getUrlByPath')
    console.log(data)
    return request({
        baseURL: process.env.VUE_APP_SYSTEM_API,
        url: '/sys-file/get-download-url',
        method: 'POST',
        data
    })
}
// export function getResultPreview(table: string): Promise<Result<Array<DetectItem>>>{
//     return request({
//         url: '/data/engine/detect-query',
//         method: 'POST',
//         data: { table }
//     })
// }
//
export function executeOcrAndPic(bizId: string): Promise<Result<any>>{
    return request({
        url: '/data/data-table/execute?bizId=' + bizId,
        method: 'GET'
    })
}
export function executeFit(bizId: string): Promise<Result<any>>{
    return request({
        url: '/data/data-table/execute?bizId=' + bizId + '&automlType=fit',
        method: 'GET'
    })
}
export function executePredic(bizId: string): Promise<Result<any>>{
    return request({
        url: '/data/data-table/execute?bizId=' + bizId + '&automlType=predict',
        method: 'GET'
    })
}
export function executeEffect(data: any): Promise<Result<any>>{
    return request({
        url: '/data/engine/table/modelEvaluate',
        method: 'POST',
        data
    })
}
export  function parseProcessGraph(data: any): Promise<Result<any>>{
    return request({
        url: '/data/engine/parse-process-graph',
        method: 'POST',
        skipLoading: true,
        data
    })
}

export interface DataAiModelPage extends Page<DataAiModelPage> {
    workspaceBizId?: string;
    modelName?:string
    categoryLabel?:string
    sourceType?:string
    keyword?:string
}
export function modelSearchPage(page: DataAiModelPage):Promise<Result<DataAiModelPage>> {
    return request({
        url: '/data/ai-model/searchPage',
        method: 'POST',
        data: page
    });
}
// export function saveAiModel(model:AiModel):Promise<Result<AiModel>>{
//     return request({
//         url: '/data/ai-model/saveModel',
//         method: 'POST',
//         data: model
//     })
//     //
// }

export function removeAiModel(id?: string):Promise<Result<boolean>>{
    return request({
        url: '/data/ai-model/remove-by-id',
        params: {id}
    })
}
