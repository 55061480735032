import  { PluginObject, VueConstructor } from 'vue';
import { getDicts as getDicts } from '@/api/sys/dict/data'
// @ts-ignore
import DataDict from '@/utils/dict'
const plugin: PluginObject<VueConstructor> = {
    install: (Vue:VueConstructor):void => {
        Vue.use(DataDict,{
            metas: {
              '*': {
                labelField: 'dictLabel',
                valueField: 'dictValue',
                request(dictMeta:any) {
                  return getDicts(dictMeta.type).then(res => res.data)
                },
              },
            },
          })
    }
}

export default  plugin
