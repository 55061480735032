





















































import { UserModule } from "@/store/modules/user";
import { ApplicationModule } from "@/store/modules/application";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Menu as MenuType } from "@/api/sys/menu";
import i18n from "@/pages/home/lang";
import { cloneDeep, orderBy } from "lodash";
import { Menu, MenuItem, Submenu } from "element-ui";
import { WorkspaceModule } from "@/store/modules/workbench";
import { getApplicationInstance } from '@/pages/application';
import * as appActions from '@/app-action'
import { getI18nValue } from "@/utils/utils";

@Component({
  name: 'TopMenu'
})
export default class TopMenu extends Vue {
  // activeIndex = "";
  env = process.env.NODE_ENV;  
  selectedSubMenuId = '';
  popoverVisibles = {
  };
  mainMenuActive = {
  }
  myWorkspaceMenu = {children:[]};
  currentPath = ''

  subMenuColCount = 10;
  // 临时处理方案 屏蔽掉风险知识库
  isyouzheng = JSON.parse(process.env.VUE_APP_APPS_CONFIG).map((item:any) => item.app).indexOf('conformance') > -1;

  hasMoreSubCol(menu:any) {
    return this.getMenuChildren(menu).length > this.subMenuColCount;
  }

  getMenuChildrenInCol(menu:any, col:any) {
    let children = this.getMenuChildren(menu);

    let colCount = this.subMenuColCount;
    if(colCount*2 <= children.length) {
      colCount = Math.round(children.length/2);
    }

    let start = 0;
    let end = colCount;
    if(col == 1) {
      start = colCount;
      end = children.length;
    }

    return children.slice(start,end);
  }

  getMenuDesc(menu:any) {
    return JSON.parse(menu.meta || '{}').menuDesc;
  }

  isMenuCustom(menu:any) {
    return JSON.parse(menu.meta || '{}').menuCustom;
  }

  hasSubMenuCustom(menu:any) {
    return this.getMenuChildren(menu).some((s:any) => this.isMenuCustom(s))
  }

  getMenuChildren(menu:any) {
    return (menu.children || []).filter((item:any)=>item.visible == '0' );
  }

  selectSubMenu(menuId: any, mainMenu:any) {
    //@ts-ignore
    this.popoverVisibles[mainMenu.menuId] = false;
    let menu = UserModule.menus.find((m:any) => m.menuId == menuId);
    if(!menu) {
      //@ts-ignore
      menu = this.myWorkspaceMenu.children.find((m:any) => m.menuId == menuId);
    }
    this.selectMenu(menu);
    this.$forceUpdate();
  }

  get currentRouteHashPath() {
    return this.$route.hash.replace('#','');
  }

  get leftMenu() {
    let menu = this.getI18nName(cloneDeep(UserModule.leftMenu))
    let liuxiangMenus = UserModule.liuxiangMenus;
    const keys:any = {'flowProject': '/flow/auditProject/','workbench': '/data/workbench/'};
    for (let index = 0; index < menu.length; index++) {
      const element = menu[index];
      if(element.menuId === "workbench") {
        element.children = element.children && (element.children.filter((e:any) => {return e.visible == '0'}) ||[]);

        let myWorkbench = element.children.find((e:any) => JSON.parse(e.meta || '{}').topType == 'MyWorkbench');
        
        if(myWorkbench) {
          myWorkbench.children = ([...this.workbenchMenus.map((workspace: any) => {
            return {
              menuId: 'Workbench_my_workshop_'+workspace.id,
              parentId: myWorkbench.menuId,
              name: workspace.name,
              url: `/workbench/${workspace.id}/index`,
              visible: '0',
              // icon: myWorkbench.icon,
            }
          })])
          this.myWorkspaceMenu = myWorkbench;

          break;
        }

        break;
      }

      if(keys[element.nameEn]){
        element.children = [];
        liuxiangMenus.forEach((item:any) => {
          element.children.push({
            name: item.clientName,
            url: `${keys[element.nameEn]}${item.clientCode}/index`,
            visible: '0'
          })
        });
      }
    }
    let menus = ((menu[0] && menu[0].children) || []);
    menus.forEach((m:any) => {
      this.$set(this.popoverVisibles,m.menuId,false)
      //@ts-ignore
      if(!this.mainMenuActive[m.menuId]) {
        this.$set(this.mainMenuActive,m.menuId,false)
      }
    })
    return menus.filter((item:any)=>{
      // 如果当前非邮政模块 屏蔽掉风险知识库
      if(!this.isyouzheng && item.name == this.$t('home.title')){
        item.visible = '1'
      }
      return item.visible == '0'
    });
  }

  getI18nName(menus: any[]) {
    return menus.map((menu) => {
      if (menu.children != null && menu.children.length != 0) {
        menu.children = this.getI18nName(menu.children)
      }
      menu.name = getI18nValue(menu,'name')
      return menu
    })
  }
  mounted() {
    // this.$store.dispatch('getWorkbenchMenus')
    if(getApplicationInstance().appId == 'workbench' || (this.$route.meta || {}).appname  == 'workbench') {
      UserModule.getWorkbenchMenus()
    }
  }

  @Watch('$store.state.application.menuPath',{deep:true})
  handleCurrentMenuPathChange() {
    if(ApplicationModule.currentMenuPath) {
      this.updateMenu(ApplicationModule.currentMenuPath)
      this.$nextTick(() => {                
          this.$store.dispatch('setMenuPath', '')
      })
      //@ts-ignore
      this.$parent.scrollTop();
    }
  }

  @Watch('currentRouteHashPath')
  handleCurrentRouteHashPathChange() {
    this.updateMenu(this.currentRouteHashPath)
    //@ts-ignore
    this.$parent.scrollTop();
  }
  

  updateMenu(path:any) {
    console.log('updateMenu',path)
    if(path.lastIndexOf('?') >= 0) {
      path = path.substr(0,path.lastIndexOf('?'));
    }
    this.currentPath = path;
    // let path = this.currentMenuPath || location.hash.replace('#','');
    let menu = null;
    if((this.$route.meta || {}).appname  == 'workbench' && /^\/workbench\/(.+?)\/(.+)/.test(path)) {
      menu = this.myWorkspaceMenu.children.find((m:any) => m.url == path);
    }
    else {
      menu = UserModule.menus.find(m => m.url == path);  
    }
    if(menu) {
      document.title = `${getI18nValue(menu,'name')} - ${process.env.VUE_APP_NAME||'app'}`
      this.selectedSubMenuId = menu.menuId;
      let subMenu = menu;
      let parentMenu = UserModule.menus.find(m => m.menuId == subMenu.parentId);

      while(parentMenu && parentMenu.parentId != '0') {
        subMenu = parentMenu;
        parentMenu = UserModule.menus.find(m => m.menuId == subMenu.parentId);
      }

      if(parentMenu && parentMenu.parentId == '0') { 
        //@ts-ignore
        for(let menuId in this.mainMenuActive) {
          //@ts-ignore
          this.mainMenuActive[menuId] = false;
        }
        //@ts-ignore       
        this.mainMenuActive[subMenu.menuId] = true;
      }
    }
  } 
  get collapsed() {
    return ApplicationModule.collapse || false
  }
  get workbenchMenus() {
    return UserModule.workbenchMenus
  }
  get activeIndex() {
    // 工作台子页面，高亮对应左侧专项菜单
    if (/^\/workbench\/(.+?)\/(.+)/.test(this.$route.path)) {
      const arr = this.$route.path.split('/')
      return `/workbench/${arr[2]}/index`
    }
    return this.$route.path
  }
  selectMenu(menu: any) {
    if(menu.menuId != this.selectedSubMenuId) {
      this.selectedSubMenuId = ''
    }

    if(this.$route.path == menu.url || menu.url.indexOf('__menu_Id__') >= 0) {
      return;
    }
    if(menu.isFrame === '0'){
      this.$router.push({
        name: 'external-link',
        params: {url:menu.url}
      })
    }
    else {
      appActions.routerPush(menu.url);
    }
  }

}
