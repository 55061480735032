import request from '@/utils/request'

export function updateUserConfig(data: Array<ISetting>) {
  return request({
    baseURL: process.env.VUE_APP_SYSTEM_API,
    url: '/user/config/updateUserConfig',
    method: 'POST',
    data
  })
}

export function getUserConfig() {
  return request({
    baseURL: process.env.VUE_APP_SYSTEM_API,
    url: '/user/config/getUserConfig',
    method: 'GET'
  })
}

export function getUserMenus() {
  return request({
    baseURL: process.env.VUE_APP_SYSTEM_API,
    url: '/user/config/menus',
    method: 'GET'
  })
}

export function upload(data: File) {
  return request({
    baseURL: process.env.VUE_APP_SYSTEM_API,
    url: '/user/config/upload',
    method: 'POST',
    data
  })
}



export interface ISetting {
  bizType?: string
  data?: any
}