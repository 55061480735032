export default {
    home: {
        title: 'Risk knowledge library',
        captcha: 'Verification Code',
        captchaPlaceholder: 'Please enter a verification code',
        login: {
            welcome: 'Welcome to login',
            register: 'Don\'t have an account yet ? Register Now',
            byPassword: 'Account password login',
            bySSO: 'SSO login',
            account: 'Account',
            accountPlaceholder: 'Please enter a user name',
            password: 'Password',
            passwordPlaceholder: 'Please enter a password',
            rememberMe: 'Remember me',
            forgetPassword: 'Forgot the password?',
            login: 'Login',
        },
        userAccount: {
            registerWelcome: 'Welcome to register',
            name: 'Name',
            namePlaceholder: 'Please enter a name',
            email: 'Mailbox',
            emailPlaceholder: 'Please enter an email address',
            mobile: 'Phone number',
            mobilePlaceholder: 'Please enter a phone number',
            companyName: 'Company name',
            companyNamePlaceholder: 'Please enter a company name',
            position: 'Position',
            positionPlaceholder: 'Please enter a position',
            contactMatters: 'Contact matters',
            contactMattersPlaceholder: 'Please enter contact details',
            submitAsAgree: 'Submitting is deemed to agree',
            agreement: '《Targus Privacy Agreement》',
            hasAccount: 'You already have an account, log in now',
            invalidMobile: 'Please enter the correct phone number',
            invalidEmail: 'Please enter the correct email address',
            forgetWelcome: 'Fill in the information to retrieve the password',
        },
        home: {
            hello1: 'Good morning,',
            hello2: 'Good morning,',
            hello3: 'Good morning,',
            hello4: 'Good afternoon,',
            hello5: 'Good afternoon,',
            hello6: 'Good evening,',
        },
        desc: {
            report: {
                description: 'Based on the enterprise\'s organizational structure and control mode, a comprehensive risk view is formed to accurately measure the overall risk level of the enterprise, providing forward-looking support for leadership decision-making and daily management.',
                descriptionDetails1: 'Flexible report design, customized risk view based on demand',
                descriptionDetails2: 'Intuitive data display, using visualization tools to intuitively display the risk situation',
                descriptionDetails3: 'Dynamic interactive experience, data drilling level by level to quickly find key information',
            },
            risk: {
                description: 'Full life cycle risk management tool helps enterprises to timely grasp the dynamic of risks, respond quickly to risk events, and finely manage and control risks through the flow of node processes.',
                descriptionDetails1: 'Pre-risk assessment, pre-positioning risk management work with standardized systems and processes',
                descriptionDetails2: 'In-process risk monitoring, deep integration with business systems to achieve comprehensive monitoring of various risks',
                descriptionDetails3: 'Post-risk disposal, flexible workflow to ensure timely disposal and response to risk events faced by enterprises',
            },
            compliance: {
                description: 'The combination of AI capabilities and process management accelerates the efficiency of compliance management and builds a "perception-analysis-response-control" all-round intelligent compliance management system.',
                descriptionDetails1: 'Integrate internal and external compliance databases to support compliance knowledge updates and sharing',
                descriptionDetails2: 'Use AI technology to intelligently identify and interpret compliance information and provide personalized compliance advice',
                descriptionDetails3: 'Equipped with process engine to implement compliance control measures such as compliance inquiry, event disposal, and regulatory response',
            },            
            control: {
                description: '',
                descriptionDetails1: '',
                descriptionDetails2: '',
                descriptionDetails3: '',
            },           
            audit: {
                description: 'Big data technology is integrated into auditing, implementing measures such as digital pre-audit analysis, standardized audit procedures, and automated preparation of audit reports to help implement high-quality audit business.',
                descriptionDetails1: 'Digital audit methods to mine potential risks from massive data',
                descriptionDetails2: 'Support for audit operations, standardized management of audit processes and results',
                descriptionDetails3: 'Sharing of audit resources to comprehensively improve the quality of audit work',
            },          
            supervise: {
                description: 'The deep integration of information technology and discipline inspection and supervision business provides technological momentum for the high-quality development of discipline inspection and supervision work, effectively improving the accuracy and effectiveness of supervision.',
                descriptionDetails1: 'Full-process management of supervision projects, online and offline linkage, expanding the scope of supervision',
                descriptionDetails2: 'Integrate data resources and conduct in-depth analysis of discipline inspection and supervision cases under the background of big data',
            },               
            workbench: {
                description: 'Providing a workbench to support team collaboration, offering functions such as data marketplace, data processing, rule engine, intelligent engine, data exploration, and task scheduling to assist in risk insight.',
                descriptionDetails1: 'Breaking the "data barrier" and processing massive data at high speed.',
                descriptionDetails2: 'End-to-end no-code, supporting drag-and-drop interaction.',
                descriptionDetails3: 'Data security is guaranteed, with row and column-level data permission configuration.',
            },               
            ai: {
                description: 'Built-in multiple AI technologies and tools, gathering industry-leading pre-trained models, providing one-stop services for model exploration, reference, training, deployment, and application.',
                descriptionDetails1: 'Natural language processing, assisting in text generation and classification.',
                descriptionDetails2: 'Machine vision, recognizing information or text in objects.',
                descriptionDetails3: 'Large language model assists in speech recognition and generation.',
            },               
            'risk-screening': {
                description: 'The firewall of enterprise business, through dynamic risk monitoring, timely tracking suspicious activities, and taking pre-control measures to achieve business all-time guarding.',
                descriptionDetails1: 'Intelligent risk interception measures, flexible configuration of interception rules.',
                descriptionDetails2: 'Customized integration of message interfaces, timely issuance of warning information.',
                descriptionDetails3: 'Stream computing engine, supporting rapid response within 1 second.',
            },               
            system: {
                description: 'Realize comprehensive management and monitoring of system operation status, flexible configuration of data and functional permissions, and improve organizational efficiency and competitiveness.',
                descriptionDetails1: 'Function permission management and control, protecting system confidential information',
                descriptionDetails2: 'Manage and maintain data resources to ensure data reliability, consistency, and integrity',
                descriptionDetails3: 'Real time monitoring and diagnosis, maintaining system high availability and stability',
            },               
            conformance: {
                description: '',
                descriptionDetails1: '',
                descriptionDetails2: '',
                descriptionDetails3: '',
            },                   
            task: {
                description: '',
                descriptionDetails1: '',
                descriptionDetails2: '',
                descriptionDetails3: '',
            },    
        }
    }
}