






import { Component, Vue } from "vue-property-decorator";
import layoutStyle3 from "@/views/layout/layoutStyle3.vue";

@Component({
  name: "layout-3",
  components: {
    layoutStyle3
  },
})
export default class extends Vue {
}
