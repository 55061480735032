import store from "@/store";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { selectById,aclList } from "@/api/workbench";
import { getFullUrl } from "@/utils/utils";
import { UserModule } from "./user";
import { WorksapceAcl } from "@/types/workspace";
import { listData } from "@/api/sys/dict/data";
export interface IWorkbenchStore {
    workspace: {[key:string]:any},
}
export interface Theme {
    code: string
    label: string
    status: string
}
@Module({dynamic: true, store, name: 'workbench'})
class WorkbenchStore extends VuexModule implements IWorkbenchStore {
    public workspace: any = null;
    public themeList: Array<Theme> = []
    @Action
    async setCurrentWorkbench(id:string) {
        if(id && this.workspace && this.workspace.id == id){
            return
        }
        if(id){
            const { code, data } = await selectById({id: id});
            if (code === "000000") {
                console.log('data:------------------------', data)
                if (data.photoPath) {
                    console.log('存在头像:-------------------------')
                    data.avatarImg = await getFullUrl(data.photoPath)
                }
                const res = await aclList({bizId: data.bizId})
                data.aclList = res.data ||[]
                this.SET_CURRENT_WORKBENCH(data);
            }
        }else{
            this.SET_CURRENT_WORKBENCH(null);
        }
    }
    @Action
    async loadThemeList(){
      const {rows} =  await listData({dictType: 'theme-list'})
      const themeList = (rows||[]).map((dict:any)=>({
        code: dict.dictValue,
        label: dict.dictLabel,
        status: dict.status
      }))
      this.SET_THEME_LIST(themeList)
    }
    get scheduleApprovalInProgress():boolean{
        return this.workspace ? this.workspace.scheduleApprovalState == 'in_progress' : false
    }
    get workspaceId():string{
        return this.workspace ? this.workspace.id : null
    }
    get workspaceBizId():string {
        return this.workspace ? this.workspace.bizId: null
    }
    get aclMap():{[p:string]:WorksapceAcl}{
        return ((this.workspace||{}).aclList||[]).reduce((rs:{[p:string]:any},acl:any)=>{
            rs[acl.userId+''] = acl;
            return rs;
        },{})
    }
    get isWorkbenchMember(){
        const userId = UserModule.userId; 
        const acl = this.aclMap[userId];
        
        return (acl||{}).status === '0' ||   (UserModule.roles||[]).reduce((rs,role)=>{
                                                        if(role.roleCode=='Administrator'){
                                                            rs = true
                                                        }
                                                        return rs;
                                                },false);
    }
    get roleType() {
        let roleType = '';

        const userId = UserModule.userId; 
        const acl = this.aclMap[userId];
        if(acl && acl.status == '0'){
           roleType =  acl.roleType || ''
        }else{
           const isAministrator = (UserModule.roles||[]).reduce((rs,role)=>{
                if(role.roleCode=='Administrator'){
                    rs = true
                }
                return rs;
           },false)
           if(isAministrator){
                roleType = '2';
           }
        }
        return roleType;
    }
    get dispatch(): {[key:string]:boolean}{
        let roleType = '';

        const userId = UserModule.userId; 
        const acl = this.aclMap[userId];
        if(acl && acl.status == '0'){
           roleType =  acl.roleType || ''
        }else{
            const isAministrator = UserModule.hasRole('Administrator');
            console.log('isAministrator',isAministrator)
           if(isAministrator){
                roleType = '2';
           }
        }
        const actions = ['delete','add','download','execute','edit'];
        const rs:{[key:string]:boolean} = {}
        for(const action of actions){
            if(action == 'delete'){
                rs[action] = roleType == '0' || roleType == '1';
            }
            if(action == 'add'){
                rs[action] = roleType == '0' || roleType == '1';
            }
            if(action == 'download'){
                rs[action] = roleType == '0' || roleType == '1';
            }
            if(action == 'execute'){
                rs[action] = roleType == '0' || roleType == '1';
            }
            if(action == 'edit'){
                rs[action] = (roleType == '0' || roleType == '1') && !this.scheduleApprovalInProgress;
            }
            if(!roleType){
                rs[action] = roleType == '0' || roleType == '1';
            }
        }
        return rs;
         
    }
    @Mutation
    SET_CURRENT_WORKBENCH(data: any) {
        this.workspace = data;
    }
    @Mutation
    SET_THEME_LIST(data:Array<Theme>){
        this.themeList = data;
    }
}

export const WorkspaceModule = getModule(WorkbenchStore)