






  import { Component, Vue } from "vue-property-decorator";
import layoutStyle35 from "@/views/layout/layoutStyle35.vue";
  
  @Component({
    name: "layout-35",
    components: {
      layoutStyle35
    },
  })
  export default class extends Vue {
  }
  