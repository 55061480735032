import { Workspace } from '@/types/workspace'
import request, { RequestResponse } from '@/utils/request'
import { Result } from '../system'

// 专项列表
export function getList() {
  return request({
    baseURL: process.env.VUE_APP_DATA_API,
    url: '/workspace/list',
    method: 'GET'
  })
}

// 专项详情
export function selectById(data: IAddEdit):RequestResponse<Result<Workspace>> {
  return request({
    baseURL: process.env.VUE_APP_DATA_API,
    url: '/workspace/selectById',
    method: 'POST',
    data
  })
}

// 增、改
export function saveOrUpdate(data: IAddEdit) {
  return request({
    baseURL: process.env.VUE_APP_DATA_API,
    url: '/workspace/saveOrUpdate',
    method: 'POST',
    data
  })
}

// 启用、禁用
export function disableAndEnable(data: IStatus) {
  return request({
    baseURL: process.env.VUE_APP_DATA_API,
    url: `/workspace/${data.bizId}/disableAndEnable`,
    method: 'POST',
    data
  })
}

// 移除专项
export function workspaceRemove(data: IAddEdit) {
  return request({
    baseURL: process.env.VUE_APP_DATA_API,
    url: `/workspace/${data.bizId}/workspaceRemove`,
    method: 'POST',
    data
  })
}

export function upload(data: File) {
  return request({
    baseURL: process.env.VUE_APP_DATA_API,
    url: '/workspace/upload',
    method: 'POST',
    data
  })
}

// 成员列表查询
export function aclList(data: any) {
  return request({
    baseURL: process.env.VUE_APP_DATA_API,
    url: `/workspace/${data.bizId}/aclList`,
    method: 'POST',
    data
  })
}

// 邀请成员
export function inviteTeamMember(data: any) {
  return request({
    baseURL: process.env.VUE_APP_DATA_API,
    url: `/workspace/${data.bizId}/inviteTeamMember`,
    method: 'POST',
    data: data.bizList
  })
}

// 受邀人同意、拒绝
export function agreeOrRefuse(data: IAgreeRefuse) {
  return request({
    baseURL: process.env.VUE_APP_DATA_API,
    url: `/workspace/${data.bizId}/agreeOrRefuse`,
    method: 'POST',
    data
  })
}

// 移出团队
export function memberRemove(data: IMember) {
  return request({
    baseURL: process.env.VUE_APP_DATA_API,
    url: `/workspace/${data.bizId}/memberRemove`,
    method: 'POST',
    data
  })
}

// 移交所有者
export function transferOwner(data: IMember) {
  return request({
    baseURL: process.env.VUE_APP_DATA_API,
    url: `/workspace/${data.bizId}/transferOwner`,
    method: 'POST',
    data
  })
}

// 变更人员角色
export function changeRole(data: IMember) {
  return request({
    baseURL: process.env.VUE_APP_DATA_API,
    url: `/workspace/${data.bizId}/changeRole`,
    method: 'POST',
    data
  })
}

// 工作台管理列表
export function selectListWsManager(params: IWsList) {
  return request({
    baseURL: process.env.VUE_APP_DATA_API,
    url: '/workspace/selectListWsManager',
    method: 'GET',
    params
  })
}

// 数量统计
export function wsCount() {
  return request({
    baseURL: process.env.VUE_APP_DATA_API,
    url: '/workspace/wsCount',
    method: 'GET'
  })
}

// 专项所有者信息
export function selectOwnerMsg() {
  return request({
    baseURL: process.env.VUE_APP_DATA_API,
    url: '/workspace/selectOwnerMsg',
    method: 'GET'
  })
}

// 替换所有者
export function replaceOwner(data: IMember) {
  return request({
    baseURL: process.env.VUE_APP_DATA_API,
    url: `/workspace/${data.bizId}/replaceOwner`,
    method: 'POST',
    data
  })
}

//
export function selectOwnerMsgByBizId(bizId: string) {
  return request({
    baseURL: process.env.VUE_APP_DATA_API,
    url: '/workspace/selectOwnerMsgByBizId',
    method: 'GET',
    params: {
      bizId
    }
  })
}

// 获取团队公告数据列表
export function getTNList(data: any) {
  return request({
    baseURL: process.env.VUE_APP_DATA_API,
    url: `workspace/${data.bizId}/notices`,
    method: 'GET'
  })
}

// 删除团队公告信息
export function delTN(data: any) {
  return request({
    baseURL: process.env.VUE_APP_DATA_API,
    url: `workspace/${data.bizId}/notice`,
    method: 'DELETE',
    data: {
      id: data.id,
      userId: data.userId
    }
  })
}

// 发布团队公告信息
export function releaseTN(data: any) {
  return request({
    baseURL: process.env.VUE_APP_DATA_API,
    url: `workspace/${data.bizId}/notice`,
    method: 'POST',
    data: {
      content: data.content,
      imgPath: data.imgPath,
    }
  })
}

// 修改全队公告信息
export function editTN(data: any) {
  return request({
    baseURL: process.env.VUE_APP_DATA_API,
    url: `workspace/${data.bizId}/notice`,
    method: 'PUT',
    data: {
      ...data.params
    }
  })
}



export interface IWsList {
  pageNum?: number
  pageSize?: number
  status?: string
  userName?: string
  keyword?: string
  createTimeSort?: string
  updateTimeSort?: string
}

export interface IMember {
  bizId: string,
  userId: string
  roleType?: string
}

export interface IAgreeRefuse {
  bizId: string,
  status: string
}

export interface IAddEdit {
  id?: string
  bizId?: string
  name?: string
  photoPath?: string
  remark?: string
}

export interface IStatus {
  id: string
  bizId: string
  status: string
}