import { Loading } from 'element-ui';
import Vue from 'vue';

let loadingRequestCount = 0; // 当前正在请求的request 次数
let loadingInstance;
export const showLoading = (config, text) => {
  if (loadingRequestCount === 0) {
    loadingInstance = Loading.service({ text: 'Loading' });
  }
  loadingRequestCount++;
};

export const hideLoading = (res) => {
  if (loadingRequestCount <= 0) return;
  loadingRequestCount--;
  if (loadingRequestCount === 0) {
    Vue.nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
      loadingInstance.close();
    });
  }
};

const LoadingManager = {
  delay_loading_timemer: 0, // loading 延迟时间
  loading_request_pool: [],
  request_pool: [], // 请求id 池，请求结束后从池子中删除
  loadingInstance: undefined,
  loading_text: '',
  add: function(config) {
    this.setLoadingText(config);
    const requestId = config.requestId;
    this.request_pool.push(requestId);
    this.monitor(requestId);
  },
  setLoadingText: function(config) {
    // 从请求中找 loading text 是否设置
    let text = window.vueInstance.$t('system.loading');
    if (config.loading_text) {
      text = config.loading_text;
    } else if (config.params && config.params.loading_text) {
      text = config.params.loading_text;
      delete config.params.loading_text;
    } else if (config.data && config.data.loading_text) {
      text = config.data.loading_text;
      delete config.data.loading_text;
    }
    this.loading_text = text;
  },
  remove: function(config) {
    const requestId = config.requestId;    
    if (this.request_pool.indexOf(requestId) > -1) {
      // 移除 对应的请求id, 以及对应的id 对应的 loading 状态
      this.request_pool.splice(this.request_pool.indexOf(requestId), 1);
      if (this.loading_request_pool.indexOf(requestId) > -1) {
        // 此时说明该请求触发了loading， 从 loading 池冲移除
        this.removeLoadingRequest(requestId);
      }
    }
  },
  // 请求所有的 loading 状态
  removAll() {
    this.request_pool = [];
    this.loading_request_pool = [];
    this.hideLoading();
  },
  addLoadingRequest: function(requestId) {
    this.loading_request_pool.push(requestId);
    if (this.loading_request_pool.length == 1) {
      // 启动 loading
      this.showLoading();
    }
  },
  removeLoadingRequest(requestId) {
    this.loading_request_pool.splice(this.loading_request_pool.indexOf(requestId), 1);
    // 如果删除后 loading 池没有了
    if (this.loading_request_pool.length == 0) {
      this.hideLoading();
    }
  },
  contain(requestId) {
    return this.request_pool.indexOf(requestId) > -1;
  },
  // 监听这个请求,如果这周
  monitor: function(requestId) {
    // 定时时间后如果还包含对应的 request id，则需要进行 loading
    setTimeout(() => {
      if (this.contain(requestId)) {
        this.addLoadingRequest(requestId);
      }
    }, this.delay_loading_timemer);
  },
  showLoading: function(text) {
    this.loadingInstance = Loading.service({
      text: this.loading_text,
      fullscreen: true,
      lock: true,
      spinner: 'el-icon-loading',
      customClass: 'e2e-loading'
    });
  },
  hideLoading: function() {
    Vue.nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
      this.loadingInstance.close();
    });
  },
  logLoadingInfo: function() {
    console.log('request_pool',this.request_pool)
    console.log('loading_request_pool',this.loading_request_pool)
  }
};

window._LoadingManager = LoadingManager

export default LoadingManager;
