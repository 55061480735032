var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reg-font-color", staticStyle: { cursor: "pointer" } },
    [
      _c("user-avatar", {
        attrs: { size: _vm.size, name: _vm.name[0], avatar: _vm.currAvatar },
        nativeOn: {
          click: function ($event) {
            return _vm.toSetting($event)
          },
        },
      }),
      _c(
        "el-dialog",
        {
          staticStyle: { "padding-left": "15px" },
          attrs: {
            width: "544px",
            "modal-append-to-body": false,
            "append-to-body": true,
            visible: _vm.showSetting,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSetting = $event
            },
            closed: function ($event) {
              return _vm.getUserSetting()
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("span", { staticClass: "reg-font-color" }, [
              _vm._v(_vm._s(_vm.$t("app.user")) + " / "),
            ]),
            _c("span", { staticClass: "reg-font-color" }, [
              _vm._v(_vm._s(_vm.$t("app.setting"))),
            ]),
          ]),
          _c(
            "el-form",
            {
              staticClass: "dialog-user-setting",
              attrs: { "label-position": "top", model: _vm.userSetting },
            },
            [
              _c("div", { staticClass: "user-setting-box-1" }, [
                _c(
                  "div",
                  {
                    staticClass: "f-c-c",
                    staticStyle: {
                      width: "110px",
                      height: "110px",
                      position: "relative",
                      "flex-shrink": "0",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "avatar-setting f-c-c" },
                      [
                        _vm.avatarImgPath
                          ? _c("el-image", {
                              staticStyle: {
                                width: "100%",
                                height: "100%",
                                "border-radius": "50%",
                              },
                              attrs: { fit: "cover", src: _vm.avatarImgPath },
                            })
                          : _c("span", [
                              _vm._v(_vm._s(_vm._makePy(_vm.name[0] || "")[0])),
                            ]),
                      ],
                      1
                    ),
                    _c(
                      "el-upload",
                      {
                        staticClass: "avatar-r-b f-c-c",
                        attrs: {
                          action: _vm.uploadUrl,
                          headers: _vm.uploadHeader,
                          "show-file-list": false,
                          "on-success": _vm.handleAvatarSuccess,
                          "with-credentials": "",
                          accept: ".jpg,.jpeg,.png",
                        },
                      },
                      [_c("i", { staticClass: "el-icon-upload upload-config" })]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "user-info" }, [
                  _c("div", { staticClass: "user-info-name" }, [
                    _vm._v(" " + _vm._s(_vm.userInfo.name) + " "),
                  ]),
                  _c("div", { staticClass: "user-info-username" }, [
                    _vm._v(" " + _vm._s(_vm.userInfo.username) + " "),
                  ]),
                  _c("div", { staticClass: "user-info-roles" }, [
                    _vm._v(" " + _vm._s(_vm.userInfo.roles) + " "),
                  ]),
                ]),
              ]),
              _c("el-divider"),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("app.langSelect") } },
                [
                  _c(
                    "el-select",
                    {
                      on: { change: _vm.changeLang },
                      model: {
                        value: _vm.userSetting.lang,
                        callback: function ($$v) {
                          _vm.$set(_vm.userSetting, "lang", $$v)
                        },
                        expression: "userSetting.lang",
                      },
                    },
                    _vm._l(
                      _vm.dict.type.system_language || [],
                      function (dictData) {
                        return _c("el-option", {
                          key: dictData.value,
                          attrs: {
                            value: dictData.value,
                            label: dictData.label,
                          },
                        })
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("app.theme") } },
                [
                  _c(
                    "el-select",
                    {
                      on: { change: _vm.changeTheme },
                      model: {
                        value: _vm.userSetting.theme,
                        callback: function ($$v) {
                          _vm.$set(_vm.userSetting, "theme", $$v)
                        },
                        expression: "userSetting.theme",
                      },
                    },
                    _vm._l(_vm.themeList, function (theme) {
                      return _c("el-option", {
                        key: theme.code,
                        attrs: { value: theme.code, label: theme.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "training",
                  attrs: { label: _vm.$t("app.training") },
                },
                [
                  _c(
                    "el-link",
                    {
                      attrs: { type: "primary", target: "_blank" },
                      on: { click: _vm.previewFile },
                    },
                    [_vm._v("targus培训材料")]
                  ),
                  _c(
                    "div",
                    { staticStyle: { "padding-top": "10px" } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          on: { change: _vm.checkedChange },
                          model: {
                            value: _vm.checked,
                            callback: function ($$v) {
                              _vm.checked = $$v
                            },
                            expression: "checked",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "white-space": "break-spaces",
                                "word-break": "break-word",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("app.trainingConfirmMsg")))]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "info", size: "small" },
                      on: { click: _vm.noSaveSetting },
                    },
                    [_vm._v(_vm._s(_vm.$t("app.cancel")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.saveSetting },
                    },
                    [_vm._v(_vm._s(_vm.$t("app.save")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", size: "small" },
                      on: { click: _vm.logout },
                    },
                    [_vm._v(_vm._s(_vm.$t("app.logout")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }