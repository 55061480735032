




import { Component, Vue } from "vue-property-decorator";
import login35 from "./login35.vue";

@Component({
  components: {
    login35
  },
})
export default class extends Vue {
}
