<template>
  <div v-if="isExternal" :style="styleExternalIcon" class="svg-external-icon svg-icon" v-on="$listeners" />
  <div v-else  :class="svgIconOuter">
    <svg :class="svgClass" aria-hidden="true" v-on="$listeners">
      <use :xlink:href="iconName" />
    </svg>
  </div>
</template>

<script >
import { isExternal } from '@/utils/validate'
export default {
  name: 'SvgIcon',
  props: {
    type: {
      type: String,
      require: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    iconClass: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    }
  },
  computed: {
    isExternal() {
      return isExternal(this.iconClass)
    },
    svgIconOuter(){
      return [
        'svg-icon-outer',
        'svg-icon-outer__'+(this.type?this.type:'default'),
        (this.disabled?'svg-icon-outer__disabled':'')
      ]
    },
    iconName() {
      return `#icon-${this.iconClass}`
    },  
    svgClass() {
      if (this.className) {
        return 'svg-icon ' + this.className
      } else {
        return 'svg-icon'
      }
    },
    styleExternalIcon() {
      return {
        mask: `url(${this.iconClass}) no-repeat 50% 50%`,
        '-webkit-mask': `url(${this.iconClass}) no-repeat 50% 50%`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// .svg-icon-outer {
//   width: 1em;
//   height: 1em;
//   &__primary {
//     color: $--color-primary
//   }
//   &__disabled {
//     color: $--color-text-placeholder;
//     cursor: not-allowed;
//     pointer-events: painted;
//   }
// }

</style>