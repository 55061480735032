































































































import Vue from "vue";
import ConfirmInvite from '@/modules/ai/Workbench/ConfirmInvite.vue'
import { deleteNews, ReadNewsList, selectInvideAcl, setReadStatus } from "@/api/user/message";
import { getApplicationInstance } from "@/pages/application";
import { routerPush } from "@/app-action";

export default Vue.extend({
  name: 'MyMessage',
  components: {
    ConfirmInvite
  },
  data() {
    return {
      currMsg: {},
      workbenchName: '',
      agreeOrRejectVisible: false,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      msgList: []
    }
  },
  computed: {
    language() {
      return this.$store.state.user.lang
    }
  },
  // created() {
  //   this.fetchData()
  // },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      ReadNewsList({
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }).then(res => {
        this.msgList = res.pagedList || []
        this.total = res.totalCount || 0
        setTimeout(() => {
          this.getMsgToggle()
        }, 200)
      })
      console.log('1:', 1)
    },

    loadMore() {
      this.pageSize += 10
      this.fetchData()
    },
    async markAllRead() {
      await this.$confirm((this as any).$t('message.confirmRead'), (this as any).$t('app.confirm'), {
        type: 'warning'
      })
      // 请求接口
      await setReadStatus()
      // 刷新未读消息
      await this.$store.dispatch('getMsgNum')
      // 刷新消息
      this.fetchData()
    },

    // 删除消息
    async deleteMsg(item: any) {
      // 未读
      if (item.readStatus === '0') {
        await this.$confirm((this as any).$t('message.confirmDelMsg'), (this as any).$t('app.confirm'), {
          type: 'warning'
        })
      }
      // await 接口删除
      await deleteNews({id: item.id})
      this.$message.success((this as any).$t('app.delSuccess'))
      await this.$store.dispatch('getMsgNum')
      this.fetchData()
    },

    getMsgStatus(item: any) {
      let s = 'msg-status-'
      const map = item.routeDataMap || {}
      if (map.newsLevel === '1') {
        return s += 'p'
      }
      if (map.newsLevel === '2') {
        return s += 'w'
      }
      if (map.newsLevel === '3') {
        return s += 'd'
      }
    },
    // 匹配和替换头内容
    getMsgText(item: any) {
      const reg = /(\[(.+?)])|(【(.+?)】)/g
      const map = item.newBodyMap || {}
      return map[this.language].match(reg)[0]
    },
    // 匹配和替换正文内容
    getMsgContent(item: any) {
      if (item.newBodyMap) {
        const reg = /(\[(.+?)])|(【(.+?)】)/g
        let msg = item.newBodyMap[this.language]
        return msg.replace(msg.match(reg)[0], '')
          .replaceAll('协作者', this.$t('wb.collaborators'))
          .replaceAll('查看者', this.$t('wb.viewer'))
      }

      return ''
    },
    // 匹配标题
    getMsgTitle(item: any) {
      if (item.routeDataMap) {
        const type = item.routeDataMap.noticeType
        const obj: any = {
          1: this.$t('message.memberInvitation'),
          2: this.$t('message.memberManage'),
          3: this.$t('message.specialManage'),
          4: this.$t('message.dataAuth'),
          5: this.$t('message.editorialInterpretation'),
          6: this.$t('message.internalRegulationOptimization'),
          7: this.$t('message.articleUpload'),
          8: this.$t('message.articleRevision'),
          9: this.$t('message.crawlerMonitoring')
        }
        return obj[type]
      }
      return ''
    },

    getMsgToggle() {
      console.log('this.$refs.msgToggleRef:', this.$refs.msgToggleRef)
      const refList: any = this.$refs.msgToggleRef || []
      for (let i = 0; i < refList.length; i++) {
        console.log('toggle.scrollHeight:' + i, refList[i].scrollHeight)
        // 盒子高度100px, 考虑字体是14px还有行高
        this.$set(this.msgList[i], 'showToggle', refList[i].scrollHeight > 60)
        this.$set(this.msgList[i], 'toggleFlag', false)
      }
    },
    // 切换
    toggleContent(item: any, index: number) {
      const ref = (this as any).$refs.msgToggleRef[index]

      item.toggleFlag = !item.toggleFlag
      this.$nextTick(() => {
        // 展开
        if (item.toggleFlag) {
          // 只能设置dom上已存在的属性
          ref.style.maxHeight = ref.scrollHeight + 'px'
        } else {
          ref.style.maxHeight = '60px'
        }
      })
    },
    // 读消息
    async readIt(item: any) {
      console.log('item:', item)
      // 未读
      if (item.readStatus === '0') {
        // 邀请类型
        if (item.routeKey === 'workspaceInvite') {
          this.currMsg = item
          if (item.routeDataMap && item.routeDataMap.workspaceId) {
            const reg = /(\[(.+?)])|(【(.+?)】)/g
            let msg = item.newBodyMap[this.language]
            let wbName: string = msg.match(reg)[1]
            this.workbenchName = wbName.substring(1, wbName.length - 1)
            // 是否已经是成员了
            const { data } = await selectInvideAcl({
              bizId: item.bizId
            })
            // 还不是成员 -> 弹框
            if (data && data.length) {
              return this.agreeOrRejectVisible = true
            }
          }
        }

        await setReadStatus([{ id: item.id }])
        await this.$store.dispatch('getMsgNum')
        this.fetchData()
      }
    },
    // 邀请弹窗 同意或拒绝
    async agreeOrRejectFeeback(status: string) {
      // 标记已读
      await setReadStatus([{ id: (this as any).currMsg.id }])
      // 同意 跳转到专项
      if (status === '0') {
        if(getApplicationInstance().appId == 'workbench' || (this.$route.meta || {}).appname  == 'workbench') {
          routerPush({
            path: `/workbench/${(this as any).currMsg.routeDataMap.workspaceId}/index`
          })
        }
        else {
          let url = `${location.origin}/workbench/#/workbench/${(this as any).currMsg.routeDataMap.workspaceId}/index`
          window.open(url)
        }
      }
      await this.$store.dispatch('getMsgNum')
      this.fetchData()
    }
  }
})
