
















































import { Component, Vue } from "vue-property-decorator";
import layoutStyle35 from "@/views/layout/layoutStyle35.vue";
import Internal35New from "./internal35New.vue";
import { ApplicationModule } from "@/store/modules/application";
import Register from "../userAccount/register.vue";
import ForgetPassword from "../userAccount/forgetPassword.vue"

@Component({
  components: { layoutStyle35, Internal35New,Register,ForgetPassword },
    name: "login-35",
  })
export default class extends Vue {
  register() {
    this.$router.push({
      path: '/account/register'
    })
  }
  typeName = 'login';
  get logined() {
    return ApplicationModule.login;
  }
  mounted(){
    this.initData();
    (window as any).onresize();
  }
  destroyed(){
    window.onresize = null;
  }
  initData() {
    function setScale(){
      // 设计稿的宽度，设计稿的高度 根据实际项目调整
      let designWidth = 1280;
      let designHeight = 880;
      // 内容区域的可视宽度, 高度
      let {clientHeight} = document.querySelectorAll('html')[0];
      // 缩放系数 (宽度)
      // let scaleWidth = clientWidth / designWidth;
      let scaleHeight = clientHeight / designHeight;
      // 需改动的dom节点
      ['#article'].forEach((dom) => {
        (document as any).querySelector(dom).style = `width: ${designWidth * scaleHeight}px; height: ${designHeight * scaleHeight}px;`;
      })
      // 缩放节点
      let scaleDom:any = document.querySelector('#scaleContent')
      scaleDom.style = `transform: scale(${scaleHeight},${scaleHeight})`;
      // 获取元素缩放后的实际宽度
      let scaleDomWidth:any =  scaleDom.getClientRects()[0].width; 
      (document as any).querySelector('.section').style = `width: ${scaleDomWidth}px; height: ${designHeight * scaleHeight}px;`
    }
    window.onresize = function () {
      setScale()
    };
  }
}
