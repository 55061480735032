
import request from '@/utils/request';
// 文件上传
export function ICResourceFileUpload(blob) {
    return request({
        url: '/birp/file/upload',
        method: 'POST',
        data: blob
    });
}

export function findFiles(businessType,businessId) {
    return request({
        url: '/birp/file/select-file-list',
        method: 'GET',
        params: {
            businessType,
            businessId
        }
    });
}
// 文件上传
export function ICResourceFileDetail(bid) {
    return request({
        url: '/birp/file/detail',
        method: 'POST',
        data: bid
    });
}

export function deleteFile(bid) {
    return request({
        url: '/birp/file/delete_file',
        method: 'POST',
        data: {bid}
    });
}


// 文件下载,单独实现
export function download(params) {
    return request({
        url: '/birp/file/download',
        method: 'POST',
        responseType: 'blob',
        data: params
    });
    // return fetch("/birp/file/download", {
    //     "headers": {
    //         "accept": "application/json, text/plain, */*",
    //         "accept-language": "zh-CN,zh;q=0.9,en-US;q=0.8,en;q=0.7",
    //         "content-type": "application/json;charset=UTF-8",
    //         "sec-fetch-mode": "cors",
    //         "sec-fetch-site": "same-origin",
    //         "x-requested-with": "XMLHttpRequest"
    //     },
    //     "body": JSON.stringify(params),
    //     "method": "POST",
    //     "mode": "cors",
    //     "credentials": "include"
    // })
    // 这是下载完成之后返回，监控不到下载进度
    // .then(response => {
    //     let filename = null;
    //     try {
    //         filename = response.headers.get('Content-Disposition')
    //             .split(';')[1].split('=')[1];
    //         filename = decodeURIComponent(filename);
    //     } catch (e) {
    //         console.error(e);
    //     }
    //     console.debug("开始传输数据！");
    //     return response.blob();
    // })
}

// 下载模板
export function importTpl(url) {
    return request({
      url: url,
      method: "POST",
      responseType: 'blob',
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }
