var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "user-avatar",
      style: {
        width: _vm.width,
        height: _vm.height,
        lineHeight: _vm.height,
        fontSize: _vm.fontSize,
      },
    },
    [
      _vm.fullUrl
        ? _c("el-image", {
            staticStyle: { width: "100%", height: "100%" },
            attrs: { fit: "cover", src: _vm.fullUrl },
          })
        : _c("span", [_vm._v(_vm._s(_vm._makePy(_vm.name[0] || "")[0]))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }