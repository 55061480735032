import request from "@/utils/request";
import { Menu } from "./sys/menu"
import qs from 'qs';
import { app, getApplicationInstance } from "@/pages/application";
import { ApplicationModule } from '@/store/modules/application'
export interface Result<T> {
    code: string
    msg: string
    data: T,
    ok: boolean
}
export interface Page<T> {
    records: Array<T>
    total?: number
    size: number
    current: number
    pages?: number
}
export declare interface User {
    id?: string,
    name?: string,
    username?:string,
    usercode?: string,
}
export interface SystemStats {
    login?: boolean
    principal?: User
    menus?: Array<Menu>
    authProperties?: AuthProperties
    userConfig?: any
    applications?: any
    menuStyle?: any
}

export enum AuthProtocol {
    INTERNAL="INTERNAL",OAUTH2="OAUTH2"
}
export interface AuthProperties {
    configs: Array<AuthConfig>
}
export interface AuthConfig {
    id?:string;
    enabled?:boolean;
    title?:string;
    /**
        * 登录协议
        */
    protocol?:AuthProtocol;

    internalLoginUrl?:string;

    internalEnableCaptcha?:boolean;
    internalCaptchaUrl?:string;

    oauthClientId?:string;
    oauthClientSecret?:string;

    oauthRedirectUrl?:string;
    oauthAuthorizationUrl?:string;
    oauthAccessTokenUrl?:string;

    oauthResourceUrl?:string;
    oauthUserIdentity?:string;
    oauthScopes?:string;
}
export interface BaseEntity {
    createTime?: string
    updateTime?: string
    createBy?: string
    updateBy?: string
}
export interface Datasource {
    id:string
    dataType:string
    connectName:string
    dataSourceName:string
    dataSourceRemark:string
    dataSourceType:string
    dataSourceDriver:string
    dataSourceUrl:string
    ip:string
    port:number
    username:string
    password:string
    delFlag:string
}
export interface  DatasourceTable {
    tableName:string
    tableRemarks:string
}
export interface IDataTableComment {
    bizId?: string
    workspaceBizId?: string
    content?: string
}
export type TableFieldType = 'VARCHAR' | 'INTEGER' | 'DOUBLE' | 'TIME' | 'DATETIMME'
export interface TableField {
    checked?:boolean
    field: string,
    type: TableFieldType
    zh: string,
    mask?: number,
    encrypt?: number
    /**
     * 是否是主键： 1：是，0:否
     */
    isPrimaryKey?:number
    [key: string]: any
}
export interface Table {
    name: string
    remark: string
}
export interface AuthEntry {
    id?: string,
    authType: string,
    authGroup: string,
    authObject: string,
    name: string,
    perms?: Array<string>,
}
export interface SysFile {
    id: string,
    originName: string,
    path: string,
    size: number,
    bizId: string,
    bizType: string,
    extension: string
}

export interface Dept {
    deptId?:string;
    /**
    *上级部门ID
    */
    parentId?:string;

    parent?:string;

    /**
    *部门编码
    */
    code?:string;

    /**
    *部门名称
    */
    name?:string;

    /**
    *排序
    */
    orderNum?:string;

    /**
    *是否删除  -1：已删除  0：正常
    */
    delFlag?:string;

    /**
    *负责人
    */
    principal?:string;

    /**
    *联系方式
    */
    contact?:string;

    /**
    *描述
    */
    remark?:string;

    /**
    *树级路径
    */
    path?:string;

    /**
    *部门层级编码
    */
    levelCode?:string;
    children?:Array<Dept>;
}

export interface LoginForm {
    grant_type?: string;
    scope?: string,
    protocol?: string;
    username: string;
    password: string;
    captchaCode: string;
    captchaKey: string;
    oauth2Code?: string
    authConfigId?: string
}
export interface Captcha {
  image: string;
  key: string;
}
export interface LoginResult{
    access_token: string;
}

export function getSystemStats(appId=getApplicationInstance().appId):Promise<Result<SystemStats>> {
    return request({
        baseURL: process.env.VUE_APP_SYSTEM_API,
        url: '/login/stats',
        params: {
            'appId': appId
        }
    })
}
export function getAuthProperties():Promise<Result<AuthProperties>> {
    return request({
        baseURL: process.env.VUE_APP_SYSTEM_API,
        url: '/login/login_config',
        method: 'get'
    })
}
export const getCaptach = (params: {width:120,height:40,type:'gif'}):Promise<Result<Captcha>> => {
  return request({
    url: ApplicationModule.internalConfigs.internalCaptchaUrl,
    method: 'GET',
    // params
  })
}

export const login = (data: LoginForm):Promise<Result<LoginResult>> =>{
    return request({
      url: ApplicationModule.internalConfigs.internalLoginUrl,
      method: 'post',
      headers: {
        Authorization: 'Basic ' + btoa('mirsp:mirsp')
      },
      data: qs.stringify(data)
    })
  }
  export const loginOauth2 = (data: LoginForm):Promise<LoginResult> =>{
    return request({
      url: ApplicationModule.internalConfigs.internalLoginUrl,
      method: 'post',
      headers: {
        Authorization: 'Basic ' + btoa('mirsp:mirsp')
      },
      data: qs.stringify(data)
    })
  }
export function logout(){
    return request({
      url: '/login/logout',
      method: 'POST'
    })
}