















































import { Component, Prop, Vue, Emit, Ref, Watch } from "vue-property-decorator";
import { WorkspaceModule } from '@/store/modules/workbench'
import SvgIcon from '@/component/svgIcon/index.vue'
import { UserModule } from "@/store/modules/user";
import i18n from "@/pages/home/lang";
import { cloneDeep } from "lodash";
@Component({
  components: {SvgIcon},
  beforeRouteEnter(to, from, next) {
    const isWorkspaceId = /^\d+$/.test(to.params.id);
    if (isWorkspaceId) {
      WorkspaceModule.setCurrentWorkbench(to.params.id).then(() => {
        next();
      })
    } else {
      next();
    }
  },
  beforeRouteUpdate(to, from, next) {
    console.log("enter workbench layout", to.params.id, /^\d+$/.test(to.params.id));
    const isWorkspaceId = /^\d+$/.test(to.params.id);
    if (to.params.id == from.params.id) {
      next();
      return;
    }
    if (isWorkspaceId) {
      WorkspaceModule.setCurrentWorkbench(to.params.id).then(() => {
        next();
      })
    } else {
      next();
    }

  },
  beforeRouteLeave(to, from, next) {
    
    if(to.path.startsWith('/workbench/') && to.params.id == from.params.id){
      //not leave current workbench
    }else{
      WorkspaceModule.setCurrentWorkbench('');
    }
    next();
  }
})
export default class WorkspaceLayout  extends Vue{
  activeIndex = ''
  avatarImg = ''
  get hideHeader(): boolean {
      return (this.$route.meta || {}).hideHeader == true
    }
  get   currWorkbench() {
      return WorkspaceModule.workspace || {}
    }
  get  isWorkbenchMember() {
      return WorkspaceModule.isWorkbenchMember
    }
  get  isEdit() {
      return this.$route.params.id
    }
  get  sonMenus() {
    
      let menusTemp = []

      let menu = this.getI18nName(cloneDeep(UserModule.leftMenu))
      for (let index = 0; index < menu.length; index++) {
        const element = menu[index];
        if(element.nameEn === 'Workbench') {
          menusTemp = (element.children.filter((e:any) => {return e.visible == '1'}) ||[]).map((e:any) => {
            return { text: e.name, path: e.url.replace(':id',this.$route.params.id) }
          })

          break;
        }
      }

      return menusTemp;

      // return [
      //   { text: this.$t('menu.lastModified'), path: `${baseUrl}/index` },
      //   { text: this.$t('menu.integrate'), path: `${baseUrl}/integrate` },
      //   { text: this.$t('menu.process'), path: `${baseUrl}/process` },
      //   { text: this.$t('menu.model'), path: `${baseUrl}/model` },
      //   { text: this.$t('menu.ai'), path: `${baseUrl}/ai` },
      //   { text: this.$t('menu.explore'), path: `${baseUrl}/explore` },
      //   { text: this.$t('menu.schedule'), path: `${baseUrl}/schedule` }
      // ]
    }

    getI18nName(menus: any[]) {
      return menus.map((menu) => {
        if (menu.children != null && menu.children.length != 0) {
          menu.children = this.getI18nName(menu.children)
        }
        if (i18n.locale == "zh-Hans") {
          menu.name = menu.nameHans || menu.name
        } else if (i18n.locale == "en") {
          menu.name = menu.nameEn || menu.name
        } else {
          // Do nothing
        }
        return menu
      })
    }
   get sonRightMenus() {
      const baseUrl = '/workbench/' + this.$route.params.id
      return [
        { text: this.$t('menu.members'), path: `${baseUrl}/members`, icon: 'el-icon-user' },
        { text: this.$t('menu.workPlan'), path: `${baseUrl}/Notice`, icon: 'el-icon-document-checked' },
        { text: this.$t('menu.workbenchSetting'), path: `${baseUrl}/edit`, icon: 'el-icon-setting' }
      ]
    }
    // 新增专项
   get isWorkbenchAdd() {
      return this.$route.path === '/workbench/Add'
    }
    // 当前专项名称
   get  currWorkbenchName() {
      // 新建专项时
      if (this.isWorkbenchAdd) {
        return ''
      }
      return (this as any).currWorkbench.name
    }
    // 当前专项备注
   get currWorkbenchRemark() {
      // 新建专项时
      if (this.isWorkbenchAdd) {
        return ''
      }
      return (this as any).currWorkbench.remark
    }
    // 当前专项头像
   get currWorkbenchAvatar() {
      // 新建专项时
      if (this.isWorkbenchAdd) {
        return ''
      }
      return (this as any).currWorkbench.avatarImg
    }

    handleSelect(key: string, keyPath: string) {
      this.$router.push({
        path: key
      })
      console.log(key, keyPath);
    }



    @Watch('$route.path',{
      immediate: true
    })
    onRoutePathChange(val:string){
      this.activeIndex = val
    }
}
