import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Layout0 from '@/views/layout.vue'
import LayoutBlank from '@/views/layoutBlank.vue'
import HeadLessLayout from '@/views/HeadLessLayout.vue'
import WorkbenchLayout from '@/views/WorkbenchLayout.vue'
import BlankLayout from '@/views/BlankLayout.vue'
import TabViewLayout from '@/views/TabViewLayout.vue'
import Layout35 from '@/views/layout35.vue'
import { app, getApplicationInstance } from '@/pages/application'

export const exampleComponents:{[key:string]: any} = {}
Vue.use(VueRouter)
let Layout:any = undefined;
if(window.location.toString().indexOf('inIframe') > -1){
    Layout = HeadLessLayout;
}else {
  Layout = Layout0;
}
export const layouts = {
  Layout,
  LayoutBlank,
  HeadLessLayout,
  WorkbenchLayout,
  BlankLayout,
  TabViewLayout,
  Layout35
}

const routes: Array<RouteConfig> = [
  
]
export function  addUserRoute(route:RouteConfig){
    getApplicationInstance().router.addRoute({
      path:'',
      component: Layout,
      children: [
        // route
      ]
    })
}
export function  addAppRoute(appId: string){
    let pathPrefix = '/'
    const win = window as any;
    // 子应用运行时
    if (win.__POWERED_BY_QIANKUN__) {
      pathPrefix = process.env.VUE_APP_QIANKUN_ROUTE
    }
    getApplicationInstance().router.addRoute({
      path:pathPrefix+appId,
      name: 'targus-'+appId,
      component: () => import(/* webpackChunkName: "apps" */ '@/views/layoutApp.vue'),
      meta: {
        appname: appId
      }
    })
}
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
export default router
