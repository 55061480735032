var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-message" },
    [
      _c(
        "div",
        { staticClass: "m-r-10", staticStyle: { "text-align": "right" } },
        [
          _c(
            "span",
            {
              staticClass: "pri-font-color",
              staticStyle: { cursor: "pointer" },
              on: { click: _vm.markAllRead },
            },
            [_vm._v(_vm._s(_vm.$t("message.markAllRead")))]
          ),
        ]
      ),
      _vm._l(_vm.msgList, function (item, index) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "msg-item-box",
            staticStyle: { padding: "10px" },
          },
          [
            _c(
              "div",
              { staticClass: "msg-con-box" },
              [
                _c("div", { staticClass: "f-b-s" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "normal-font-color",
                          staticStyle: { "font-size": "12px" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                new Date(item.createTime).toLocaleString()
                              ) +
                              " "
                          ),
                        ]
                      ),
                      item.readStatus === "0"
                        ? _c(
                            "el-badge",
                            {
                              staticClass: "item",
                              attrs: { value: _vm.$t("message.noRead") },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "msg-status-p m-t-10" },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.getMsgTitle(item)) + " "
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _c("div", { staticClass: "msg-status-p m-t-10" }, [
                            _vm._v(" " + _vm._s(_vm.getMsgTitle(item)) + " "),
                          ]),
                    ],
                    1
                  ),
                  _c("i", {
                    staticClass: "el-icon-delete msg-status-p",
                    staticStyle: { "font-size": "16px", cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        return _vm.deleteMsg(item)
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "pri-font-color m-t-10" }, [
                  _c(
                    "div",
                    {
                      ref: "msgToggleRef",
                      refInFor: true,
                      staticClass: "msg-content",
                      style: {
                        cursor: item.readStatus === "0" ? "pointer" : "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.readIt(item)
                        },
                      },
                    },
                    [
                      _c("span", { class: _vm.getMsgStatus(item) }, [
                        _vm._v(" " + _vm._s(_vm.getMsgText(item)) + " "),
                      ]),
                      _c(
                        "span",
                        {
                          class: {
                            "normal-font-color": item.readStatus === "1",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.getMsgContent(item)) + " ")]
                      ),
                    ]
                  ),
                  item.showToggle
                    ? _c(
                        "div",
                        {
                          staticClass: "msg-toggle",
                          on: {
                            click: function ($event) {
                              return _vm.toggleContent(item, index)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.toggleFlag
                                  ? _vm.$t("message.packUp")
                                  : _vm.$t("message.expand")
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _c("el-divider"),
              ],
              1
            ),
          ]
        )
      }),
      _c(
        "div",
        {
          staticClass: "sec-font-color",
          staticStyle: { "padding-left": "20px", "padding-bottom": "10px" },
        },
        [
          _vm.msgList.length === _vm.total
            ? _c("span", [_vm._v(_vm._s(_vm.$t("message.noMore")))])
            : _c(
                "span",
                {
                  staticStyle: { cursor: "pointer" },
                  on: { click: _vm.loadMore },
                },
                [_vm._v(_vm._s(_vm.$t("message.loadMore")))]
              ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { "padding-left": "15px" },
          attrs: {
            width: "544px",
            visible: _vm.agreeOrRejectVisible,
            "show-close": false,
            "append-to-body": true,
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.agreeOrRejectVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "f-b-c",
              staticStyle: { padding: "0 22px" },
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("div", [
                _c("span", { staticClass: "pri-font-color" }, [
                  _vm._v(_vm._s(_vm.workbenchName) + "/"),
                ]),
                _c("span", { staticClass: "pri-font-color" }, [
                  _vm._v(_vm._s(_vm.$t("message.teamInvitation")) + "/"),
                ]),
                _c("span", { staticClass: "reg-font-color" }, [
                  _vm._v(_vm._s(_vm.$t("message.confirmInvitation"))),
                ]),
              ]),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "button-secondary",
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.agreeOrRejectVisible = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("app.cancel")))]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm.agreeOrRejectVisible
            ? _c("ConfirmInvite", {
                attrs: {
                  workbenchId: _vm.currMsg.routeDataMap.workspaceId,
                  visible: _vm.agreeOrRejectVisible,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.agreeOrRejectVisible = $event
                  },
                  feedback: _vm.agreeOrRejectFeeback,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }