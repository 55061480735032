import { Menu } from "@/api/sys/menu";
import { ApplicationModule } from "@/store/modules/application";
import { MessageBox } from "element-ui";
import { debounce, throttle } from "lodash";
import VueRouter from "vue-router";
import { login, LoginForm, loginOauth2, logout } from "@/api/system";
import * as appActions from '@/app-action';

let _appId = '';
let _accessToken = '';
let _menus:Array<Menu> = [];
let _goto:any;
let _router:VueRouter
export class Application {
    constructor(model: ApplicationModel){
        if(!model.appId){
            throw new Error("");
        }
        _appId = model.appId
        _accessToken = sessionStorage.getItem('VUE_APP_ACCESS_TOKEN') || ''
    }
    get appId(): string {
        return _appId;
    }
    get accessToken(): string|undefined {
        return _accessToken || undefined;
    }
    get router(){
        return _router
    }
    set router(router:VueRouter){
        _router = router;
    }
    get menus(){
        return _menus;
    }
    async start(){
        console.log('app started')
        ApplicationModule.setSystemStats()
        //TODO: 加载应用信息
        const loadApp = Promise.resolve();
        const loadUserStats  = Promise.reject();
        return Promise.all([loadApp,loadUserStats]);
        //TODO: 加载用户信息（姓名/账号/角色/权限）
    }
    async login(userInfo: LoginForm) {
        // let { username, password,captchaCode,captchaKey,grant_type } = userInfo;
        // const {grant_type} = userInfo;
        // username = username.trim()
        // password = password.trim()
        // captchaCode = captchaCode.trim()
        // captchaKey = captchaKey.trim()
        try{
            const res = await loginOauth2(userInfo)
            //@ts-ignore
            if(res.access_token){
                //@ts-ignore
                sessionStorage.setItem("VUE_APP_ACCESS_TOKEN",res.access_token)
                _accessToken = sessionStorage.getItem('VUE_APP_ACCESS_TOKEN') || ''
                location.href="/"
            }else{
                return Promise.reject('登录失败');
            }
        }catch(e){
            // return Promise.reject(e);
        }
    }
    async logout() {
        try{
            await logout();
            ApplicationModule.logouted()
            appActions.logout()
        }catch(e){
        //
        }
        sessionStorage.removeItem("VUE_APP_ACCESS_TOKEN")
        _accessToken = sessionStorage.getItem('VUE_APP_ACCESS_TOKEN') || ''
    }
    destroy(){
        _accessToken = '';
        _appId = '';
        _menus = [];
        console.log('destroy method access')
        if(!_goto){
            _goto = 
            debounce(()=>{
                _goto = null;
                this.gotoLoginPage()
            },1000,{leading: false,trailing: true,maxWait: 1000});
        }
        _goto()
        
    }
    gotoLoginPage(){
        MessageBox.confirm('要离开该页面吗?','提示').then(()=>{
            const url = `${location.origin}/#/login`
            location.replace(url);
        })
    }
}
export interface ApplicationModel {
    appId?: string
}
export let app:Application | null = null;
export function createAppilication(appId: string):Application{
    app = new Application({appId})
    return app;
}
export function getApplicationInstance():Application{
    if(!app){
        throw new Error("Application not instanced")
    }
    return app;
}