







































import { Captcha, getCaptach, LoginForm , login} from "@/api/system";
import { Component, Vue } from "vue-property-decorator";
import { Dictionary } from "vue-router/types/router";
import { getApplicationInstance } from '@/pages/application';
import { ApplicationModule } from '@/store/modules/application'

@Component({

})
export default class Internal extends Vue {
    ApplicationModule = ApplicationModule
    rememberMe = false
    showPassword = false
    loginForm: LoginForm = {
        grant_type: "password",
        scope: "server",
        username: "",
        password: "",
        captchaCode: "",
        captchaKey: ""
    }
    captcha: Captcha = {
        image: "",
        key: ""
    }
    private redirect?: string
    private otherQuery?: Dictionary<string> = {}
    mounted() {
        this.loadCaptcha()
    }
    async loadCaptcha() {
        const rs = await getCaptach({ width: 120, height: 40, type: "gif" });
        this.captcha = rs.data;
        this.loginForm.captchaKey = rs.data.key;
    }
    async handleLogin() {
        getApplicationInstance().login(this.loginForm).catch((msg)=>{
            this.$alert(msg,this.$tc('common.tips'))
        })
    }
    forget() {
        // this.$router.push({
        //     path: '/account/forget'
        // })
        (this.$parent as any).typeName = 'forgetPassword';
    }
}
