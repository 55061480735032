var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "register user-registration" }, [
    _c("div", { staticClass: "welcome" }, [
      _vm._v(_vm._s(_vm.$t("home.userAccount.registerWelcome")) + " "),
      _vm._m(0),
    ]),
    _c(
      "div",
      {
        staticClass: "register-form",
        staticStyle: { padding: "8px 12px", "border-radius": "8px" },
      },
      [
        _c(
          "el-scrollbar",
          { staticClass: "flex-grow-scrollbar-fix register-form-scroller" },
          [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: { model: _vm.saveForm, size: "small", rules: _vm.rules },
              },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("home.userAccount.name"),
                      prop: "name",
                    },
                  },
                  [
                    _c("el-input", {
                      staticClass: "input-bg-tran-simple",
                      attrs: {
                        placeholder: _vm.$t("home.userAccount.namePlaceholder"),
                      },
                      model: {
                        value: _vm.saveForm.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.saveForm, "name", $$v)
                        },
                        expression: "saveForm.name",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("home.userAccount.email"),
                      prop: "email",
                    },
                  },
                  [
                    _c("el-input", {
                      staticClass: "input-bg-tran-simple",
                      attrs: {
                        placeholder: _vm.$t(
                          "home.userAccount.emailPlaceholder"
                        ),
                      },
                      model: {
                        value: _vm.saveForm.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.saveForm, "email", $$v)
                        },
                        expression: "saveForm.email",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("home.userAccount.companyName"),
                      prop: "companyName",
                    },
                  },
                  [
                    _c("el-input", {
                      staticClass: "input-bg-tran-simple",
                      attrs: {
                        placeholder: _vm.$t(
                          "home.userAccount.companyNamePlaceholder"
                        ),
                      },
                      model: {
                        value: _vm.saveForm.companyName,
                        callback: function ($$v) {
                          _vm.$set(_vm.saveForm, "companyName", $$v)
                        },
                        expression: "saveForm.companyName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("home.userAccount.mobile"),
                      prop: "mobile",
                    },
                  },
                  [
                    _c("el-input", {
                      staticClass: "input-bg-tran-simple",
                      attrs: {
                        placeholder: _vm.$t(
                          "home.userAccount.mobilePlaceholder"
                        ),
                      },
                      model: {
                        value: _vm.saveForm.mobile,
                        callback: function ($$v) {
                          _vm.$set(_vm.saveForm, "mobile", $$v)
                        },
                        expression: "saveForm.mobile",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("home.userAccount.position"),
                      prop: "position",
                    },
                  },
                  [
                    _c("el-input", {
                      staticClass: "input-bg-tran-simple",
                      attrs: {
                        placeholder: _vm.$t(
                          "home.userAccount.positionPlaceholder"
                        ),
                      },
                      model: {
                        value: _vm.saveForm.position,
                        callback: function ($$v) {
                          _vm.$set(_vm.saveForm, "position", $$v)
                        },
                        expression: "saveForm.position",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("home.userAccount.contactMatters"),
                      prop: "contactMatters",
                    },
                  },
                  [
                    _c("el-input", {
                      staticClass: "input-bg-tran-simple",
                      attrs: {
                        placeholder: _vm.$t(
                          "home.userAccount.contactMattersPlaceholder"
                        ),
                      },
                      model: {
                        value: _vm.saveForm.contactMatters,
                        callback: function ($$v) {
                          _vm.$set(_vm.saveForm, "contactMatters", $$v)
                        },
                        expression: "saveForm.contactMatters",
                      },
                    }),
                  ],
                  1
                ),
                _vm.ApplicationModule.internalConfigs.internalEnableCaptcha
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("home.captcha"),
                          prop: "captchaCode",
                        },
                      },
                      [
                        _c("br"),
                        _c(
                          "div",
                          { staticClass: "captcha" },
                          [
                            _c("el-input", {
                              staticClass: "input-bg-tran-simple",
                              staticStyle: {
                                width: "calc( 50% - 6px )",
                                "margin-right": "12px",
                              },
                              attrs: {
                                placeholder: _vm.$t("home.captchaPlaceholder"),
                              },
                              model: {
                                value: _vm.saveForm.captchaCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.saveForm, "captchaCode", $$v)
                                },
                                expression: "saveForm.captchaCode",
                              },
                            }),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: !_vm.captcha.image,
                                    expression: "!captcha.image",
                                  },
                                ],
                                staticClass: "captcha-image",
                                staticStyle: { width: "120px", height: "50px" },
                                on: { click: _vm.loadCaptcha },
                              },
                              [
                                _vm.captcha
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "120px",
                                        height: "50px",
                                      },
                                      attrs: {
                                        src:
                                          "data:image/png;base64," +
                                          _vm.captcha.image,
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "register-btns" }, [
      _c("div", { staticClass: "register-btns-line" }, [
        _c("span", [
          _vm._v(_vm._s(_vm.$t("home.userAccount.submitAsAgree"))),
          _c(
            "span",
            { staticClass: "register-agreement", on: { click: _vm.agreement } },
            [_vm._v(_vm._s(_vm.$t("home.userAccount.agreement")))]
          ),
        ]),
      ]),
      _c("div", { staticClass: "register-btns-line" }, [
        _c(
          "span",
          {
            staticClass: "goto-login",
            staticStyle: { "font-size": "14px" },
            on: { click: _vm.login },
          },
          [_vm._v(_vm._s(_vm.$t("home.userAccount.hasAccount")))]
        ),
      ]),
      _c(
        "div",
        { staticClass: "register-btns-line" },
        [
          _c(
            "el-button",
            {
              staticClass: "register-btn",
              staticStyle: {
                width: "100%",
                height: "40px",
                "font-size": "16px",
                "border-radius": "4px",
              },
              attrs: { type: "primary", size: "large" },
              on: { click: _vm.handleRegister },
            },
            [_vm._v(_vm._s(_vm.$t("app.submit")))]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "title", staticStyle: { "font-size": "40px" } },
      [
        _c("i", { staticClass: "el-icon-arrow-left" }),
        _c(
          "span",
          {
            staticStyle: {
              position: "relative",
              top: "-2px",
              "font-size": "40px",
            },
          },
          [_vm._v("Targus ")]
        ),
        _c("i", { staticClass: "el-icon-arrow-right" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }