import VueRouter from 'vue-router'
import Layout35 from '@/views/layout35.vue'
import Layout from '@/views/layout.vue'

import Login from './modules/login/login.vue'
import Home from './modules/home/home.vue';
export default new VueRouter({
    // mode: 'history',
    // base: process.env.BASE_URL,
    mode: process.env.VUE_APP_ROUTE_MODE||'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/login',
            component: Login
        },
        {
            path: '/',
            component: Home
        },
        {
          path: '/layout35',
          redirect: '/',
          component: Layout35,
          children: [
            {
              path: '/account/register',
              component: ()=> import('./modules/userAccount/register.vue'),
              meta: {
                authWhite: true
              }
            },
            {
              path: '/account/forget',
              component: ()=> import('./modules/userAccount/forgetPassword.vue'),
              meta: {
                authWhite: true
              }
            },
          ]
        },        
        {
          path: "/account/agreement",
          component: ()=> import("./modules/userAccount/agreement.vue"),
          meta: {
            authWhite: true
          }
        },
    ]
})