var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "home-layout " + (_vm.collapsed ? "collapsed" : "") },
    [
      _vm.menuStyle == "1"
        ? _c(
            "div",
            { staticClass: "home-left", class: _vm.appName },
            [
              _c("div", { staticClass: "logo" }, [
                _c("div", { staticStyle: { "text-align": "left" } }, [
                  _c("img", { attrs: { src: _vm.logoPath } }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.collapsed,
                          expression: "!collapsed",
                        },
                      ],
                      staticClass: "logo-title",
                      on: { click: _vm.gotoHome },
                    },
                    [_vm._v(_vm._s(_vm.sysName))]
                  ),
                ]),
              ]),
              _c(
                "el-scrollbar",
                {
                  staticClass: "layout-left-menu",
                  staticStyle: { "flex-grow": "1" },
                },
                [_vm._m(0)],
                1
              ),
              _c("div", { staticClass: "version" }, [
                _vm._v(" " + _vm._s(_vm.version) + " "),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "home-right", class: "type-" + _vm.menuStyle }, [
        _c("div", { staticClass: "home-top" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  height: "100%",
                  "align-items": "center",
                  gap: "10px",
                  "justify-content": "space-between",
                },
              },
              [
                _vm.menuStyle == "1"
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          "flex-grow": "1",
                          "padding-left": "25px",
                        },
                      },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "dark",
                              content: _vm.collapsed
                                ? _vm.$t("app.showMenu")
                                : _vm.$t("app.hideMenu"),
                              placement: "bottom",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "toggle-icon",
                                staticStyle: {
                                  "font-size": "18px !important",
                                  padding: "0",
                                },
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeCollapse()
                                  },
                                },
                              },
                              [
                                _vm.collapsed
                                  ? _c("i", { staticClass: "fa fa-indent" })
                                  : _c("i", { staticClass: "fa fa-outdent" }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.menuStyle == "2"
                  ? _c("div", [
                      _c("div", { staticClass: "logo logo-top" }, [
                        _c("div", [
                          _c("img", { attrs: { src: _vm.logoPath } }),
                        ]),
                        _c("div", [
                          _c("span", { staticClass: "logo-title logo-top" }, [
                            _vm._v(_vm._s(_vm.sysName + _vm.applicationName)),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm.menuStyle == "2"
                  ? _c("div", { staticClass: "top-home-menu" }, [
                      _c("div", [
                        _c(
                          "span",
                          {
                            staticClass: "logo-go-home",
                            on: { click: _vm.gotoHome },
                          },
                          [_c("i", { staticClass: "el-icon-back" })]
                        ),
                      ]),
                      _c("div", { staticClass: "top-menu" }, [_vm._m(1)], 1),
                    ])
                  : _vm._e(),
                _vm.menuStyle == "2"
                  ? _c(
                      "div",
                      { staticClass: "top-user-info" },
                      [
                        _c("layout-user-info", {
                          staticClass: "top-layout-user-info",
                        }),
                      ],
                      1
                    )
                  : _c("layout-user-info", {
                      staticClass: "home-layout-user-info",
                    }),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "home-middle" },
          [
            _c(
              "el-scrollbar",
              {
                ref: "scrollBar",
                staticStyle: { height: "100%", "box-sizing": "border-box" },
                attrs: { viewClass: "layout-view" },
              },
              [
                _c("div", { staticClass: "home-middle-padding" }),
                _vm._t("default"),
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("main-menu", { attrs: { id: "mainMenu", tabindex: "0" } })
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("top-menu", { attrs: { id: "topMenu", tabindex: "0" } })
  },
]
render._withStripped = true

export { render, staticRenderFns }