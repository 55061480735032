import request from '@/utils/request'

export function userRegister(data: any) {
  return request({
    baseURL: process.env.VUE_APP_SYSTEM_API,
    url: '/modules/sys/user/userRegister',
    method: 'POST',
    data
  })
}

export function forgotPassword(data: any) {
  return request({
    baseURL: process.env.VUE_APP_SYSTEM_API,
    url: '/modules/sys/user/forgotPassword',
    method: 'POST',
    data
  })
}