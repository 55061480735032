var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "workspace-layout",
      staticStyle: {
        display: "flex",
        height: "100%",
        "flex-direction": "column",
      },
    },
    [
      !_vm.hideHeader
        ? _c(
            "el-card",
            {
              staticClass: "wb-box",
              class: _vm.isWorkbenchMember ? "is-workbench-member" : "",
            },
            [
              _c(
                "el-row",
                {
                  staticStyle: { margin: "24px 32px" },
                  attrs: { type: "flex", justify: "space-between" },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-row",
                        { attrs: { type: "flex", justify: "start" } },
                        [
                          _c(
                            "div",
                            { staticClass: "special-a" },
                            [
                              _vm.currWorkbenchAvatar
                                ? _c("el-image", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%",
                                    },
                                    attrs: {
                                      fit: "cover",
                                      src: _vm.currWorkbenchAvatar,
                                    },
                                  })
                                : _c(
                                    "span",
                                    {
                                      staticClass: "f-c-c",
                                      staticStyle: { "margin-top": "8px" },
                                    },
                                    [
                                      _c("svg-icon", {
                                        staticStyle: { "font-size": "50px" },
                                        attrs: { "icon-class": "robot" },
                                      }),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "24px" } },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "20px",
                                    "margin-top": "12px",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.currWorkbenchName ||
                                        _vm.$t("wb.newSpecial")
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    "margin-top": "12px",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.currWorkbenchRemark ||
                                          _vm.$t("wb.newRemark")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  !_vm.isWorkbenchAdd && _vm.isWorkbenchMember
                    ? _c(
                        "el-row",
                        {
                          staticStyle: { "font-size": "14px" },
                          attrs: { type: "flex" },
                        },
                        [
                          _c(
                            "el-menu",
                            {
                              staticClass: "sonmenu-box",
                              attrs: {
                                "default-active": _vm.activeIndex,
                                mode: "horizontal",
                              },
                              on: { select: _vm.handleSelect },
                            },
                            _vm._l(_vm.sonRightMenus, function (item) {
                              return _c(
                                "el-menu-item",
                                {
                                  key: item.path,
                                  staticClass: "sonmenu-span",
                                  attrs: { index: item.path },
                                },
                                [
                                  _c("i", { class: item.icon }),
                                  _c(
                                    "span",
                                    { attrs: { slot: "title" }, slot: "title" },
                                    [_vm._v(_vm._s(item.text))]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              !_vm.isWorkbenchAdd && _vm.isWorkbenchMember
                ? _c(
                    "el-menu",
                    {
                      staticClass: "sonmenu-box",
                      attrs: {
                        "default-active": _vm.activeIndex,
                        mode: "horizontal",
                      },
                      on: { select: _vm.handleSelect },
                    },
                    [
                      _vm._l(_vm.sonMenus, function (item) {
                        return _c(
                          "el-menu-item",
                          {
                            key: item.path,
                            staticClass: "sonmenu-span",
                            attrs: { index: item.path },
                          },
                          [_vm._v(" " + _vm._s(item.text) + " ")]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.isWorkbenchAdd || _vm.isWorkbenchMember
        ? _c("router-view", { staticStyle: { "flex-grow": "1" } })
        : _vm._e(),
      !_vm.isWorkbenchAdd && !_vm.isWorkbenchMember
        ? _c(
            "div",
            { staticClass: "no-permission" },
            [
              _c("SvgIcon", { attrs: { iconClass: "no_permission" } }),
              _c("span", [_vm._v(_vm._s(_vm.$t("wb.noPermission")))]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }